import React, { createContext, FC, useEffect, useState } from 'react'

interface IAuthenticationContext {
    token: string;
    saveToken(token: string): void;
    removeToken(): void;
    email: string;
    saveEmail(email: string): void;
    removeEmail(): void;
};

const AuthenticationContext = createContext({} as IAuthenticationContext);

interface AuthenticationContextProps {
    children: React.ReactNode;
};

const AuthenticationContextProvider: FC<AuthenticationContextProps> = (props) => {
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');

    const saveToken = (token: string) => {
        setToken(token);
        localStorage.setItem('@token', token);
    }

    const removeToken = () => {
        setToken('');
        localStorage.removeItem('@token');
    }

    const saveEmail = (email: string) => {
        setEmail(email);
        localStorage.setItem('@email', email);
    }

    const removeEmail = () => {
        setEmail('');
        localStorage.removeItem('@email');
    }

    useEffect(() => {
        const jwt: string | null = localStorage.getItem('@token');
        const email: string | null = localStorage.getItem('@email');

        if (jwt && email) {
            setToken(jwt);
            setEmail(email);
        }
    }, [])

    const providerValue = {
        token: token,
        saveToken,
        removeToken,
        email: email,
        saveEmail,
        removeEmail
    }

    return (
        <AuthenticationContext.Provider value={providerValue}>
            {props.children}
        </AuthenticationContext.Provider>
    )
}

export { AuthenticationContext, AuthenticationContextProvider }
