import React from 'react'
import { useContext } from 'react'
import { FC } from 'react'
import { GlobalStateContext } from '../../../contexts'

interface CreditCardNeededModalProps {
    setShowCreditCard(state: boolean): void;
}

const CreditCardNeededModal: FC<CreditCardNeededModalProps> = (props) => {
    const { setShowCreditCard } = props;

    const { setShowCreditCardModal } = useContext(GlobalStateContext);

    const handleContinue = () => {
        setShowCreditCard(true);
        setShowCreditCardModal(false);
    }

    const handleClose = () => {
        setShowCreditCard(false);
        setShowCreditCardModal(false);
    }

    return (
        <div className="creditcard_needed__wrapper">
            <div className="creditcard_needed__top">
                <p>Credit Card information needed</p>
                <img
                    src="/assets/close.svg"
                    alt="X"
                    onClick={handleClose}
                />
            </div>
            <button
                onClick={handleContinue}
            >
                Continue
            </button>
        </div>
    )
}

export default CreditCardNeededModal
