import React, { createContext, FC, useState } from 'react'
import { DashboardLinkEnum, InCallActionsEnum, ModalTypesEnum, PagesEnum } from '../util/enums';

interface IGlobalStateContext {
    showIncomingCallModal: boolean;
    setShowIncomingCallModal(state: boolean): void;
    showInCallModal: boolean;
    setShowInCallModal(state: boolean): void;
    showCustomerAuthModal: boolean;
    setShowCustomerAuthModal(state: boolean): void;
    showCreditCardModal: boolean;
    setShowCreditCardModal(state: boolean): void;
    showTicketSummaryModal: boolean;
    setShowTicketSummaryModal(state: boolean): void;
    showLoader: boolean;
    setShowLoader(state: boolean): void;
    isCCPInitialized: boolean;
    setIsCCPInitialized(state: boolean): void;
    isLoggedOut: boolean;
    setIsLoggedOut(state: boolean): void;
    activeAction: InCallActionsEnum | undefined;
    setActiveAction(action: InCallActionsEnum | undefined): void;
    activePage: PagesEnum | undefined;
    setActivePage(action: PagesEnum | undefined): void;
    previousActivePage: PagesEnum | undefined;
    setPreviousActivePage(action: PagesEnum | undefined): void;
    pageLink: DashboardLinkEnum | undefined;
    setPageLink(action: DashboardLinkEnum | undefined): void;
    previousPageLink: DashboardLinkEnum | undefined;
    setPreviousPageLink(action: DashboardLinkEnum | undefined): void;
    showModal: boolean;
    setShowModal(state: boolean): void;
    activeModal: ModalTypesEnum | undefined;
    setActiveModal(modal: ModalTypesEnum | undefined): void;
};

const GlobalStateContext = createContext({} as IGlobalStateContext);

interface GlobalStateContextProps {
    children: React.ReactNode;
};

const GlobalStateContextProvider: FC<GlobalStateContextProps> = (props) => {
    const [showLoader, setShowLoader] = useState(true);
    const [showIncomingCallModal, setShowIncomingCallModal] = useState(false);
    const [showInCallModal, setShowInCallModal] = useState(false);
    const [showCustomerAuthModal, setShowCustomerAuthModal] = useState(false);
    const [showCreditCardModal, setShowCreditCardModal] = useState(false);
    const [showTicketSummaryModal, setShowTicketSummaryModal] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [activeModal, setActiveModal] = useState<ModalTypesEnum | undefined>(undefined);

    const [isCCPInitialized, setIsCCPInitialized] = useState(false);
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    const [activeAction, setActiveAction] = useState<InCallActionsEnum | undefined>(undefined);
    const [activePage, setActivePage] = useState<PagesEnum | undefined>(undefined);
    const [previousActivePage, setPreviousActivePage] = useState<PagesEnum | undefined>(undefined);
    const [pageLink, setPageLink] = useState<DashboardLinkEnum | undefined>(undefined);
    const [previousPageLink, setPreviousPageLink] = useState<DashboardLinkEnum | undefined>(undefined);

    const providerValue = {
        showIncomingCallModal: showIncomingCallModal,
        setShowIncomingCallModal,
        showInCallModal: showInCallModal,
        setShowInCallModal,
        showCustomerAuthModal: showCustomerAuthModal,
        setShowCustomerAuthModal,
        showCreditCardModal,
        setShowCreditCardModal,
        showTicketSummaryModal,
        setShowTicketSummaryModal,
        showLoader,
        setShowLoader,
        isCCPInitialized,
        setIsCCPInitialized,
        activeAction,
        setActiveAction,
        isLoggedOut,
        setIsLoggedOut,
        activePage,
        setActivePage,
        previousActivePage,
        setPreviousActivePage,
        pageLink,
        setPageLink,
        previousPageLink,
        setPreviousPageLink,
        activeModal,
        setActiveModal,
        showModal,
        setShowModal,
    }

    return (
        <GlobalStateContext.Provider value={providerValue}>
            {props.children}
        </GlobalStateContext.Provider>
    )
}

export { GlobalStateContext, GlobalStateContextProvider }
