import React, { FC, useContext, useState } from 'react'
import { AmazonConnectContext, ConciergeContext, GlobalStateContext, SecurityQuestionContext } from '../../../contexts';
import { IAmazonContactAttributesDTO, IConciergeData, IContactData, IContactMessageData } from '../../../interfaces';
import { AutocompleteTypesEnum } from '../../../util/enums';
import Autocomplete from '../../Autocomplete/Autocomplete';
import SelectedContact from '../../SelectedContact/SelectedContact';
import Timer from '../../Timer/Timer';

interface ReassignTicketProps {
    setShowReassignTicket(state: boolean): void;
    totalCallTime: number;
}

const ReassignTicket: FC<ReassignTicketProps> = (props) => {
    const { setShowReassignTicket, totalCallTime } = props;

    const [selected, setSelected] = useState<IConciergeData[]>([]);
    const [suggestions, setSuggestions] = useState<IConciergeData[]>([]);

    const { agent, connection, contact, updateContactAttributes } = useContext(AmazonConnectContext);
    const { setShowInCallModal, setShowCustomerAuthModal, setShowModal, setActiveModal } = useContext(GlobalStateContext);
    const { conciergeData } = useContext(ConciergeContext);
    const { customerData } = useContext(SecurityQuestionContext);

    const handleCloseModals = () => {
        setShowModal(false);
        setActiveModal(undefined);
    }

    const handleRemove = (value: IConciergeData) => {
        const selectedIndex = selected.map(item => item.id).indexOf(value.id);

        if (selectedIndex !== -1) {
            const selectedList: IConciergeData[] = JSON.parse(JSON.stringify(selected));

            selectedList.splice(selectedIndex, 1);

            setSelected(selectedList);

            return;
        }
    }

    const handleReassignTicket = () => {
        if (contact && selected[0].amazonId) {
            console.log(agent?.getAllQueueARNs());

            let endpoint: connect.Endpoint | undefined;

            agent?.getEndpoints(agent.getAllQueueARNs(), {
                success: (result) => {
                    endpoint = result.endpoints.find(endpoint => endpoint.name === selected[0].email);

                    if (endpoint) {
                        const attributes = {
                            previousConciergeName: conciergeData?.conciergeUser.firstName + ' ' + conciergeData?.conciergeUser.lastName,
                            customerData: ''
                        }

                        if (customerData) {
                            attributes.customerData = JSON.stringify(customerData);
                        }

                        const dto = {
                            attributes: attributes,
                            contactId: contact.contactId
                        } as IAmazonContactAttributesDTO;

                        handleAmazonContactUpdate(dto, endpoint);
                    }

                },
                failure: (error) => {
                    console.log(error);
                }
            })
        }
    }

    const handleAmazonContactUpdate = (dto: IAmazonContactAttributesDTO, endpoint: connect.Endpoint) => {
        updateContactAttributes(dto)
        .then(response => {
            if (endpoint) {
                contact?.addConnection(endpoint, {
                    success: () => {
                        connection?.destroy({
                            success: () => {
                                contact.clear({
                                    success: () => handleCloseModals(),
                                });
                            },
                        });

                        contact.toggleActiveConnections();
                    },
                    failure: error => {
                        console.log(error);
                    }
                })
            }
        })
    }

    const handleSetSelected = (value: IConciergeData[]) => {
        setSelected(value);
    }

    const handleSetSuggestions = (value: IConciergeData[]) => {
        setSuggestions(value);
    }

    return (
        <div className="reassign_ticket__wrapper">
            <div className="reassign_ticket__top">
                <div className="reassign_ticket__customer_info">
                    {/* <p>Michael Garcia</p> */}
                    <p>{customerData?.customer.userAttributes.firstName + ' ' + customerData?.customer.userAttributes.lastName}</p>
                    <span>in call</span>
                </div>
                <div className="reassign_ticket__call_info">
                    <span>Choose an employee to</span>
                    <p>Reassign Ticket</p>
                    <span>in call</span>
                    {/* <span>02:10</span> */}
                    <Timer time={totalCallTime} />
                </div>
                <div className="reassign_ticket__button">
                    <img src="/assets/in_call_back.svg" alt="<" onClick={() => setShowReassignTicket(false)} />
                </div>
            </div>
            <div className="reassign_ticket__content">
                <div className="reassign_ticket__input_container">
                    {
                        selected.length === 1
                        ? <SelectedContact
                            key={selected[0].id}
                            data={selected[0]}
                            handleRemove={handleRemove}
                            isConcierge={true}
                        />
                        : <Autocomplete
                            selected={selected}
                            setSelected={handleSetSelected}
                            suggestions={suggestions}
                            setSuggestions={handleSetSuggestions}
                            searchingType={AutocompleteTypesEnum.CONCIERGE}
                        />
                    }
                </div>
                <button
                    disabled={selected.length === 0}
                    onClick={handleReassignTicket}
                >
                    Reassign Ticket
                </button>
            </div>
        </div>
    )
}

export default ReassignTicket
