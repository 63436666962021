import React, { useContext, useRef, useState } from 'react'
import { useHistory } from 'react-router';
import { ConciergeContext, GlobalStateContext } from '../../contexts'
import { IUpdateConciergeData } from '../../interfaces';
import { ConciergeBackendRoleEnum } from '../../util/enums'

const EditProfilePage = () => {
    const { conciergeData, updateConcierge, getCurrentConcierge } = useContext(ConciergeContext);
    const {
        setActivePage,
        previousActivePage,
        setPreviousActivePage,
        previousPageLink,
        setPageLink
    } = useContext(GlobalStateContext);

    const [updateData, setUpdateData] = useState({
        userId: conciergeData?.userId,
        poolId: conciergeData?.poolId,
        email: conciergeData?.email,
        phoneNumber: conciergeData?.phoneNumber,
        role: conciergeData?.role,
        status: conciergeData?.status,
        firstName: conciergeData?.conciergeUser.firstName,
        lastName: conciergeData?.conciergeUser.lastName,
        thumbnailUrl: conciergeData?.conciergeUser.thumbnailUrl ? conciergeData?.conciergeUser.thumbnailUrl : '',
        hasThumbnail: conciergeData?.conciergeUser.hasThumbnail,
    } as IUpdateConciergeData);

    const history = useHistory();

    const inputPhoto = useRef<HTMLInputElement>(null);

    const handleCancel = () => {
        setActivePage(previousActivePage);
        setPreviousActivePage(undefined);

        setPageLink(previousPageLink);

        if (previousPageLink) {
            history.push(previousPageLink);
        }
        else {
            history.push('');
        }
    }

    const handleSave = () => {
        console.log(updateData);

        if (conciergeData) {
            updateConcierge(conciergeData.id, updateData)
            .then((response) => {
                getCurrentConcierge();
            })
        }

    }

    const uploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const file = event.target.files[0];

            fileToBase64(file);
        }
    }

    const fileToBase64 = (file: File) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            let base64data = reader.result as string;

            setUpdateData({
                ...updateData,
                photo: base64data
            })
        }

        reader.readAsDataURL(file);
    }

    const handleAddPhoto = () => {
        inputPhoto.current?.click();
    }

    const inputHandler = (fieldName: string, event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setUpdateData({
            ...updateData,
            [fieldName]: fieldName === 'role' ? parseInt(event.target.value) : event.target.value,
        });
    };

    const renderPhoto = () => {
        if (updateData.photo) {
            return <img className="edit_profile__profile_photo" src={updateData.photo} alt="" />
        }

        if (updateData.hasThumbnail && updateData.thumbnailUrl) {
            return <img className="edit_profile__profile_photo" src={updateData.thumbnailUrl} alt="" />
        }

        return <img className="edit_profile__profile_photo" src="/assets/add_photo.svg" onClick={handleAddPhoto} />
    }

    return (
        <div className="edit_profile__wrapper">
            <div className="edit_profile__photo">
                <input
                    type="file"
                    ref={inputPhoto}
                    onChange={e => uploadPhoto(e)}
                />
                {renderPhoto()}
                <img className="edit_profile__remove_photo" src="/assets/x_black.svg" alt="x" />
            </div>
            <div className="edit_profile__data">
                <div className="edit_profile__container">
                    <span>First name</span>
                    <input
                        type="text"
                        value={updateData.firstName}
                        onChange={e => inputHandler('firstName', e)}
                    />
                    <span>Email</span>
                    <input
                        type="text"
                        value={updateData.email}
                        onChange={e => inputHandler('email', e)}
                    />
                    <span>Assigned role</span>
                    <select
                        value={updateData.role}
                        onChange={e => inputHandler('role', e)}
                    >
                        <option value={ConciergeBackendRoleEnum.Administrator}>Admin</option>
                        <option value={ConciergeBackendRoleEnum.Manager}>Manager</option>
                        <option value={ConciergeBackendRoleEnum.Concierge}>Concierge</option>
                    </select>
                    <button
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                </div>
                <div className="edit_profile__container">
                <span>Last name</span>
                    <input
                        type="text"
                        value={updateData.lastName}
                        onChange={e => inputHandler('lastName', e)}
                    />
                    <span>Phone number</span>
                    <input
                        type="text"
                        value={updateData.phoneNumber}
                        onChange={e => inputHandler('phoneNumber', e)}
                    />
                    <span>Pool</span>
                    <select>
                        <option value={1}>725Contact - East Coast</option>
                    </select>
                    <button
                        className="edit_profile__save_button"
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>
                <div className="edit_profile__divider"></div>
            </div>
        </div>
    )
}

export default EditProfilePage
