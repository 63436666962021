import crypto from "crypto";
import config from "../../config/config.json";
import { ILoginDTO } from "../../interfaces";

const encryptEmail = (email: string) => {
    const iv = crypto.randomBytes(16);
    
    const cypher = crypto.createCipheriv(
        config.encryptionAlgorithm,
        config.secretKey,
        iv
    );

    const encrypted = Buffer.concat([
        cypher.update(email),
        cypher.final(),
    ]);

    return {
        iv: iv.toString("hex"),
        encrypted: String(encrypted.toString("hex")),
    } as ILoginDTO;
}

export { encryptEmail }