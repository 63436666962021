export enum ConciergeStatusEnum {
    AVAILABLE = "available",
    OCCUPIED = "occupied",
    OFFLINE = "offline",
}

export enum ConciergeRoleEnum {
    ADMIN = "Admin",
    MANAGER = "Manager",
    CONCIERGE = "Concierge",
}

export enum ConciergeBackendStatusEnum {
    Init,
    Routable,
    NotRoutable,
    Offline
}

export enum ConciergeBackendRoleEnum {
    Concierge = 0,
    Manager = 1,
    Administrator = 2
}