import React, { FC, useContext } from 'react';
import { ConciergeBackendRoleEnum, ConciergeRoleEnum, DashboardLinkEnum, DashboardTextEnum, PagesEnum } from '../../util/enums';
import { Link } from "react-router-dom";
import { ConciergeContext, GlobalStateContext } from '../../contexts';

const Dashboard: FC = () => {
    const { activePage, setActivePage, setPreviousActivePage, pageLink, setPageLink, setPreviousPageLink } = useContext(GlobalStateContext);
    const { conciergeData } = useContext(ConciergeContext);

    const renderDashboardMenuItems = () => {
        return Object.keys(DashboardTextEnum).map((key) => {
            let className = "";

            const value = DashboardTextEnum[key as keyof typeof DashboardTextEnum];
            const pageValue = PagesEnum[key as keyof typeof PagesEnum];
            const linkValue = DashboardLinkEnum[key as keyof typeof DashboardLinkEnum];

            if (activePage === pageValue) {
                className = "dashboard_menu_item__content--active";
            }
            else if (activePage === PagesEnum.CREATE_EMPLOYEES && key === "EMPLOYEES") {
                className = "dashboard_menu_item__content--active";
            }
            else {
                className = "dashboard_menu_item__content";
            }

            if (value === DashboardTextEnum.SUBSCRIPTION_PLAN && conciergeData?.role !== ConciergeBackendRoleEnum.Administrator) {
                return;
            }

            return (
                <div key={key} className="dashboard_menu_item">
                    <div className={className}>
                        <Link 
                            to={DashboardLinkEnum[key as keyof typeof DashboardLinkEnum]} 
                            className="dashboard_menu_item__text"
                            onClick={() => handleRedirect(pageValue, linkValue)}
                        >
                            {value}
                        </Link>
                        {
                            key === "EMPLOYEES" && conciergeData?.role === ConciergeBackendRoleEnum.Administrator
                            ? (
                                <div className="dashboard_menu_item__button">
                                    <Link
                                        to={DashboardLinkEnum.CREATE_EMPLOYEES}
                                        onClick={() => handleRedirect(PagesEnum.CREATE_EMPLOYEES, DashboardLinkEnum.CREATE_EMPLOYEES)}
                                    >
                                        ADD
                                    </Link>
                                </div>
                            )
                            : null
                        }
                    </div>
                </div>
            );
        });
    };

    const handleRedirect = (pageValue: PagesEnum, linkValue: DashboardLinkEnum) => {
        setPreviousActivePage(activePage);
        setActivePage(pageValue);

        setPreviousPageLink(pageLink);
        setPageLink(linkValue);
    }

    return (
        <div className="dashboard">
            <div className="dashboard_logo">
                <img src="/assets/logo.png" alt="725" className="dashboard_logo--image" />
            </div>
            <div className="dashboard_role">
                <p>System Admin</p>
            </div>
            {renderDashboardMenuItems()}
        </div>
    )
}

export default Dashboard
