import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { ConciergeContext, ContactContext, SecurityQuestionContext, TicketContext } from '../../contexts';
import useDebounce from '../../hooks/useDebounce';
import { IConciergeData, IContactData, ITicketData } from '../../interfaces';
import { AutocompleteTypesEnum, ConciergeBackendRoleEnum, ConciergeRoleEnum, MessageTypesEnum } from '../../util/enums';
import Modal from 'react-modal';
import ContactNumberSelectionModal from '../Modals/ContactNumberSelectionModal/ContactNumberSelectionModal';
import { IContactMessageData } from '../../interfaces/ContactInterface';
import { doesContactHaveMultipleContactData, getFullName } from '../../util/functions';

interface AutocompleteProps {
    suggestions: IContactData[] | IConciergeData[];
    setSuggestions(suggestions: IContactData[] | IConciergeData[]): void;
    selected: IContactMessageData[] | IConciergeData[];
    setSelected(selected: IContactMessageData[] | IConciergeData[]): void;
    messageType?: MessageTypesEnum;
    searchingType: AutocompleteTypesEnum;
    setTicket?(ticket: ITicketData): void;
}

const Autocomplete: FC<AutocompleteProps> = (props) => {
    const { selected, setSelected, suggestions, setSuggestions, messageType, searchingType, setTicket } = props;

    const { searchContactsByValue } = useContext(ContactContext);
    const { customerData } = useContext(SecurityQuestionContext);
    const { getConciergesByName } = useContext(ConciergeContext);
    const { getTicketById } = useContext(TicketContext);

    const [areSuggestionsVisible, setAreSuggestionsVisible] = useState(false);
    const [isSelectionModalVisible, setIsSelectionModalVisible] = useState(false);
    const [isSearchingConcierges, setIsSearchingConcierges] = useState(false);
    const [selectedContact, setSelectedContact] = useState<IContactData>({} as IContactData);

    const [search, setSearch] = useState({
        query: ''
    });
    const searchWithDebounce = useDebounce(search.query, 1000);

    const wrapper = useRef<HTMLDivElement>(null);

    const onTextChanged = (value: string) => {
        setSearch({
            query: value
        });
        
        if (!value) {
            setSuggestions([]);
        }
    };

    const addToSelected = (value: IContactMessageData | IConciergeData) => {
        if (searchingType === AutocompleteTypesEnum.CONCIERGE
            || (searchingType === AutocompleteTypesEnum.TICKET && isSearchingConcierges)) {
            const selectedIndex = selected.map(item => item.id).indexOf(value.id);

            if (selectedIndex !== -1) {
                const selectedList: IConciergeData[] = JSON.parse(JSON.stringify(selected));

                selectedList.splice(selectedIndex, 1);

                // selectedList.push(value as IConciergeData);

                setSelected(selectedList);

                return;
            }

            const selectedList: IConciergeData[] = JSON.parse(JSON.stringify(selected));

            selectedList.splice(selectedIndex, 1);

            selectedList.push(value as IConciergeData);

            setSelected(selectedList);

            // setSelected([...selected as IConciergeData[], value as IConciergeData]);

            return;
        }

        if (searchingType === AutocompleteTypesEnum.CONTACT) {
            const selectedIndex = selected.map(item => item.id).indexOf(value.id);
    
            if (selectedIndex !== -1) {
                const selectedList: IContactMessageData[] = JSON.parse(JSON.stringify(selected));
    
                selectedList.splice(selectedIndex, 1);
    
                selectedList.push(value as IContactMessageData);
    
                setSelected(selectedList);
    
                return;
            }
    
            setSelected([...selected as IContactMessageData[], value as IContactMessageData]);
        }
    }

    const suggestionSelected = (value: IContactData) => {
        if (doesContactHaveMultipleContactData(messageType, value)) {
            setSelectedContact(value);
            setIsSelectionModalVisible(true);

            return;
        }

        const contactMessageData = {
            id: value.id,
            firstName: value.myUserAttributes.firstName,
            lastName: value.myUserAttributes.lastName,
            phoneticMiddleName: value.phoneticMiddleName,
            prefix: value.prefix,
            sufix: value.sufix,
            phoneNumberId: undefined,
            emailId: undefined
        } as IContactMessageData;

        if (messageType === MessageTypesEnum.EMAIL) {
            if (value.myUserAttributes.emails.length !== 0) {
                contactMessageData.emailId = value.myUserAttributes.emails[0].id;
            }
        }
        else {
            if (value.myUserAttributes.phones.length !== 0) {
                contactMessageData.phoneNumberId = value.myUserAttributes.phones[0].id;
                contactMessageData.phoneNumber = value.myUserAttributes.phones[0].number;
            }
        }

        console.log(messageType);
        console.log(contactMessageData);

        addToSelected(contactMessageData);
    };

    const handleClickOutside = (event: any) => {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
            setAreSuggestionsVisible(false);
            
            return;
        }

        setAreSuggestionsVisible(true);
    }

    const renderSuggestions = () => {
        return suggestions.map((item: IContactData | IConciergeData) => {
            if (searchingType === AutocompleteTypesEnum.CONCIERGE) {
                const concierge = item as IConciergeData;

                return <div className="autocomplete__suggestion" key={concierge.id}>
                    <img src="/assets/user_profile_letters.svg" alt="image" />
                    <p
                        onClick={() => addToSelected(concierge)}
                    >
                        {
                            concierge.conciergeUser.lastName
                            ? concierge.conciergeUser.firstName + " " + concierge.conciergeUser.lastName
                            : concierge.conciergeUser.firstName
                        }
                    </p>
                </div>
            }
            
            if (searchingType === AutocompleteTypesEnum.CONTACT) {
                const contact = item as IContactData;

                return <div className="autocomplete__suggestion" key={contact.id}>
                    <img src="/assets/user_profile_letters.svg" alt="image" />
                    <p
                        onClick={() => suggestionSelected(contact)}
                    >
                        {/* {
                            contact.myUserAttributes.lastName
                            ? contact.myUserAttributes.firstName + " " + contact.myUserAttributes.lastName
                            : contact.myUserAttributes.firstName
                        } */}
                        {getFullName(contact)}
                    </p>
                </div>
            }

            if (searchingType === AutocompleteTypesEnum.TICKET && isSearchingConcierges) {
                const concierge = item as IConciergeData;

                return <div className="autocomplete__suggestion" key={concierge.id}>
                    <img src="/assets/user_profile_letters.svg" alt="image" />
                    <div
                        className="autocomplete__concierge_container"
                        onClick={() => addToSelected(concierge)}
                    >
                        <p>
                            {
                                concierge.conciergeUser.lastName
                                ? concierge.conciergeUser.firstName + " " + concierge.conciergeUser.lastName
                                : concierge.conciergeUser.firstName
                            }
                        </p>
                        <div className={`autocomplete__concierge_role autocomplete__concierge_role_${concierge.role}`}>
                            <span>{getConciergeRoleFromBackendRole(concierge.role)}</span>
                        </div>
                    </div>
                </div>
            }
        });
    } 

    const handleClose = () => {
        setIsSelectionModalVisible(false);
    }

    const renderPlaceholder = () => {
        if (selected.length === 0) {
            if (searchingType === AutocompleteTypesEnum.CONCIERGE) {
                return 'Search Employee';
            }

            if (searchingType === AutocompleteTypesEnum.TICKET) {
                return 'Search Employee / Ticket ID'
            }
            
            return "Search Contact by Name / E-mail / Number";
        }
        
        return '';
    }

    const getConciergeRoleFromBackendRole = (role: ConciergeBackendRoleEnum) => {
        if (role === ConciergeBackendRoleEnum.Concierge) {
            return ConciergeRoleEnum.CONCIERGE;
        }

        if (role === ConciergeBackendRoleEnum.Manager) {
            return ConciergeRoleEnum.MANAGER;
        }

        return ConciergeRoleEnum.ADMIN;
    }

    useEffect(() => {
        let mounted = true;
        let searchingConcierges;

        const ticketId = parseInt(searchWithDebounce);

        if (!ticketId) {
            searchingConcierges = true;
            setIsSearchingConcierges(true);
        }
        else {
            searchingConcierges = false;
            setIsSearchingConcierges(false);
        }

        if (searchWithDebounce) {
            if (searchingType === AutocompleteTypesEnum.CONCIERGE
                || (searchingType === AutocompleteTypesEnum.TICKET && searchingConcierges)) {
                getConciergesByName(searchWithDebounce)
                .then((concierges: IConciergeData[]) => {
                    if (mounted) {
                        setSuggestions(concierges);
                    }
                });
            }

            if (customerData && searchingType === AutocompleteTypesEnum.CONTACT) {
                searchContactsByValue(customerData.customer.id, searchWithDebounce)
                .then((contacts: IContactData[]) => {
                    if (mounted) {
                        setSuggestions(contacts);
                    }
                });
            }

            if (searchingType === AutocompleteTypesEnum.TICKET && !searchingConcierges) {
                getTicketById(parseInt(searchWithDebounce))
                .then(response => {
                    if (mounted && setTicket && response.id) {
                        setTicket(response);
                    }
                })
            }
            
            return function cleanup() {
                mounted = false;
            }; 
        }
    }, [searchWithDebounce]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [wrapper]);

    return (
        <div
            className="autocomplete__wrapper"
            ref={wrapper}
            style={selected.length % 2 === 0 ? {width: '100%'} : {width: '48%'}}
        >
            <div 
                className="autocomplete__search"
            >
                <img src="/assets/search.svg" alt="" />
                <input
                    id="input"
                    type="text"
                    placeholder={renderPlaceholder()}
                    autoComplete="new-password"
                    value={search.query}
                    onChange={e => onTextChanged(e.target.value)}
                />
            </div>
            <div 
                className="autocomplete__suggestions_container"
                style={
                    !areSuggestionsVisible
                    ? { display: 'none' }
                    : {}
                    &&
                    selected.length >= 2
                    ? { borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }
                    : { borderTopLeftRadius: '14px', borderTopRightRadius: '14px' }
                }
            >
                <hr
                    style={
                        suggestions.length === 0
                        ? { display: 'none' }
                        : {}
                    }
                />
                {suggestions.length > 0 && (
                    <div className="autocomplete__suggestions_wrapper">
                        {renderSuggestions()}
                    </div>
                )}
                <Modal
                    isOpen={isSelectionModalVisible}
                    className="contact_number_selection__modal"
                    ariaHideApp={false}
                    overlayClassName="modal__overlay"
                >
                    <ContactNumberSelectionModal
                        contact={selectedContact}
                        messageType={messageType}
                        handleSelect={addToSelected}
                        handleClose={handleClose}
                    />
                </Modal>
            </div>
        </div>
    )
}

export default Autocomplete
