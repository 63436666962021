import { IContactData, IContactMessageData, ICustomerSubscriptionData, ISubscriptionData } from "../../interfaces";
import { MessageTypesEnum, SubscriptionTypesEnum } from "../enums";

const doesCorrespondingMessageTypeAndDataExist = (messageType: MessageTypesEnum | undefined, data: IContactMessageData): boolean => {
    if ((messageType === MessageTypesEnum.SMS && data.phoneNumberId) ||
        (messageType === MessageTypesEnum.EMAIL && data.emailId) ||
        !messageType) {
        return true;
    }

    return false;
}

const doesContactHaveMultipleContactData = (messageType: MessageTypesEnum | undefined, data: IContactData): boolean => {
    if ((messageType === MessageTypesEnum.SMS && data.myUserAttributes.phones.length > 1) ||
        (messageType === MessageTypesEnum.EMAIL && data.myUserAttributes.emails.length > 1)
    ) {
        return true;
    }

    return false;
}

const isSubscriptionSelectionDisabled = (customerSubscription: ICustomerSubscriptionData | undefined, selectionSubscription: ISubscriptionData, isMonthly: boolean) => {
    if (!customerSubscription) {
        return false;
    }
    
    if (selectionSubscription.yearlyPrice < customerSubscription.subscriptionAttributes.yearlyPrice || 
        selectionSubscription.monthlyPrice < customerSubscription.subscriptionAttributes.monthlyPrice) {
            return true;
    }

    if (isMonthly && customerSubscription.paymentType === SubscriptionTypesEnum.YEARLY) {
        return true;
    }

    if (isMonthly && customerSubscription.subscriptionAttributes.name === 'Basic') {
        return true;
    }

    return false;
}

export { doesCorrespondingMessageTypeAndDataExist, doesContactHaveMultipleContactData, isSubscriptionSelectionDisabled }