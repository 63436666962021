import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import { Autocomplete } from '../../components'
import { IConciergeData, IFilterTicketDTO, ITicketData } from '../../interfaces';
import { AnalyticsTimeframesEnum, AutocompleteTypesEnum, ConciergeBackendRoleEnum, ConciergeRoleEnum, TicketStatusEnum } from '../../util/enums';
import Modal from 'react-modal';
import ConciergeCard from './components/ConciergeCard/ConciergeCard';
import TicketInformationModal from './components/TicketInformationModal/TicketInformationModal';
import { AuthenticationContext, TicketContext } from '../../contexts';

const TicketManagementPage = () => {
    const [selectedTicket, setSelectedTicket] = useState<ITicketData | undefined>(undefined);
    const [selectedConcierge, setSelectedConcierge] = useState<IConciergeData[]>([]);
    const [suggestions, setSuggestions] = useState<IConciergeData[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [tickets, setTickets] = useState<ITicketData[]>([]);

    const [selectedStatus, setSelectedStatus] = useState<TicketStatusEnum | undefined>(undefined);
    const [selectedRole, setSelectedRole] = useState<ConciergeRoleEnum | undefined>(undefined);
    const [selectedTimeframe, setSelectedTimeframe] = useState<AnalyticsTimeframesEnum | undefined>(undefined);
    const [selectedPool, setSelectedPool] = useState<string | undefined>(undefined);

    const [showStatuses, setShowStatuses] = useState(false);
    const [showRoles, setShowRoles] = useState(false);
    const [showTimeframes, setShowTimeframes] = useState(false);
    const [showPools, setShowPools] = useState(false);
    const [showInformationModal, setShowInformationModal] = useState(false);

    const { token } = useContext(AuthenticationContext);
    const { getAllTickets, filterTickets } = useContext(TicketContext);

    const renderStatuses = () => {
        const statuses = Object.keys(TicketStatusEnum).map((key) => {
            if (key > '6') {
                const value = TicketStatusEnum[key as keyof typeof TicketStatusEnum];
    
                if (value === selectedStatus) {
                    return;
                }
    
                return (
                    <div
                        key={key}
                        className="ticket_management__dropdown_item"
                        onClick={() => setSelectedStatus(value)}
                    >
                        <span>{renderStatusName(value)}</span>
                        <div className="ticket_management__dropdown_divider"></div>
                    </div>
                );
            }
        });

        return <div className="ticket_management__dropdown_container">
            <div
                className="ticket_management__selected"
                tabIndex={0}
                onClick={e => setShowStatuses(!showStatuses)}
                // onBlur={e => setShowStatuses(false)}
            >
                <span>{renderStatusName(selectedStatus)}</span>
                <img src="/assets/gray_arrow.svg" alt="v" />
            </div>
            {
                showStatuses && (<div
                    className="ticket_management__dropdown"
                    tabIndex={9}
                    onClick={() => setShowStatuses(!showStatuses)}
                    onBlur={() => setShowStatuses(false)}
                >
                    <div className="ticket_management__dropdown__selected">
                        <span>{renderStatusName(selectedStatus)}</span>
                        <img src="/assets/gray_arrow.svg" alt="v" />
                    </div>
                    <div className="ticket_management__dropdown_divider"></div>
                    {
                        (selectedStatus || selectedStatus === TicketStatusEnum.OPEN) && (<div
                            className="ticket_management__dropdown_item"
                            onClick={() => setSelectedStatus(undefined)}
                        >
                            <span>Any status</span>
                            <div className="ticket_management__dropdown_divider"></div>
                        </div>)
                    }
                    {statuses}
                </div>)
            }
        </div>
    }

    const renderRoles = () => {
        const roles = Object.keys(ConciergeRoleEnum).map((key) => {
            const value = ConciergeRoleEnum[key as keyof typeof ConciergeRoleEnum];

            if (value === selectedRole) {
                return;
            }

            return (
                <div
                    key={key}
                    className="ticket_management__dropdown_item"
                    onClick={() => setSelectedRole(value)}
                >
                    <span>{value}</span>
                    <div className="ticket_management__dropdown_divider"></div>
                </div>
            );
        });

        return <div className="ticket_management__dropdown_container">
            <div
                className="ticket_management__selected"
                tabIndex={0}
                onClick={e => setShowRoles(!showRoles)}
                // onBlur={e => setShowStatuses(false)}
            >
                <span>{selectedRole ? selectedRole : 'Employee role'}</span>
                <img src="/assets/gray_arrow.svg" alt="v" />
            </div>
            {
                showRoles && (<div
                    className="ticket_management__dropdown"
                    tabIndex={9}
                    onClick={() => setShowRoles(!showRoles)}
                    onBlur={() => setShowRoles(false)}
                >
                    <div className="ticket_management__dropdown__selected">
                        <span>{selectedRole ? selectedRole : 'Employee role'}</span>
                        <img src="/assets/gray_arrow.svg" alt="v" />
                    </div>
                    <div className="ticket_management__dropdown_divider"></div>
                    {
                        selectedRole && (<div
                            className="ticket_management__dropdown_item"
                            onClick={() => setSelectedRole(undefined)}
                        >
                            <span>Any role</span>
                            <div className="ticket_management__dropdown_divider"></div>
                        </div>)
                    }
                    {roles}
                </div>)
            }
        </div>
    }

    const renderTimeframes = () => {
        const timeframes = Object.keys(AnalyticsTimeframesEnum).map((key) => {
            if (key > '4' && key !== "FIXED_TIMEFRAME") {
                const value = AnalyticsTimeframesEnum[key as keyof typeof AnalyticsTimeframesEnum];

                if (value === selectedTimeframe) {
                    return;
                }

                return (
                    <div
                        key={key}
                        className="ticket_management__dropdown_item"
                        onClick={() => handleChangeTimeframe(value)}
                    >
                        <span>{renderTimeframeName(value)}</span>
                        <div className="ticket_management__dropdown_divider"></div>
                    </div>
                );
            }
        });

        return <div className="ticket_management__dropdown_container">
            <div
                className="ticket_management__selected"
                tabIndex={0}
                onClick={e => setShowTimeframes(!showTimeframes)}
                // onBlur={e => setShowStatuses(false)}
            >
                <span>{renderTimeframeName(selectedTimeframe)}</span>
                <img src="/assets/gray_arrow.svg" alt="v" />
            </div>
            {
                showTimeframes && (<div
                    className="ticket_management__dropdown"
                    tabIndex={9}
                    onClick={() => setShowTimeframes(!showTimeframes)}
                    onBlur={() => setShowTimeframes(false)}
                >
                    <div className="ticket_management__dropdown__selected">
                        <span>{renderTimeframeName(selectedTimeframe)}</span>
                        <img src="/assets/gray_arrow.svg" alt="v" />
                    </div>
                    <div className="ticket_management__dropdown_divider"></div>
                    {
                        (selectedTimeframe || selectedTimeframe === AnalyticsTimeframesEnum.TODAY) && (<div
                            className="ticket_management__dropdown_item"
                            onClick={() => handleChangeTimeframe(undefined)}
                        >
                            <span>Any timeframe</span>
                            <div className="ticket_management__dropdown_divider"></div>
                        </div>)
                    }
                    {timeframes}
                </div>)
            }
        </div>
    }

    const renderPools = () => {
        return <div className="ticket_management__dropdown_container">
        <div
            className="ticket_management__selected"
            tabIndex={0}
            onClick={e => setShowPools(!showPools)}
            // onBlur={e => setShowPools(false)}
        >
            <span>{selectedPool ? selectedPool : 'Pool'}</span>
            <img src="/assets/gray_arrow.svg" alt="v" />
        </div>
        {
            showPools && (<div
                className="ticket_management__dropdown"
                tabIndex={9}
                onClick={() => setShowPools(!showPools)}
                onBlur={() => setShowPools(false)}
            >
                <div className="ticket_management__dropdown__selected">
                    <span>{selectedPool ? selectedPool : 'Pool'}</span>
                    <img src="/assets/gray_arrow.svg" alt="v" />
                </div>
                <div className="ticket_management__dropdown_divider"></div>
                <div
                    className="ticket_management__dropdown_item"
                    onClick={() => setSelectedPool('First pool')}
                >
                    <span>First pool</span>
                    <div className="ticket_management__dropdown_divider"></div>
                </div>
            </div>)
        }
        </div>
    }

    const renderTickets = () => {
        return tickets.map(ticket => {


            return <div
                className="ticket_management__ticket"
                key={ticket.id}
                onClick={() => handleShowInformationModal(ticket)}
            >
                <span>Ticket ID: {ticket.id}</span>
                <div className={`ticket_management__ticket_status ticket_management__ticket_status__${ticket.status}`}>
                    <span>{renderStatusNameForTicket(ticket.status)}</span>
                </div>
            </div>
        })
    }

    const renderTimeframeName = (timeframe: AnalyticsTimeframesEnum | undefined) => {
        switch (timeframe) {
            case AnalyticsTimeframesEnum.TODAY:
                return '24 hours';
            case AnalyticsTimeframesEnum.LAST_WEEK:
                return 'last week';
            case AnalyticsTimeframesEnum.LAST_MONTH:
                return 'last month';
            case AnalyticsTimeframesEnum.LAST_YEAR:
                return 'last year';
            case AnalyticsTimeframesEnum.FIXED_TIMEFRAME:
                return 'custom';
            default:
                return '24h/week/m/y';
        }
    }

    const renderStatusName = (value: TicketStatusEnum | undefined) => {
        switch (value) {
            case TicketStatusEnum.OPEN:
                return 'Open';
            case TicketStatusEnum.CLOSED_COMPLETE:
                return 'Closed Complete';
            case TicketStatusEnum.CLOSE_PENDING:
                return 'Closed Pending';
            case TicketStatusEnum.PENDING_COMPLETION:
                return 'Pending Completion';
            case TicketStatusEnum.CLOSED_INCOMPLETE:
                return 'Closed Incomplete';
            case TicketStatusEnum.REVIEW:
                return 'Review';
            case TicketStatusEnum.AUDIT:
                return 'Audit';
            default:
                return 'Ticket Status';
        }
    }

    const renderStatusNameForTicket = (value: TicketStatusEnum | undefined) => {
        switch (value) {
            case TicketStatusEnum.OPEN:
                return 'Open';
            case TicketStatusEnum.CLOSED_COMPLETE:
                return 'Closed';
            case TicketStatusEnum.CLOSE_PENDING:
                return 'Cl. Pend.';
            case TicketStatusEnum.PENDING_COMPLETION:
                return 'Pen. Comp.';
            case TicketStatusEnum.CLOSED_INCOMPLETE:
                return 'Cl. Incomp.';
            case TicketStatusEnum.REVIEW:
                return 'Review';
            case TicketStatusEnum.AUDIT:
                return 'Audit';
            default:
                return 'Ticket Status';
        }
    }
    
    const renderDates = () => {
        let text = '';
        
        if (!startDate) {
            return 'Date';
        }
        
        text += DateTime.fromJSDate(startDate).toFormat('dd.MM.yyyy.');
        
        if (endDate) {
            if (startDate.getTime() === endDate.getTime()) {
                return text;
            }
            
            text += ' - ' + DateTime.fromJSDate(endDate).toFormat('dd.MM.yyyy.');
        }
        
        return text;
    }
    
    const getBackendRoleFromConciergeRole = (role: ConciergeRoleEnum) => {
        if (role === ConciergeRoleEnum.CONCIERGE) {
            return ConciergeBackendRoleEnum.Concierge;
        }

        if (role === ConciergeRoleEnum.MANAGER) {
            return ConciergeBackendRoleEnum.Manager;
        }

        return ConciergeBackendRoleEnum.Administrator;
    }

    const handleSetSelected = (value: IConciergeData[]) => {
        setSelectedConcierge(value);
    }

    const handleSetSuggestions = (value: IConciergeData[]) => {
        setSuggestions(value);
    }

    const handleChangeTimeframe = (timeframe: AnalyticsTimeframesEnum | undefined) => {
        setSelectedTimeframe(timeframe);

        setStartDate(null);
        setEndDate(null);
    }

    const handleSetDate = (dates: any) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);

        setSelectedTimeframe(AnalyticsTimeframesEnum.FIXED_TIMEFRAME);
    }

    const handleShowInformationModal = (ticket: ITicketData) => {
        setSelectedTicket(ticket);
        setShowInformationModal(true);
    }

    const handleCloseInformationModal = () => {
        setShowInformationModal(false);
    }

    const resetTickets = () => {
        getAllTickets().then((tickets: ITicketData[]) => {
            setTickets(tickets);
        });
    }

    const updateTicketList = (id: number, status: TicketStatusEnum) => {
        const ticketIndex = tickets.map(ticket => ticket.id).indexOf(id);

        const newTickets: ITicketData[] = JSON.parse(JSON.stringify(tickets));

        newTickets[ticketIndex].status = status;

        setTickets(newTickets);
    }

    const setDatesForTimeframe = (dto: IFilterTicketDTO, timeframe: AnalyticsTimeframesEnum) => {
        const today = DateTime.now();

        if (selectedTimeframe === AnalyticsTimeframesEnum.TODAY) {
            dto.startDate = today.startOf('day').toMillis();
            dto.endDate = today.endOf('day').toMillis();
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_WEEK) {
            const lastWeek = today.minus({weeks: 1});

            dto.startDate = lastWeek.startOf('week').toMillis();
            dto.endDate = lastWeek.endOf('week').toMillis();
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_MONTH) {
            const lastMonth = today.minus({month: 1});

            dto.startDate = lastMonth.startOf('month').toMillis();
            dto.endDate = lastMonth.endOf('month').toMillis();
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_YEAR) {
            const lastYear = today.minus({year: 1});

            dto.startDate = lastYear.startOf('year').toMillis();
            dto.endDate = lastYear.endOf('year').toMillis();
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.FIXED_TIMEFRAME
            && startDate
            && endDate) {
            dto.startDate = DateTime.fromJSDate(startDate).startOf('day').toMillis();
            dto.endDate = DateTime.fromJSDate(endDate).endOf('day').toMillis();
        }
    }

    useEffect(() => {
        if (token) {
            resetTickets();
        }
    }, [token]);

    useEffect(() => {
        const dto = {} as IFilterTicketDTO;
        
        if (selectedRole) {
            dto.role = getBackendRoleFromConciergeRole(selectedRole);
        }

        if (selectedTimeframe || selectedTimeframe === AnalyticsTimeframesEnum.TODAY) {
            setDatesForTimeframe(dto, selectedTimeframe);
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.FIXED_TIMEFRAME && !endDate) {
            return;
        }

        if (selectedStatus || selectedStatus === TicketStatusEnum.OPEN) {
            dto.status = selectedStatus;
        }

        if (selectedConcierge.length > 0) {
            dto.searchString = selectedConcierge[0].email;
        }

        // if (Object.keys(dto).length !== 0) {
            filterTickets(dto)
            .then(response => {
                setTickets(response);
            })
            .catch(error => {
                alert(error);
            })
        // }
    }, [selectedStatus, selectedTimeframe, selectedRole, startDate, endDate, selectedConcierge])

    return (
        <div className="ticket_management__wrapper">
            <div className="ticket_management__header">
                <div className="ticket_management__search">
                    <Autocomplete
                        selected={selectedConcierge}
                        setSelected={handleSetSelected}
                        suggestions={suggestions}
                        setSuggestions={handleSetSuggestions}
                        searchingType={AutocompleteTypesEnum.TICKET}
                        setTicket={handleShowInformationModal}
                    />
                </div>
                <div className="ticket_management__filters">
                    {renderStatuses()}
                    {/* {renderRoles()} */}
                    {
                        selectedConcierge.length === 0 && (
                            renderRoles()
                        )
                    }
                    {renderTimeframes()}
                    <div className="ticket_management__dropdown_container">
                        <div className="ticket_management__selected">
                            <DatePicker
                                selected={startDate}
                                onChange={handleSetDate}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                            />
                            <p>{renderDates()}</p>
                        </div>
                    </div>
                    {/* {renderPools()} */}
                    {
                        selectedConcierge.length === 0 && (
                            renderPools()
                        )
                    }
                </div>
            </div>
            {
                selectedConcierge.length > 0
                ? <ConciergeCard concierge={selectedConcierge[0]} />
                : <hr />
            }
            <div className="ticket_management__ticket_container">
                {renderTickets()}
            </div>
            <Modal
                isOpen={showInformationModal}
                className="ticket_information__modal"
                onRequestClose={() => setShowInformationModal(false)}
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                
                <div className="modal__scroll_component__ticket_information">
                    <TicketInformationModal ticket={selectedTicket} closeModal={handleCloseInformationModal} updateTicketList={updateTicketList} />
                </div>
            </Modal>
        </div>
    )
}

export default TicketManagementPage
