import React, { createContext, FC, useState } from 'react'

interface IContactCallTimerContext {
    contactCallTime: number;
    setContactCallTime(state: number): void;
    isContactCallOn: boolean;
    setIsContactCallOn(state: boolean): void;
    contactCallTimer: ReturnType<typeof setInterval> | undefined;
    setContactCallTimer (state: ReturnType<typeof setInterval> | undefined): void;
    startContactCallTimer(startMinutes: number): void;
    stopContactCallTimer(): void;
    resetContactCallTimer(): void;
};

const ContactCallTimerContext = createContext({} as IContactCallTimerContext);

interface ContactCallTimerContextProps {
    children: React.ReactNode;
};

const ContactCallTimerContextProvider: FC<ContactCallTimerContextProps> = (props) => {
    const [contactCallTime, setContactCallTime] = useState(0);
    const [isContactCallOn, setIsContactCallOn] = useState(false);
    const [contactCallTimer, setContactCallTimer] = useState<ReturnType<typeof setInterval>>();

    const startContactCallTimer = (startMinutes: number) => {
        setIsContactCallOn(true);
        setContactCallTime(startMinutes * 60);

        let seconds = startMinutes * 60;

        setContactCallTimer(
            setInterval(
                () => {
                    if (seconds > 0) {
                        seconds -= 1;
                    }

                    setContactCallTime(seconds);
                },
                1000
            )
        )
    }

    const stopContactCallTimer = () => {
        setIsContactCallOn(false);

        if (contactCallTimer) {
            clearInterval(contactCallTimer);
        }
    }

    const resetContactCallTimer = () => {
        setContactCallTime(0);
        setIsContactCallOn(false);
        setContactCallTimer(undefined);
    }

    const providerValue = {
        contactCallTime,
        setContactCallTime,
        isContactCallOn,
        setIsContactCallOn,
        contactCallTimer,
        setContactCallTimer,
        startContactCallTimer,
        stopContactCallTimer,
        resetContactCallTimer
    }

    return (
        <ContactCallTimerContext.Provider value={providerValue}>
            {props.children}
        </ContactCallTimerContext.Provider>
    )
}

export { ContactCallTimerContext, ContactCallTimerContextProvider }
