import React, { FC, useContext, useState } from 'react';
import './App.css';
import { AppTemplate } from "./templates/index";
import {
  Switch,
  Route
} from "react-router-dom";
import { DashboardLinkEnum } from './util/enums';
import {
  AnalyticsPage,
  EmployeesPage,
  SubscriptionPlanPage,
  TicketManagementPage,
  CreateEmployeePage,
  ResetPassword,
  LoggedOutPage,
  EditProfilePage
} from "./pages/index";
import { CCPPortal } from './components';
import "./scss/main.scss";
import { GlobalStateContext } from './contexts';

const App: FC <{}> = () => {
  const { isLoggedOut } = useContext(GlobalStateContext);

  return (
    <div>
      <CCPPortal>

      </CCPPortal>
      {
        isLoggedOut
        ? <LoggedOutPage />
        : <Switch>
          <Route path="/password/:hash">
            <ResetPassword></ResetPassword>
          </Route>
          <AppTemplate>
              <Route path={DashboardLinkEnum.ANALYTICS}>
                <AnalyticsPage></AnalyticsPage>
              </Route>
              <Route path={DashboardLinkEnum.TICKET_MANAGEMENT}>
                <TicketManagementPage></TicketManagementPage>
              </Route>
              <Route path={DashboardLinkEnum.SUBSCRIPTION_PLAN}>
                <SubscriptionPlanPage></SubscriptionPlanPage>
              </Route>
              <Route path={DashboardLinkEnum.EMPLOYEES}>
                <EmployeesPage/>
              </Route>
              <Route path={DashboardLinkEnum.CREATE_EMPLOYEES}>
                <CreateEmployeePage />
              </Route>
              <Route path="/edit-profile">
                <EditProfilePage />
              </Route>
          </AppTemplate>
        </Switch>
      }
      
    </div>
  );
}

export default App;
