import React from 'react'

const LoaderModal = () => {
    return (
        <div className="loader__wrapper">
            <img src="/assets/loader.gif" alt="loading" />
        </div>
    )
}

export default LoaderModal
