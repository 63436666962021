import React, { FC, useContext, useEffect, useState } from 'react'
import { AnalyticsTimeframesEnum } from '../../../../util/enums'
import { Line, Bar } from 'react-chartjs-2'
import useDebounce from '../../../../hooks/useDebounce';
import { AnalyticsContext, AuthenticationContext, ConciergeContext } from '../../../../contexts';
import { IAnalyticComponentInfoData, IAnalyticsData, IConciergeData } from '../../../../interfaces';
import { getUnitFromAnalyticTitle } from '../../../../util/functions';
import { DateTime, Interval } from 'luxon';
import { getDataForAnalytic } from '../../../../util/functions/AnalyticsPropertyHelper';

interface AnalyticComponentProps {
    timeframe: AnalyticsTimeframesEnum;
    searchable?: boolean;
    info: IAnalyticComponentInfoData;
    data: IAnalyticsData[];
    startDate?: DateTime | null;
    endDate?: DateTime | null;
}

const AnalyticComponent: FC<AnalyticComponentProps> = (props) => {
    const { timeframe, info, data, startDate, endDate, searchable } = props;

    const [analyticsData, setAnalyticsData] = useState(data);
    const [selectedTimeframe, setSelectedTimeframe] = useState(timeframe);
    const [componentStartDate, setComponentStartDate] = useState(startDate);
    const [componentEndDate, setComponentEndDate] = useState(endDate);
    const [selectedConcierge, setSelectedConcierge] = useState<IConciergeData | undefined>(undefined);
    const [showInfo, setShowInfo] = useState(false);
    const [isSelectSelected, setIsSelectSelected] = useState(false);
    const [isComponentSelected, setIsComponentSelected] = useState(false);
    const [search, setSearch] = useState({
        query: "",
    });

    const searchWithDebounce = useDebounce(search.query, 300);
    
    const { getConciergesByName } = useContext(ConciergeContext);
    const { getAnalytics } = useContext(AnalyticsContext);
    const { token } = useContext(AuthenticationContext);
    
    const options = {
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                beginAtZero: true,
            },
        },
        maintainAspectRatio: false
    };

    const handleOnInputChange = (value: string) => {
        setSearch({
            query: value
        });
    }

    const getArrayFromDate = (starDate: DateTime, endDate: DateTime) => {
        const interval = Interval.fromDateTimes(starDate, endDate);

        return Array.from(days(interval));
    }

    const formatMetrics = (startDate: DateTime, endDate: DateTime, analyticsData: IAnalyticsData[]) => {
        const array = getArrayFromDate(startDate, endDate);

        let metrics: number[] | number[][] = [];

        if (info.title === 'Call Volume') {
            metrics = [[], []];
        }
        
        for (let i = 0; i < array.length; i++) {
            const date = array[i];
            
            const data = analyticsData.find(metric => {
                const metricDate = DateTime.fromISO(metric.createdAt);

                if (metricDate >= date.startOf('day') && metricDate <= date.endOf('day')) {
                    return metric;
                }

                return undefined;
            });

            if (data) {
                const formattedData = getDataForAnalytic(data, info.title);

                if (Array.isArray(formattedData)) {
                    (metrics[0] as number[]).push(formattedData[0]);
                    (metrics[1] as number[]).push(formattedData[1]);

                    continue;
                }

                (metrics as number[]).push(formattedData);

                continue;
            }

            if (info.title === 'Call Volume') {
                (metrics[0] as number[]).push(0);
                (metrics[1] as number[]).push(0);

                continue;
            }

            (metrics as number[]).push(0);
        }

        return metrics;
    }

    const handleSelectChange = (value: string) => {
        setIsComponentSelected(true);
        setSelectedTimeframe(parseInt(value));
    }

    function* days(interval: Interval) {
        if (interval.isValid) {
            let cursor = interval.start.startOf("day");

            while (cursor < interval.end) {
                yield cursor;
                cursor = cursor.plus({ days: 1 });
            }
        }
    }

    const timeframeToDisplay = (timeframe: AnalyticsTimeframesEnum) => {
        switch (timeframe) {
            case AnalyticsTimeframesEnum.TODAY:
                return 'Today';
            case AnalyticsTimeframesEnum.LAST_WEEK:
                return 'Last week';
            case AnalyticsTimeframesEnum.LAST_MONTH:
                return 'Last month';
            case AnalyticsTimeframesEnum.LAST_YEAR:
                return 'Last year';
            default:
                return 'Custom';
        }
    }

    const renderTimeframeOptions = () => {
        return Object.keys(AnalyticsTimeframesEnum).map((key) => {
            if (key > '4' && key !== "FIXED_TIMEFRAME") {
                const value = AnalyticsTimeframesEnum[key as keyof typeof AnalyticsTimeframesEnum];
                return (
                    <option
                        key={key}
                        value={value}
                    >
                        {timeframeToDisplay(value)}
                    </option>
                );
            }

            return null;
        });
    }

    const analyticToDisplay = () => {
        switch (selectedTimeframe) {
            case AnalyticsTimeframesEnum.TODAY:
                return handleTodayAnalytics();

            case AnalyticsTimeframesEnum.LAST_WEEK:
                return handleLastWeekAnalytics();

            case AnalyticsTimeframesEnum.LAST_MONTH:
            case AnalyticsTimeframesEnum.FIXED_TIMEFRAME:
                return handleLastMonthAndFixedAnalytics();

            case AnalyticsTimeframesEnum.LAST_YEAR:
                return handleLastYearAnalytics();

            default:
                return null;
        }
    }

    const handleTodayAnalytics = () => {
        const today = DateTime.now();

        const todayData = formatMetrics(today.startOf('day'), today.endOf('day'), analyticsData);

        if (Array.isArray(todayData[0])) {
            return (
                <div className="analytics_component__today">
                    <p>{(todayData[0] as number[])[0] ? (todayData[0] as number[])[0] : '0'} {getUnitFromAnalyticTitle(info.title)}</p>
                    <p>{(todayData[1] as number[])[0] ? (todayData[1] as number[])[0] : '0'} {getUnitFromAnalyticTitle(info.title)}</p>
                </div>
            );
        }

        return (
            <div className="analytics_component__today">
                <p>{todayData[0] ? todayData[0] : '0'} {getUnitFromAnalyticTitle(info.title)}</p>
            </div>
        );
    }

    const handleLastWeekAnalytics = () => {
        const today = DateTime.now();

        const lastWeek = today.minus({weeks: 1});

        const weekData = formatMetrics(lastWeek.startOf('week'), lastWeek.endOf('week'), analyticsData);

        let weekDatasets = [{}];

        if (Array.isArray(weekData[0])) {
            weekDatasets = [
                {
                    data: weekData[0],
                    fill: false,
                    borderColor: '#4CDA64',
                },
                {
                    data: weekData[1],
                    fill: false,
                    borderColor: '#F2994A',
                },
            ];
        }
        else {
            weekDatasets = [
                {
                    data: weekData,
                    fill: false,
                    borderColor: '#4CDA64',
                },
            ];
        }

        const weeklyData = {
            labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            datasets: weekDatasets
        };

        return (
            <div className="analytic_component__interval_container">
                {/* <div className="analytic_component__search">
                    <img src="/assets/search.svg" alt="" />
                    <input
                        type="text"
                        placeholder="Search Employee"
                        value={search.query}
                        onChange={e => handleOnInputChange(e.target.value)}
                    />
                </div> */}
                <div className="analytic_component__interval">
                    <Line data={weeklyData} options={options} type='line' />
                </div>
            </div>
        );
    }

    const handleLastMonthAndFixedAnalytics = () => {
        if (componentStartDate && componentEndDate) {
            const intervalData = formatMetrics(componentStartDate, componentEndDate, analyticsData);

            const array = getArrayFromDate(componentStartDate, componentEndDate)

            const labels = array.map(date => date.toFormat('dd LLL'));

            let datasets = [{}];

            if (Array.isArray(intervalData[0])) {
                datasets = [
                    {
                        data: intervalData[0],
                        fill: false,
                        borderColor: '#4CDA64',
                    },
                    {
                        data: intervalData[1],
                        fill: false,
                        borderColor: '#F2994A',
                    },
                ];
            }
            else {
                datasets = [
                    {
                        data: intervalData,
                        fill: false,
                        borderColor: '#4CDA64',
                    },
                ];
            }

            const monthlyData = {
                labels: labels,
                datasets: datasets
            };

            return (
                <div className="analytic_component__interval_container">
                    <div className="analytic_component__interval">
                        <Line data={monthlyData} options={options} type='line' />
                    </div>
                </div>
            );
        }
        
        return null;
    }

    const handleLastYearAnalytics = () => {
        const today = DateTime.now();

        const lastYear = today.minus({years: 1});

        const yearData = formatMetrics(lastYear.startOf('year'), lastYear.endOf('year'), analyticsData);

        let yearDatasets = [{}];

        if (Array.isArray(yearData[0])) {
            yearDatasets = [
                {
                    data: yearData[0],
                    fill: false,
                    borderColor: '#4CDA64',
                    backgroundColor: '#4CDA64',
                    barThickness: 5,
                    borderRadius: 3
                },
                {
                    data: yearData[1],
                    fill: false,
                    borderColor: '#F2994A',
                    backgroundColor: '#F2994A',
                    barThickness: 5,
                    borderRadius: 3
                },
            ];
        }
        else {
            yearDatasets = [
                {
                    data: yearData,
                    fill: false,
                    borderColor: '#4CDA64',
                    backgroundColor: '#4CDA64',
                    barThickness: 5,
                    borderRadius: 3
                },
            ];
        }

        const yearlyData = {
            labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: yearDatasets
        };

        return (
            <div className="analytic_component__interval_container">
                <div className="analytic_component__interval">
                    <Bar data={yearlyData} options={options} type='line' />
                </div>
            </div>
        );
    }

    const handleGetAnalytics = (startDate: DateTime, endDate: DateTime) => {
        if (token) {
            getAnalytics(startDate.toMillis(), endDate.toMillis())
            .then(response => {
                setAnalyticsData(response);
            })
        }
    }

    // useEffect(() => {
    //     let mounted = true;

    //     if (searchWithDebounce) {
    //         getConciergesByName(searchWithDebounce)
    //             .then((concierges: IConciergeData[]) => {
    //                 if (mounted) {
    //                     setSelectedConcierge(concierges[0]);
    //                 }
    //             });

    //         return function cleanup() {
    //             mounted = false;
    //         };
    //     }
    // }, [searchWithDebounce, getConciergesByName])

    useEffect(() => {
        setSelectedTimeframe(timeframe);
        setIsComponentSelected(false);
    }, [timeframe]);
    
    useEffect(() => {
        setAnalyticsData(data);
    }, [data]);
    
    useEffect(() => {
        setComponentStartDate(startDate);
    }, [startDate]);
    
    useEffect(() => {
        setComponentEndDate(endDate);
    }, [endDate]);

    useEffect(() => {
        if (isComponentSelected) {
            const today = DateTime.now();
    
            if (selectedTimeframe === AnalyticsTimeframesEnum.TODAY) {
                handleGetAnalytics(today, today);
            }
    
            if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_WEEK) {
                const lastWeek = today.minus({weeks: 1});
    
                handleGetAnalytics(lastWeek.startOf('week'), lastWeek.endOf('week'));
            }
    
            if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_MONTH) {
                const lastMonth = today.minus({month: 1});
    
                setComponentStartDate(lastMonth.startOf('month'));
                setComponentEndDate(lastMonth.endOf('month'));
    
                handleGetAnalytics(lastMonth.startOf('month'), lastMonth.endOf('month'));
            }
    
            if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_YEAR) {
                const lastYear = today.minus({year: 1});
    
                handleGetAnalytics(lastYear.startOf('year'), lastYear.endOf('year'));
            }
        }
    }, [selectedTimeframe, isComponentSelected]);

    return (
        <div className="analytic_component__wrapper" style={selectedTimeframe === AnalyticsTimeframesEnum.TODAY ? {height: '120px'} : {}}>
            <div className="analytic_component__header">
                <h2>{info.title}</h2>
                <div className="analytic_component__info">
                    <img
                        src="/assets/info.svg"
                        alt="i"
                        onMouseEnter={() => setShowInfo(true)}
                        onMouseLeave={() => setShowInfo(false)}
                    />
                    {
                        showInfo
                        ? <p className="analytic_component__info_text">{info.description}</p>
                        : null
                    }
                </div>
                <div className="analytic_component__timeframe">
                    <select
                        value={selectedTimeframe}
                        onChange={e => handleSelectChange(e.target.value)}
                        onFocus={() => setIsSelectSelected(true)}
                        onBlur={() => setIsSelectSelected(false)}
                        className={isSelectSelected ? 'analytic_component__selected_select' : ''}
                    >
                        {renderTimeframeOptions()}
                    </select>
                </div>
            </div>
            <div className="analytic_component__content">
                {analyticToDisplay()}
            </div>
        </div>
    )
}

export default AnalyticComponent
