import React, { FC, useContext, useEffect, useState } from 'react'
import { GlobalStateContext, SecurityQuestionContext, TicketContext, GlobalTimerContext, ConciergeContext, AmazonConnectContext } from '../../../contexts';
import { ICustomerWithTicketData, IUpdateTicketDTO } from '../../../interfaces';
import { ConciergeBackendStatusEnum, TicketActionTypeEnum, TicketStatusEnum } from '../../../util/enums';
import { getAgentStateFromConciergeStatus } from '../../../util/functions';
import Timer from '../../Timer/Timer';

interface TicketSummaryModalProps {
    totalCallTime: number;
}

const TicketSummaryModal: FC<TicketSummaryModalProps> = (props) => {
    const { totalCallTime } = props;

    const { customerData, setCustomerData } = useContext(SecurityQuestionContext);
    const { updateTicket } = useContext(TicketContext);
    const { setShowTicketSummaryModal, setShowModal, setActiveModal } = useContext(GlobalStateContext);
    const { updateConciergeStatus, getCurrentConcierge, conciergeData } = useContext(ConciergeContext);
    const { agent } = useContext(AmazonConnectContext);
    
    const [showTypeDropdown, setShowTypeDropdown] = useState(false);
    const [conciergeNote, setConciergeNote] = useState('');
    const [customerNote, setCustomerNote] = useState('');

    const handleSelectStatus = (status: TicketStatusEnum) => {
        const newCustomerData: ICustomerWithTicketData = JSON.parse(JSON.stringify(customerData));

        newCustomerData.ticket.status = status;

        setCustomerData(newCustomerData);
    }

    const handleUpdateTicket = () => {
        if (customerData) {
            const dto = {
                conciergeId: customerData.ticket.conciergeId,
                conciergeNote: conciergeNote ? conciergeNote : '',
                customerId: customerData.ticket.customerId,
                customerNote: customerNote ? customerNote : '',
                status: customerData.ticket.status
            } as IUpdateTicketDTO;
    
            updateTicket(customerData.ticket.id, dto)
            .then(response => {
                setShowModal(false);
                setActiveModal(undefined);

                handleUpdateConciergeStatusToAvailable();
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    const handleUpdateConciergeStatusToAvailable = () => {
        if (conciergeData) {
            updateConciergeStatus(conciergeData.id, ConciergeBackendStatusEnum.Routable)
            .then(response => {
                const agentState = getAgentStateFromConciergeStatus(ConciergeBackendStatusEnum.Routable, agent);
                
                if (agentState) {
                    agent?.setState(agentState);
                }
                
                getCurrentConcierge();
            })
        }
    }

    const renderStatusName = (value: TicketStatusEnum | undefined) => {
        switch (value) {
            case TicketStatusEnum.OPEN:
                return 'Open';
            case TicketStatusEnum.CLOSED_COMPLETE:
                return 'Closed Complete';
            case TicketStatusEnum.CLOSE_PENDING:
                return 'Closed Pending';
            case TicketStatusEnum.PENDING_COMPLETION:
                return 'Pending Completion';
            case TicketStatusEnum.CLOSED_INCOMPLETE:
                return 'Closed Incomplete';
            case TicketStatusEnum.REVIEW:
                return 'Review';
            case TicketStatusEnum.AUDIT:
                return 'Audit';
            default:
                return '';
        }
    }

    const renderTicketActions = () => {
        return customerData?.ticket.actions.map(action => {
            switch (action.type) {
                case TicketActionTypeEnum.Call:
                    return <div className="ticket_summary__ticket_info">
                        <h2>Call</h2>
                        <span>With: Carol Beck</span>
                        {/* <span>
                            With: {action.toContact.userAttributes.firstName + ' ' + action.toContact.userAttributes.lastName}
                        </span> */}
                        <span>Call Duration: 00:46</span>
                    </div>

                case TicketActionTypeEnum.ContactListing:
                    return <div className="ticket_summary__ticket_info">
                        <h2>Contact Listing</h2>
                        <span>Showed Contacts</span>
                    </div>

                case TicketActionTypeEnum.Message:
                    return <div className="ticket_summary__ticket_info">
                        <h2>Message</h2>
                        <span className="ticket_summary__bottom_margin">To: Carol Beck</span>
                        {/* <span className="ticket_summary__bottom_margin">
                            To: {action.toContact.userAttributes.firstName + ' ' + action.toContact.userAttributes.lastName}
                        </span> */}
                        <span>Message:</span>
                        <p>
                            <b><i>I'm stuck in traffic and my phone is dead, can you please pick up the kids from school.</i></b>
                        </p>
                        {/* <p>
                            <b><i>{action.message}</i></b>
                        </p> */}
                    </div>

                case TicketActionTypeEnum.VoiceMessage:
                    return <div className="ticket_summary__ticket_info">
                        <h2>Voice Message</h2>
                        <span>To: Carol Beck</span>
                        {/* <span>
                            With: {action.toContact.userAttributes.firstName + ' ' + action.toContact.userAttributes.lastName}
                        </span> */}
                    </div>

                case TicketActionTypeEnum.BoostPlan:
                    return <div className="ticket_summary__ticket_info">
                        <h2>Boost Plan</h2>
                        <span>
                            From: Plus / monthly
                        </span>
                        <span className="ticket_summary__bottom_margin">
                            To: <b>Premium / monthly</b>
                        </span>
                        {/* <span>
                            From: {action.fromPlan.name} / {action.fromPlan.type}
                        </span> */}
                        {/* <span className="ticket_summary__bottom_margin">
                            To: <b>{action.fromPlan.name} / {action.fromPlan.type}</b>
                        </span> */}
                        <p className="ticket_summary__green">
                            New Credit Card Added.
                        </p>
                    </div>

                case TicketActionTypeEnum.MinutesAdded:
                    return <div className="ticket_summary__ticket_info">
                        <h2>Minutes Added</h2>
                        <span>
                            Type: <b>Call-to-Contact minutes</b>
                        </span>
                        <span>
                            Minutes: <b>5 minutes</b>
                        </span>
                        <span>
                            Total charged: <b>$5</b>
                        </span>
                        {/* <span>
                            Type: <b>{action.minutesAdded.type ? 'Call-to-Contact minutes' : 'Support Call minutes'}</b>
                        </span>
                        <span>
                            Minutes: <b>{action.minutesAdded.minutes > 1 ? action.minutesAdded.minutes + ' minute' : action.minutesAdded.minutes + ' minutes'}</b>
                        </span>
                        <span>
                            Total charged: <b>${action.minutesAdded.chargedAmount}</b>
                        </span> */}
                    </div>
            }
        });
    }

    const renderTicketStatuses = () => {
        return Object.keys(TicketStatusEnum).map((key) => {
            if (key > '6') {
                const value = TicketStatusEnum[key as keyof typeof TicketStatusEnum];
    
                if (value === customerData?.ticket.status) {
                    return;
                }
    
                return (
                    <div
                        key={key}
                        className="ticket_summary__dropdown_item"
                        onClick={() => handleSelectStatus(value)}
                    >
                        <span>{renderStatusName(value)}</span>
                        <div className="ticket_summary__dropdown_divider"></div>
                    </div>
                );
            }
        });
    }

    useEffect(() => {
        if (customerData) {
            setConciergeNote(customerData.ticket.conciergeNote);
            setCustomerNote(customerData.ticket.customerNote);
        }
    }, [])

    return (
        <div className="ticket_summary__wrapper">
            <div className="ticket_summary__call_info">
                {/* <p>Michael Garcia</p> */}
                <p>{customerData?.customer.userAttributes.firstName + ' ' + customerData?.customer.userAttributes.lastName}</p>
                <span className="ticket_summary__green">Call finished</span>
                {/* <span>Call duration: 02:10</span> */}
                <span>Call duration: <Timer time={totalCallTime} /></span>
                <span>Date: 07.03.2021.</span>
            </div>
            <div className="ticket_summary__ticket_info__container">
                <h1>Ticket Summary</h1>
                <div className="ticket_summary__ticket_info">
                    <h2>Call 1</h2>
                    <span>With: Carol Beck</span>
                    <span>Call Duration: 00:46</span>
                </div>
                <hr />
                <div className="ticket_summary__ticket_info">
                    <h2>Call 2</h2>
                    <span>With: Will Smith</span>
                    <span>Call Duration: 00:29</span>
                </div>
                <hr />
                <div className="ticket_summary__ticket_info">
                    <h2>Contact Listing</h2>
                    <span>Showed Contacts</span>
                </div>
                <hr />
                <div className="ticket_summary__ticket_info">
                    <h2>Message</h2>
                    <span className="ticket_summary__bottom_margin">To: Carol Beck</span>
                    <span>Message:</span>
                    <p><b><i>I'm stuck in traffic and my phone is dead, can you please pick up the kids from school.</i></b></p>
                </div>
                <hr />
                <div className="ticket_summary__ticket_info">
                    <h2>Voice Message</h2>
                    <span>To: Carol Beck</span>
                </div>
                <hr />
                <div className="ticket_summary__ticket_info">
                    <h2>Boost Plan</h2>
                    <span>From: Plus / monthly</span>
                    <span className="ticket_summary__bottom_margin">To: <b>Premium / monthly</b></span>
                    <p className="ticket_summary__green">
                        New Credit Card Added.
                    </p>
                </div>
                <hr />
                <div className="ticket_summary__ticket_info">
                    <h2>Minutes Added</h2>
                    <span>Type: <b>Call-to-Contact minutes</b></span>
                    <span>Minutes: <b>5 minutes</b></span>
                    <span>Total charged: <b>$5</b></span>
                </div>
                <hr />
                <div className="ticket_summary__ticket_info">
                    <h2>Customer's note (Public)</h2>
                    {/* <textarea>
                        Please send the following messge to my priority list:
                        Hello, please make sure to come to Dannys tonight! My phone is currently dead, and I’m getting a new one so I won’t be aveliable. See ya!
                    </textarea> */}
                    <textarea
                        value={customerNote}
                        onChange={e => setCustomerNote(e.target.value)}
                    />
                </div>
                <div className="ticket_summary__ticket_info">
                    <h2>My note (Private)</h2>
                    {/* <textarea>
                        The user was agressive after he was informed that his credit card has been rejected.
                    </textarea> */}
                    <textarea
                        value={conciergeNote}
                        onChange={e => setConciergeNote(e.target.value)}
                    />
                </div>
                <hr />
                <div className="ticket_summary__dropdown_container">
                    <div
                        className={`ticket_summary__selected ticket_summary__selected__${customerData?.ticket.status}`}
                        tabIndex={0}
                        onClick={() => setShowTypeDropdown(!showTypeDropdown)}
                        // onBlur={() => setShowTypeDropdown(false)}
                    >
                        <div className="ticket_summary__divider"></div>
                        <div className="ticket_summary__selected_container">
                            {
                                <span>{renderStatusName(customerData?.ticket.status)}</span>
                            }
                        </div>
                        <div className="ticket_summary__arrow_container">
                            <img src="/assets/arrow.svg" alt="v" />
                        </div>
                    </div>
                    {
                        showTypeDropdown && (<div
                            className="ticket_summary__dropdown"
                            tabIndex={9}
                            onClick={() => setShowTypeDropdown(!showTypeDropdown)}
                            onBlur={() => setShowTypeDropdown(false)}
                        >
                            <div className="ticket_summary__dropdown__selected_type">
                                <div className="ticket_summary__divider"></div>
                                <div className="ticket_summary__selected_container">
                                    {
                                        <span>{renderStatusName(customerData?.ticket.status)}</span>
                                    }
                                </div>
                                <div className="ticket_summary__arrow_container">
                                    <img src="/assets/arrow.svg" alt="v" />
                                </div>
                            </div>
                            <div className="ticket_summary__dropdown_divider"></div>
                            {renderTicketStatuses()}
                        </div>)
                    }
                </div>
                <button
                    onClick={handleUpdateTicket}
                >
                    Done
                </button>
            </div>
            <div className="ticket_summary__divider"></div>
        </div>
    )
}

export default TicketSummaryModal
