import React, { FC, useContext, useEffect, useState } from 'react'
import { AmazonConnectContext, ConciergeContext, GlobalStateContext, SecurityQuestionContext, TicketContext, GlobalTimerContext, CustomerContext, SupportCallTimerContext } from '../../../contexts'
import { ICustomerSubscriptionData, ICustomerWithTicketData, IEditCustomerSubscriptionDTO, IReassignTicketDTO } from '../../../interfaces';
import { ConciergeBackendStatusEnum, InCallActionsEnum, ModalTypesEnum } from '../../../util/enums';
import { getAgentStateFromConciergeStatus } from '../../../util/functions';

interface IncomingCallModalProps {
    setIsTotalTimeOn(state: boolean): void;
    setIsSupportCallTimeOn(state: boolean): void;
    setIsChargedMinutesTimeOn(state: boolean): void;
}

const IncomingCallModal: FC<IncomingCallModalProps> = (props) => {
    const { setIsChargedMinutesTimeOn, setIsSupportCallTimeOn, setIsTotalTimeOn } = props;

    const { contact, agent } = useContext(AmazonConnectContext);
    const { setShowCustomerAuthModal, setShowInCallModal, setShowIncomingCallModal, setShowModal, setActiveModal } = useContext(GlobalStateContext);
    const { customerData, setCustomerData, setIsCustomerEnrolled } = useContext(SecurityQuestionContext);
    const { reassignTicket } = useContext(TicketContext);
    const { conciergeData } = useContext(ConciergeContext);
    const { startTotalCallTimer } = useContext(GlobalTimerContext);
    const { startSupportCallMinutesTimer, startSupportCallTimer } = useContext(SupportCallTimerContext);
    const { getCustomerByPhoneNumber, editCustomerSubscription } = useContext(CustomerContext);
    const { updateConciergeStatus, getCurrentConcierge } = useContext(ConciergeContext);

    const [authenticated, setAuthenticated] = useState(false);
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
    const [conciergeName, setConciergeName] = useState('');

    const handleAcceptCall = () => {
        if (contact) { 
            contact.accept({
                success: () => {
                    if (conciergeName.length > 0 && customerData) {
                        handleUpdateConciergeStatusToOccupied();

                        handleReassignTicket();

                        return;
                    }

                    if (customerPhoneNumber.length) {
                        getCustomerByPhoneNumber(customerPhoneNumber)
                        .then(response => {
                            if (!response.customer) {
                                alert('There is no customer associated with the phone number.');

                                return;
                            }

                            setIsCustomerEnrolled(response.customer.isEnrolled);

                            if (authenticated) {
                                setCustomerData(response);
    
                                setIsTotalTimeOn(true);
                                
                                handleUpdateConciergeStatusToOccupied();

                                if (response.customer.subscription) {
                                    if (response.customer.subscription.remainingNumOfSupportCalls > 0) {
                                        handleEditCustomerSubscription(response, response.customer.subscription);
                    
                                        return;
                                    }
                                    
                                    setIsChargedMinutesTimeOn(true);
                                    
                                    setShowIncomingCallModal(false);
                                    
                                    setShowModal(true);
                                    setActiveModal(ModalTypesEnum.IN_CALL);

                                    return;
                                }
                            }
                        })
                        .catch(error => {
                            alert('There is no customer associated with the phone number.');

                            return;
                        })
                    }
                    
                    handleUpdateConciergeStatusToOccupied();
            
                    setIsTotalTimeOn(true);

                    setShowIncomingCallModal(false);
                    setShowModal(true);
                    setActiveModal(ModalTypesEnum.CUSTOMER_AUTH);
                }
            });
        }
    }

    const handleUpdateConciergeStatusToOccupied = () => {
        if (conciergeData) {
            updateConciergeStatus(conciergeData.id, ConciergeBackendStatusEnum.NotRoutable)
            .then(response => {
                const agentState = getAgentStateFromConciergeStatus(ConciergeBackendStatusEnum.NotRoutable, agent);
                
                if (agentState) {
                    agent?.setState(agentState);
                }
                
                getCurrentConcierge();
            })
        }
    }

    const handleReassignTicket = () => {
        const dto = {
            conciergeId: conciergeData?.id,
            ticketId: customerData?.ticket.id
        } as IReassignTicketDTO;

        reassignTicket(dto)
        .then(response => {
            setIsTotalTimeOn(true);

            setShowModal(true);
            setActiveModal(ModalTypesEnum.IN_CALL);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const handleEditCustomerSubscription = (customerWithTicketData: ICustomerWithTicketData, subscription: ICustomerSubscriptionData) => {
        const dto = {
            customerId: customerWithTicketData.customer.id,
            subscription: {
                remainingNumOfMinsPerCallToContact: subscription.remainingNumOfMinsPerCallToContact,
                remainingNumOfPriorityContactGroups: subscription.remainingNumOfPriorityContactGroups,
                remainingNumOfSupportCalls: subscription.remainingNumOfSupportCalls - 1,
                remainingNumOfMinsPerSupportCall: subscription.remainingNumOfMinsPerSupportCall,
            }
        } as IEditCustomerSubscriptionDTO;

        editCustomerSubscription(dto)
        .then(editResponse => {
            const customerData: ICustomerWithTicketData = JSON.parse(JSON.stringify(customerWithTicketData));

            customerData.customer.subscription = editResponse;

            setCustomerData(customerData);

            // const agentState = getOccupiedAgentState();

            // if (agentState) {
            //     agent?.setState(agentState);
            // }

            setIsSupportCallTimeOn(true);
            
            setShowModal(true);
            setActiveModal(ModalTypesEnum.IN_CALL);
        })
        .catch(error => {
            console.log(error);
            alert(error);
        })
    }

    const renderCaller = () => {
        if (conciergeName.length > 0) {
            return <div className="incoming_call__caller_info">
                {
                    customerData
                    ? <p>{customerData.customer.userAttributes.firstName + ' ' + customerData.customer.userAttributes.lastName}</p>
                    : <p>Not Authenticated</p>
                }
                <p>Redirected to you from:</p>
                <p>{conciergeName}</p>
            </div>
        }

        if (authenticated) {
            return <p>Authenticated</p>
        }

        return <p>Not Authenticated</p>
    }

    const getOccupiedAgentState = () => {
        if (agent) {
            const agentStates = agent.getAgentStates();

            return agentStates.find(state => state.name === 'Occupied');
        }
    }

    const setConciergeData = (previousConciergeNameMap: {name: string, value: string}, customerDataMap: {name: string, value: string}) => {
        const previousConciergeName = previousConciergeNameMap.value;

        setConciergeName(previousConciergeName);

        const customerDataValue = customerDataMap.value;

        if (customerDataValue !== '') {
            setCustomerData(JSON.parse(customerDataValue));
        }
    }

    useEffect(() => {
        if (contact) {
            const attributeMap = contact.getAttributes();

            const authenticatedMap = attributeMap['Authenticated'];
            const customerPhoneNumber = attributeMap['customerPhoneNumber'];
            const customerDataMap = attributeMap['customerData'];
            const previousConciergeNameMap = attributeMap['previousConciergeName'];

            if (customerPhoneNumber) {
                const customerPhoneNumberValue = customerPhoneNumber.value;

                setCustomerPhoneNumber(customerPhoneNumberValue);
            }
            
            if (!authenticatedMap && !customerDataMap && !previousConciergeNameMap) {
                setAuthenticated(false);

                return;
            }

            if (previousConciergeNameMap) {
                setConciergeData(previousConciergeNameMap, customerDataMap);

                return;
            }

            const authenticatedValue = authenticatedMap.value;

            if (authenticatedValue == 'false') {
                setAuthenticated(false);

                return;
            }

            setAuthenticated(true);
        }
    }, [contact]);

    return (
        <div className="incoming_call__wrapper">
            <p>Incoming call</p>
            {renderCaller()}
            <img src="/assets/green_call.svg" alt="" onClick={handleAcceptCall}/>
        </div>
    )
}

export default IncomingCallModal
