import { IContactData, IContactMessageData } from "../../interfaces";

const getFullName = (contact: IContactData | undefined) => {
    let fullName = '';

    if (contact?.myUserAttributes.firstName) {
        fullName += contact.myUserAttributes.firstName;
    }

    if (contact?.myUserAttributes.lastName) {
        fullName += ' ' + contact.myUserAttributes.lastName;
    }

    if (contact?.prefix) {
        fullName += ' ' + contact.prefix;
    }

    if (contact?.phoneticMiddleName) {
        fullName += ' ' + contact.phoneticMiddleName;
    }

    if (contact?.sufix) {
        fullName += ' ' + contact.sufix;
    }

    return fullName;
}

const getFullNameMessageContact = (contact: IContactMessageData | undefined) => {
    let fullName = '';

    if (contact?.firstName) {
        fullName += contact.firstName;
    }

    if (contact?.lastName) {
        fullName += ' ' + contact.lastName;
    }

    if (contact?.prefix) {
        fullName += ' ' + contact.prefix;
    }

    if (contact?.phoneticMiddleName) {
        fullName += ' ' + contact.phoneticMiddleName;
    }

    if (contact?.sufix) {
        fullName += ' ' + contact.sufix;
    }

    return fullName;
}

export { getFullName, getFullNameMessageContact }