import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ResetPasswordContext } from '../../contexts';
import { IResetPasswordDTO } from '../../interfaces';

interface RouteParams {
    hash: string;
}

const ResetPassword = () => {
    const history = useHistory();

    const { hash } = useParams<RouteParams>();

    const { resetPassword } = useContext(ResetPasswordContext);

    const [showError, setShowError] = useState(false);
    const [showMatchError, setShowMatchError] = useState(false);
    const [resetPasswordDTO, setResetPasswordDTO] = useState({
        hash: hash,
        password: '',
        repeatPassword: ''
    } as IResetPasswordDTO);

    // const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})');
    // const passwordRegex = new RegExp('/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/');
    const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/);

    const inputHandler = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        setResetPasswordDTO({
            ...resetPasswordDTO,
            [fieldName]: value,
        });
    };

    const handleSubmit = () => {
        if (showError || showMatchError) {
            return;
        }

        resetPassword(resetPasswordDTO)
        .then((response: any) => {
            console.log(response);
            history.push("/");
        })
    }

    const passwordValidation = () => {
        if (!resetPasswordDTO.password || !resetPasswordDTO.repeatPassword) {
            return;
        }

        if (!passwordRegex.test(resetPasswordDTO.password) || !passwordRegex.test(resetPasswordDTO.repeatPassword)) {
            setShowError(true);
        }
        else {
            setShowError(false);
        }

        if (resetPasswordDTO.password !== resetPasswordDTO.repeatPassword) {
            setShowMatchError(true);
        }
        else {
            setShowMatchError(false);
        }
    }

    useEffect(() => {
        console.log(resetPasswordDTO)
        passwordValidation();
    }, [resetPasswordDTO.password, resetPasswordDTO.repeatPassword]);

    return (
        <div className="reset_password__wrapper">
            <div className="reset_password__logo">
                <img src="/assets/big_logo.svg" alt="725contact" />
            </div>
            <div className="reset_password__form_container">
                <div className="reset_password__form">
                    <p>Enter your password:</p>
                    <input
                        type="password"
                        placeholder="Password"
                        value={resetPasswordDTO.password}
                        onChange={e => inputHandler('password', e)}
                    />
                    <input
                        type="password"
                        placeholder="Repeat password"
                        value={resetPasswordDTO.repeatPassword}
                        onChange={e => inputHandler('repeatPassword', e)}
                    />
                    <span
                        className={showError ? 'reset_password__error' : 'reset_password__hint'}
                    >
                        Password must be at least 10 characters long and must contain at least one uppercase letter, one number and one special character.
                    </span>
                    {
                        showMatchError
                        ? <span className="reset_password__error reset_password__match_error" >Passwords do not match.</span>
                        : null
                    }
                    <button
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
