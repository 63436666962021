import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardCvcElement, TokenResult } from '@stripe/stripe-js';
import React, { FC, useContext, useEffect, useState } from 'react'
import { CreditCardContext, CustomerContext, SecurityQuestionContext, SubscriptionPlanContext } from '../../contexts';
import { IAddCardDTO, ICancelSubscriptionDTO, ICreditCardData, ICustomerData, ICustomerWithTicketData, IUpgradeSubscriptionDTO } from '../../interfaces';
import { CreditCardFormActionEnum, InCallActionsEnum } from '../../util/enums';

interface CreditCardFormProps {
    creditCardAction?: CreditCardFormActionEnum | undefined;
    targetSubscription?: string;
    setShowCreditCard(state: boolean): void;
    setActiveAction?(state: InCallActionsEnum | undefined): void;
}

const CreditCardForm: FC<CreditCardFormProps> = (props) => {
    const { creditCardAction, targetSubscription, setShowCreditCard, setActiveAction } = props;

    const { cancelSubscriptionPlan, upgradeSubscriptionPlan } = useContext(SubscriptionPlanContext);
    const { customerData, setCustomerData } = useContext(SecurityQuestionContext)
    const { getCustomerById } = useContext(CustomerContext);
    const { addDefaultCard } = useContext(CreditCardContext);

    const [creditCardData, setCreditCardData] = useState({
        name: '',
        number: '',
        billingAddress: '',
        expDate: '',
        cvv: ''
    } as ICreditCardData);

    const stripe = useStripe();
    const elements = useElements();

    const inputHandler: Function = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        setCreditCardData({
            ...creditCardData,
            [fieldName]: event.target.value,
        });
    };

    const removeCustomerSubscription = () => {
        const updatedCustomerData: ICustomerWithTicketData = JSON.parse(JSON.stringify(customerData));

        updatedCustomerData.customer.subscription = undefined;

        setCustomerData(updatedCustomerData);
    }

    const updateCustomerData = () => {
        const updatedCustomerData: ICustomerWithTicketData = JSON.parse(JSON.stringify(customerData));

        if (customerData) {
            getCustomerById(customerData?.customer.id)
            .then(response => {
                updatedCustomerData.customer = response;

                setCustomerData(updatedCustomerData);
            })
        }
    }

    const handleCancelPlan = (value: TokenResult) => {
        const dto = {
            card: value.token?.id,
            customerId: customerData?.customer.id
        } as ICancelSubscriptionDTO;

        cancelSubscriptionPlan(dto)
        .then(response => {
            removeCustomerSubscription();

            alert("Successfully canceled customer's subscription.");

            setShowCreditCard(false);
            if (setActiveAction) {
                setActiveAction(undefined);
            }
        })
        .catch(error => {
            alert('There was error canceling the subscription.');
        })
    }

    const handleUpgradePlan = (value: TokenResult) => {
        if (targetSubscription) {
            const subscriptionData = targetSubscription.split('-');
    
            const subscriptionId = parseInt(subscriptionData[0]);
            const paymentType = parseInt(subscriptionData[1]);
    
            const dto = {
                card: value.token?.id,
                customerId: customerData?.customer.id,
                paymentType: paymentType,
                subscriptionId: subscriptionId
            } as IUpgradeSubscriptionDTO;
    
            upgradeSubscriptionPlan(dto)
            .then(response => {
                updateCustomerData();
    
                alert("Successfully upgraded customer's subscription.");
    
                setShowCreditCard(false);
            })
            .catch(error => {
                console.log(error);
                alert('There was error upgrading the subscription.');
            })
        }
    }

    const handleAddCreditCard = (value: TokenResult) => {
        const dto = {
            customerId: customerData?.customer.id,
            card: value.token?.id
        } as IAddCardDTO;

        addDefaultCard(dto)
        .then(response => {
            updateCustomerData();

            alert("Successfully added customer's new card.");

            setShowCreditCard(false);
        })
        .catch(error => {
            alert('There was error adding the new card.');
        })
    }

    const handleContinueCreditCard = () => {
        if (stripe && elements) {
            const cardNumberElement = elements.getElement(CardNumberElement);
            
            if (cardNumberElement) {
                stripe.createToken(cardNumberElement, {
                    name: creditCardData.name,
                    address_line1: creditCardData.billingAddress
                })
                .then(value => {
                    if (value.error) {
                        alert(value.error.message);

                        return;
                    }

                    if (creditCardAction === CreditCardFormActionEnum.CANCEL) {
                        handleCancelPlan(value);

                        return;
                    }

                    if (creditCardAction === CreditCardFormActionEnum.UPGRADE) {
                        handleUpgradePlan(value);

                        return;
                    }

                    if (creditCardAction === CreditCardFormActionEnum.ADD_CARD) {
                        handleAddCreditCard(value);

                        return;
                    }

                    return;
                })
            }
        }
    }

    const handleCancelCreditCard = () => {
        setShowCreditCard(false);
    }

    return (
        <div className="creditcard__container">
            <p>Add Credit Card</p>
            <input
                type="text"
                placeholder="Cardholder name"
                value={creditCardData.name}
                onChange={e => inputHandler('name', e)}
            />
            <CardNumberElement
                className="creditcard__stripe__card_number"
                options={{
                    style: {
                        base: {
                            fontSize: '24px',
                            lineHeight: '28px',
                            color: '#000000',
                            backgroundColor: 'transparent',
                            "::placeholder": {
                                color: "#BDBDBD"
                            }
                        }
                    },
                    placeholder: 'Credit card number'
                }}
            />
            <input
                type="text"
                placeholder="Billing address"
                value={creditCardData.billingAddress}
                onChange={e => inputHandler('billingAddress', e)}
            />
            <div className="creditcard__input_container">
                <CardExpiryElement
                    className="creditcard__stripe__card_exp_cvc"
                    options={{
                        style: {
                            base: {
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#000000',
                                backgroundColor: 'transparent',
                                "::placeholder": {
                                    color: "#BDBDBD"
                                }
                            }
                        },
                        placeholder: 'Exp Date'
                    }}
                />
                <CardCvcElement
                    className="creditcard__stripe__card_exp_cvc"
                    options={{
                        style: {
                            base: {
                                fontSize: '24px',
                                lineHeight: '28px',
                                color: '#000000',
                                backgroundColor: 'transparent',
                                "::placeholder": {
                                    color: "#BDBDBD"
                                }
                            }
                        },
                        placeholder: 'CVC'
                    }}
                />
            </div>
            <div className="creditcard__button_container">
                <button
                    className="creditcard__button__cancel"
                    onClick={handleCancelCreditCard}
                >
                    Cancel
                </button>
                <button
                    className="creditcard__button__continue"
                    onClick={handleContinueCreditCard}
                >
                    Continue
                </button>
            </div>
            {/* <p>Add Credit Card</p>
            <input
                type="text"
                placeholder="Cardholder name"
                value={creditCardData.name}
                onChange={e => inputHandler('name', e)}
            />
            <input
                type="text"
                placeholder="Credit card number"
                value={creditCardData.number}
                onChange={e => inputHandler('number', e)}
            />
            <input
                type="text"
                placeholder="Billing address"
                value={creditCardData.billingAddress}
                onChange={e => inputHandler('billingAddress', e)}
            />
            <div className="creditcard__input_container">
                <input
                    type="text"
                    placeholder="Exp Date"
                    value={creditCardData.expDate}
                    onChange={e => inputHandler('expDate', e)}
                />
                <input
                    type="text"
                    placeholder="CVC"
                    value={creditCardData.cvv}
                    onChange={e => inputHandler('cvv', e)}
                />
            </div>
            <div className="creditcard__button_container">
                <button
                    className="creditcard__button__cancel"
                    onClick={handleCancelCreditCard}
                >
                    Cancel
                </button>
                <button
                    className="creditcard__button__continue"
                    onClick={handleContinueCreditCard}
                >
                    Continue
                </button>
            </div> */}
        </div>
    )
}

export default CreditCardForm
