export enum TicketStatusEnum {
    OPEN,
    CLOSED_COMPLETE,
    CLOSE_PENDING,
    PENDING_COMPLETION,
    CLOSED_INCOMPLETE,
    REVIEW,
    AUDIT
}

export enum TicketActionTypeEnum {
    Call,
    ContactListing,
    Message,
    VoiceMessage,
    BoostPlan,
    MinutesAdded
}