import React, { FC, useContext, useEffect, useState } from 'react'
import { CustomerContext, GlobalStateContext, SecurityQuestionContext, SubscriptionPlanContext } from '../../../contexts';
import { CreditCardFormActionEnum, InCallActionsEnum, SubscriptionTypesEnum } from '../../../util/enums';
import { isSubscriptionSelectionDisabled } from '../../../util/functions/CheckHelper';
import Modal from "react-modal";
import { CreditCardNeededModal } from '../../Modals';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe, TokenResult } from '@stripe/stripe-js';
import CreditCardForm from '../../CreditCardForm/CreditCardForm';
import { ICustomerWithTicketData, IUpgradeSubscriptionDTO } from '../../../interfaces';
import config from '../../../config/config.json';

interface BoostPlanProps {
    setActiveAction(state: InCallActionsEnum | undefined): void;
}

const BoostPlan: FC<BoostPlanProps> = (props) => {
    const { setActiveAction } = props;

    const { customerData, setCustomerData } = useContext(SecurityQuestionContext);
    const { subscriptionPlans, getActiveSubscriptionPlans, upgradeSubscriptionPlan } = useContext(SubscriptionPlanContext);
    const { showCreditCardModal, setShowCreditCardModal } = useContext(GlobalStateContext);
    const { getCustomerById } = useContext(CustomerContext);

    const [showCreditCard, setShowCreditCard] = useState(false);
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [action, setAction] = useState<CreditCardFormActionEnum | undefined>(undefined);
    const [selected, setSelected] = useState(
        customerData?.customer.subscription
        ? customerData?.customer.subscription.subscriptionId + '-' + customerData?.customer.subscription.paymentType
        : '0-0'
    );

    const handleSelectPlan = (value: string) => {
        setSelected(value);
    }

    const updateCustomerData = () => {
        const updatedCustomerData: ICustomerWithTicketData = JSON.parse(JSON.stringify(customerData));

        if (customerData) {
            getCustomerById(customerData?.customer.id)
            .then(response => {
                updatedCustomerData.customer = response;

                setCustomerData(updatedCustomerData);
            })
        }
    }

    const handleCancelPlan = () => {
        setAction(CreditCardFormActionEnum.CANCEL);
        setShowCreditCardModal(true);
    }

    const handleUpgradePlan = () => {
        const subscriptionData = selected.split('-');

        const subscriptionId = parseInt(subscriptionData[0]);
        const paymentType = parseInt(subscriptionData[1]);

        const dto = {
            customerId: customerData?.customer.id,
            paymentType: paymentType,
            subscriptionId: subscriptionId
        } as IUpgradeSubscriptionDTO;

        upgradeSubscriptionPlan(dto)
        .then(response => {
            updateCustomerData();

            alert("Successfully upgraded customer's subscription.");
        })
        .catch(error => {
            if ((error.message as string).includes('Unable')) {
                return;
            }

            setAction(CreditCardFormActionEnum.UPGRADE);
            setShowCreditCardModal(true);
        })
    }

    const renderButtons = (planId: number, selectedPlan: boolean) => {
        const subscriptionData = selected.split('-');

        const subscriptionId = subscriptionData[0];
        const paymentType = subscriptionData[1];

        if (planId === customerData?.customer.subscription?.subscriptionId) {
            if (isSubscriptionBoostable(subscriptionId, paymentType)) {
                return <button
                    onClick={handleUpgradePlan}
                >
                    Boost plan
                </button>
            }

            return <button
                onClick={handleCancelPlan}
            >
                Cancel plan
            </button>
        }

        if (selectedPlan && (parseInt(subscriptionId) !== customerData?.customer.subscription?.subscriptionId)) {
            return <button
                onClick={handleUpgradePlan}
            >
                Boost plan
            </button>
        }

        return <div className="boostplan__divider"></div>
    }

    const isSubscriptionBoostable = (subscriptionId: string, paymentType: string) => {
        return parseInt(subscriptionId) === customerData?.customer.subscription?.subscriptionId 
            && parseInt(paymentType) !== customerData?.customer.subscription?.paymentType;
    }

    const renderSubscriptionPlans = () => {
        if (subscriptionPlans && customerData) {
            return subscriptionPlans.map(plan => {
                const monthlyId = plan.id + '-' + SubscriptionTypesEnum.MONTHLY;
                const yearlyId = plan.id + '-' + SubscriptionTypesEnum.YEARLY;

                const disabledMonthly = 
                    isSubscriptionSelectionDisabled(customerData.customer.subscription, plan, true);

                const disabledYearly = 
                    isSubscriptionSelectionDisabled(customerData.customer.subscription, plan, false);

                const selectedPlan = monthlyId === selected || yearlyId === selected;

                return <div
                        className={
                            selectedPlan
                            ? "boostplan__container boostplan__container__selected"
                            : "boostplan__container"
                        }
                        key={plan.id}
                    >
                    <h2>{plan.name}</h2>
                    {
                        plan.name === 'Basic'
                        ? <div className="boostplan__points_container">
                            <p>• Automated, secure cloud backup of unlimited number of your contacts</p>
                            <p>• Unlimited retrieval and restore of all stored contacts, to any subscriber device</p>
                            <p>• Export/backup contact list in any file-format</p>
                            <p>• No live concierge support</p>
                        </div>
                        : <div className="boostplan__points_container">
                            <p>• Unlimited contact storage & retrieval</p>
                            <p>• <b>{plan.numOfSupportCalls}</b> concierge support calls per year</p>
                            <p>• Define <b>{plan.numOfPriorityContactGroups}</b> contacts in a Priority Group for easy one-to-many messaging</p>
                            <p>• <b>{plan.numOfMinsPerSupportCall}</b> minutes per support call</p>
                            <p>• <b>{plan.numOfMinsPerCallToContact}</b> minutes per assisted call-to-contact</p>
                            <p>• <b>${plan.priceOfAdditionalConciergeSupportPerMin}/minute</b>, additional concierge support time</p>
                            <p>• <b>${plan.priceOfAdditionalCallToContactPerMin}/minute</b>, additional call-to-contact time</p>
                        </div>
                    }
                    <div
                        className={
                            disabledMonthly
                            ? "boostplan__radio_group boostplan__radio_group__disabled"
                            : "boostplan__radio_group"
                        }
                    >
                        <label
                            className="boostplan__radio_container"
                            htmlFor={monthlyId}
                        >
                            <p>${plan.monthlyPrice} / monthly</p>
                            <input
                                type="radio"
                                id={monthlyId}
                                value={monthlyId}
                                onChange={e => handleSelectPlan(e.target.value)}
                                checked={monthlyId === selected}
                                disabled={disabledMonthly}
                            />
                            <span className="boostplan__radio_checkmark"></span>
                        </label>
                    </div>
                    <div
                        className={
                            disabledYearly
                            ? "boostplan__radio_group boostplan__radio_group__disabled"
                            : "boostplan__radio_group"
                        }
                    >
                        <label
                            className="boostplan__radio_container"
                            htmlFor={yearlyId}
                        >
                            <p>${plan.yearlyPrice} / year</p>
                            {
                                plan.name !== 'Basic'
                                ? <p className="boostplan__discount">save 25%</p>
                                : null
                            }
                            <input
                                type="radio"
                                id={yearlyId}
                                value={yearlyId}
                                onChange={e => handleSelectPlan(e.target.value)}
                                checked={yearlyId === selected}
                                disabled={disabledYearly}
                            />
                            <span className="boostplan__radio_checkmark"></span>
                        </label>
                    </div>
                    {renderButtons(plan.id, selectedPlan)}
                </div>
            })
        }
    }

    useEffect(() => {
        getActiveSubscriptionPlans()
        .then(response => {
            loadStripe(config.stripeKey)
            .then((value) => {
                setStripe(value);
            });
        });
    }, []);

    return (
        <div className="boostplan__wrapper">
            {
                showCreditCard
                ? <Elements stripe={stripe}>
                    <CreditCardForm
                        creditCardAction={action}
                        targetSubscription={selected}
                        setShowCreditCard={setShowCreditCard}
                        setActiveAction={setActiveAction}
                    />
                </Elements>
                : renderSubscriptionPlans()
            }
            <Modal
                isOpen={showCreditCardModal}
                onRequestClose={() => setShowCreditCardModal(false)}
                className="creditcard_needed__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <CreditCardNeededModal
                    setShowCreditCard={setShowCreditCard}
                />
            </Modal>
        </div>
    )
}

export default BoostPlan
