import React, { useContext, useEffect, useState } from 'react'
import { Link, Route, Switch } from 'react-router-dom';
import { AuthenticationContext, SubscriptionPlanContext } from '../../contexts';
import { ISubscriptionData, IToggleSubscriptionActiveDTO } from '../../interfaces';
import SubscriptionPlanForm from './components/SubscriptionPlanForm/SubscriptionPlanForm';

const SubscriptionPlanPage = () => {
    const { subscriptionPlans, getSubscriptionPlans, toggleSubscriptionPlanActiveStatus } = useContext(SubscriptionPlanContext);
    const { token } = useContext(AuthenticationContext);

    const [selectedPlan, setSelectedPlan] = useState<ISubscriptionData | undefined>(undefined);

    const handleEditPlan = (plan: ISubscriptionData | undefined) => {
        setSelectedPlan(plan);
    }

    const handleToggleActivePlan = (plan: ISubscriptionData) => {
        if (subscriptionPlans) {
            const activeSubscriptions = subscriptionPlans.filter(plan => plan.active);

            if (activeSubscriptions.length === 5 && !plan.active) {
                alert('There are already 5 active subscriptions. You cannot activate one more without deactivating an existing one.');

                return;
            }
        }

        const dto = {
            subscriptionActiveState: !plan.active
        } as IToggleSubscriptionActiveDTO;

        toggleSubscriptionPlanActiveStatus(plan.id, dto)
        .then(response => {
            getSubscriptionPlans()
        })
        .catch(error => {
            console.log(error);
        });
    }

    const renderSubscriptionPlans = () => {
        return subscriptionPlans?.map(plan => {
            const className = plan.active
                ? 'subscription_plan__container'
                : 'subscription_plan__container subscription_plan__container__inactive';

            return <div
                    className={className}
                    key={plan.id}
                >
                <h2>{plan.name}</h2>
                {
                    plan.name === 'Basic'
                    ? <div className="subscription_plan__points_container">
                        <p>• Automated, secure cloud backup of unlimited number of your contacts</p>
                        <p>• Unlimited retrieval and restore of all stored contacts, to any subscriber device</p>
                        <p>• Export/backup contact list in any file-format</p>
                        <p>• No live concierge support</p>
                    </div>
                    : <div className="subscription_plan__points_container">
                        <p>• Unlimited contact storage & retrieval</p>
                        <p>• <b>{plan.numOfSupportCalls}</b> concierge support calls per year</p>
                        <p>• Define <b>{plan.numOfPriorityContactGroups}</b> contacts in a Priority Group for easy one-to-many messaging</p>
                        <p>• <b>{plan.numOfMinsPerSupportCall}</b> minutes per support call</p>
                        <p>• <b>{plan.numOfMinsPerCallToContact}</b> minutes per assisted call-to-contact</p>
                        <p>• <b>${plan.priceOfAdditionalConciergeSupportPerMin}/minute</b>, additional concierge support time</p>
                        <p>• <b>${plan.priceOfAdditionalCallToContactPerMin}/minute</b>, additional call-to-contact time</p>
                    </div>
                }
                <div
                    className="subscription_plan__price_container"
                >
                    <p>${plan.monthlyPrice} / monthly</p>
                </div>
                <div
                    className="subscription_plan__price_container"
                >
                    <p>${plan.yearlyPrice} / year</p>
                </div>
                <Link
                    to="/subscription-plan/form"
                    onClick={() => handleEditPlan(plan)}
                >
                    Edit
                </Link>
                <button
                    onClick={() => handleToggleActivePlan(plan)}
                >
                    {
                        plan.active
                        ? 'Make Inactive'
                        : 'Make Active'
                    }
                </button>
            </div>
        })
    }

    const renderContent = () => {
        return <div className="subscription_plan__wrapper">
            {renderSubscriptionPlans()}
            <div className="subscription_plan__new_plan_container">
                <h2>New Plan</h2>
                <Link
                    to="/subscription-plan/form"
                    onClick={() => handleEditPlan(undefined)}
                >
                    Add new
                </Link>
            </div>
        </div>
    }

    useEffect(() => {
        if (token) {
            getSubscriptionPlans();
        }
    }, [token]);

    return (
        <div className="subscription_plan__page_wrapper">
            <Switch>
                <Route path="/subscription-plan/form">
                    <SubscriptionPlanForm plan={selectedPlan} />
                </Route>
                <Route path="/subscription-plan">
                    {renderContent()}
                </Route>
            </Switch>
        </div>
    )
}

export default SubscriptionPlanPage;
