import React, { FC, useState } from 'react'
import { IContactData, IContactMessageData } from '../../../interfaces'
import { MessageTypesEnum } from '../../../util/enums'

interface ContactNumberSelectionModalProps {
    messageType?: MessageTypesEnum;
    contact: IContactData;
    handleSelect(data: IContactMessageData): void;
    handleClose(): void;
}

const ContactNumberSelectionModal: FC<ContactNumberSelectionModalProps> = (props) => {
    const { contact, messageType, handleSelect, handleClose } = props;

    const [selected, setSelected] = useState(-1);

    const headerText = messageType === MessageTypesEnum.SMS
        ? "Select phone number"
        : "Select email"

    const renderContactNumbers = () => {
        if (messageType === MessageTypesEnum.SMS) {
            return contact?.myUserAttributes.phones.map(item => {
                return (
                    <div className="customer_number_selection__number" key={item.id}>
                        <input
                            type="radio"
                            id={item.number}
                            value={item.id}
                            onChange={e => handleSelectNumber(e.target.value)}
                            checked={item.id === selected}
                        />
                        <label htmlFor={item.number}>{item.number}</label>
                    </div>
                )
            })
        }
        return contact?.myUserAttributes.emails.map(item => {
            return (
                <div className="customer_number_selection__number" key={item.id}>
                    <input
                        type="radio"
                        id={item.value}
                        value={item.id}
                        onChange={e => handleSelectNumber(e.target.value)}
                        checked={item.id === selected}
                    />
                    <label htmlFor={item.value}>{item.value}</label>
                </div>
            )
        })
    }

    const handleSelectNumber = (id: string) => {
        setSelected(parseInt(id));
    }

    const handleSubmit = () => {
        console.log(selected);

        const contactMessageData = {
            id: contact.id,
            firstName: contact.myUserAttributes.firstName,
            lastName: contact.myUserAttributes.lastName,
        } as IContactMessageData;

        if (messageType === MessageTypesEnum.SMS) {
            contactMessageData.phoneNumberId = selected;
        }
        else {
            contactMessageData.emailId = selected;
        }

        handleSelect(contactMessageData);
        handleClose();
    }

    return (
        <div className="customer_number_selection__wrapper">
            <p>{headerText}</p>
            <div className="customer_number_selection__number_container">
                {renderContactNumbers()}
            </div>
            <button
                onClick={handleSubmit}
            >
                Select
            </button>
        </div>
    )
}

export default ContactNumberSelectionModal
