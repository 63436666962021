import React, { createContext, FC, useContext } from 'react'
import { IAnalyticsData } from '../interfaces';
import { AuthenticationContext } from './AuthenticationContext';
import { AxiosContext } from './AxiosContext';

interface IAnalyticsContext {
    getAnalytics(startDate: number, endDate: number): Promise<IAnalyticsData[]>;
};

const AnalyticsContext = createContext({} as IAnalyticsContext);

interface AnalyticsContextProps {
    children: React.ReactNode;
}

const AnalyticsContextProvider: FC<AnalyticsContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);
    const { token } = useContext(AuthenticationContext);

    const getAnalytics = (startDate: number, endDate: number) => {
        return axiosInstance({
            method: 'GET',
            url: `concierges/metrics?startDate=${startDate}&endDate=${endDate}`
        }).then((response: any) => {
            console.log(response.data);
            return response.data;
        });
    }

    const providerValue = {
        getAnalytics
    }

    return (
        <AnalyticsContext.Provider value={providerValue}>
            {props.children}
        </AnalyticsContext.Provider>
    )
}

export { AnalyticsContext, AnalyticsContextProvider }
