import React, { createContext, FC, useContext, useState } from 'react'
import { IChargeMinuteDTO } from '../interfaces/AddMinutesInterface';
import { InCallActionsEnum } from '../util/enums';
import { CustomerContext } from './CustomerContext';
import { GlobalStateContext } from './GlobalStateContext';

interface ISupportCallTimerContext {
    supportCallTime: number;
    setSupportCallTime(state: number): void;
    isSupportCallOn: boolean;
    setIsSupportCallOn(state: boolean): void;
    supportCallTimer: ReturnType<typeof setInterval> | undefined;
    setSupportCallTimer (state: ReturnType<typeof setInterval> | undefined): void;
    startSupportCallTimer(startMinutes: number): void;
    stopSupportCallTimer(): void;
    resetSupportCallTimer(): void;
    supportCallMinutes: number;
    setSupportCallMinutes(state: number): void;
    isSupportCallMinutesOn: boolean;
    setIsSupportCallMinutesOn(state: boolean): void;
    supportCallMinutesTimer: ReturnType<typeof setInterval> | undefined;
    setSupportCallMinutesTimer (state: ReturnType<typeof setInterval> | undefined): void;
    startSupportCallMinutesTimer(customerId: number): void;
    stopSupportCallMinutesTimer(): void;
    resetSupportCallMinutesTimer(): void;
};

const SupportCallTimerContext = createContext({} as ISupportCallTimerContext);

interface SupportCallTimerContextProps {
    children: React.ReactNode;
};

const SupportCallTimerContextProvider: FC<SupportCallTimerContextProps> = (props) => {
    const [supportCallTime, setSupportCallTime] = useState(0);
    const [isSupportCallOn, setIsSupportCallOn] = useState(false);
    const [supportCallTimer, setSupportCallTimer] = useState<ReturnType<typeof setInterval>>();
    const [supportCallMinutes, setSupportCallMinutes] = useState(0);
    const [isSupportCallMinutesOn, setIsSupportCallMinutesOn] = useState(false);
    const [supportCallMinutesTimer, setSupportCallMinutesTimer] = useState<ReturnType<typeof setInterval>>();

    const { chargeCustomerByMinute } = useContext(CustomerContext);
    const { setShowCreditCardModal, setActiveAction } = useContext(GlobalStateContext);

    const startSupportCallTimer = (startMinutes: number) => {
        setIsSupportCallOn(true);
        setSupportCallTime(startMinutes * 60);

        let seconds = startMinutes * 60;

        setSupportCallTimer(
            setInterval(
                () => {
                    if (seconds > 0) {
                        seconds -= 1;
                    }

                    setSupportCallTime(seconds);
                },
                1000
            )
        )
    }

    const stopSupportCallTimer = () => {
        setIsSupportCallOn(false);

        if (supportCallTimer) {
            clearInterval(supportCallTimer);
        }
    }

    const resetSupportCallTimer = () => {
        setSupportCallTime(0);
        setIsSupportCallOn(false);
        setSupportCallTimer(undefined);
    }

    const startSupportCallMinutesTimer = (customerId: number) => {
        setIsSupportCallMinutesOn(true);
        setSupportCallMinutes(1);

        let minutes = 1;

        const dto = {
            customerId: customerId
        } as IChargeMinuteDTO;

        chargeCustomerByMinute(dto)
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.log(error);
            alert(error);

            setActiveAction(InCallActionsEnum.ADD_MINUTES);
            setShowCreditCardModal(true);
        });

        setSupportCallMinutesTimer(
            setInterval(
                () => {
                    minutes += 1;

                    setSupportCallMinutes(minutes);

                    chargeCustomerByMinute(dto)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                        alert(error);
            
                        setActiveAction(InCallActionsEnum.ADD_MINUTES);
                        setShowCreditCardModal(true);
                    });
                },
                60000
            )
        )
    }

    const stopSupportCallMinutesTimer = () => {
        setIsSupportCallMinutesOn(false);

        if (supportCallMinutesTimer) {
            clearInterval(supportCallMinutesTimer);
        }
    }

    const resetSupportCallMinutesTimer = () => {
        setSupportCallMinutes(0);
        setIsSupportCallMinutesOn(false);
        setSupportCallMinutesTimer(undefined);
    }

    const providerValue = {
        supportCallTime,
        setSupportCallTime,
        isSupportCallOn,
        setIsSupportCallOn,
        supportCallTimer,
        setSupportCallTimer,
        startSupportCallTimer,
        stopSupportCallTimer,
        resetSupportCallTimer,
        supportCallMinutes,
        setSupportCallMinutes,
        isSupportCallMinutesOn,
        setIsSupportCallMinutesOn,
        supportCallMinutesTimer,
        setSupportCallMinutesTimer,
        startSupportCallMinutesTimer,
        stopSupportCallMinutesTimer,
        resetSupportCallMinutesTimer
    }

    return (
        <SupportCallTimerContext.Provider value={providerValue}>
            {props.children}
        </SupportCallTimerContext.Provider>
    )
}

export { SupportCallTimerContext, SupportCallTimerContextProvider }
