import React, { FC, useContext } from 'react'
import { ConciergeContext } from '../../../../contexts';
import { IConciergeData } from '../../../../interfaces';

interface DeleteEmployeeModalProps {
    concierge: IConciergeData;
    refreshHandler(): void;
    cancelHandler(): void;
}

const DeleteEmployeeModal: FC<DeleteEmployeeModalProps> = (props) => {
    const { concierge, refreshHandler, cancelHandler } = props;

    const { deleteConcierge } = useContext(ConciergeContext);

    const deleteEmployee = () => {
        deleteConcierge(concierge.id).then((response) => {
            refreshHandler();
            cancelHandler();
        });
    }

    return (
        <div className="delete_employee__wrapper">
            <p>Delete profile</p>
            <span>Are you sure you wish to delete {concierge.conciergeUser.firstName + " " + concierge.conciergeUser.lastName}'s profile?</span>
            <button className="delete_employee__delete" onClick={deleteEmployee}>Delete</button>
            <button className="delete_employee__cancel" onClick={cancelHandler}>Cancel</button>
        </div>
    )
}

export default DeleteEmployeeModal
