import React, { FC, useContext, useState } from 'react'
import { Dashboard, MainHeader, IncomingCallModal, CustomerAuthModal } from '../../components/index';
import Modal from 'react-modal';
import { GlobalStateContext } from '../../contexts';
import InCallModal from "../InCallModal/InCallModal";
import { TicketSummaryModal, LoaderModal, MainModal } from '../../components/Modals';

interface AppTemplateProps {
    children: React.ReactNode;
}

const AppTemplate: FC<AppTemplateProps> = (props) => {
    const { children } = props;

    const {
        showModal,
        showIncomingCallModal,
        showInCallModal,
        showCustomerAuthModal,
        showTicketSummaryModal,
        showLoader
    } = useContext(GlobalStateContext);

    return (
        <div className="page_wrapper">
            <Dashboard/>
            <div className="page_wrapper__content">
                <MainHeader />
                <div className="page_wrapper__content--wrapper">{children}</div>
            </div>
            {/* <Modal
                isOpen={showIncomingCallModal}
                className="incoming_call__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <IncomingCallModal />
            </Modal> */}
            {
                showModal && (
                    <MainModal />
                )
            }
            {/* <Modal
                isOpen={showInCallModal}
                className="in_call__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <InCallModal />
            </Modal>
            <Modal
                isOpen={showCustomerAuthModal}
                className="customer_auth__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <CustomerAuthModal />
            </Modal>
            <Modal
                isOpen={showTicketSummaryModal}
                className="ticket_summary__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <div className="modal__scroll_component">
                    <TicketSummaryModal />
                </div>
            </Modal> */}
            <Modal
                isOpen={showLoader}
                className="loader__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <LoaderModal />
            </Modal>
        </div>
    )
}

export default AppTemplate
