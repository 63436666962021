import React, { createContext, FC, useContext } from 'react'
import { IAddMinutesDTO, IChargeMinuteDTO, ICustomerData, ICustomerSubscriptionData, ICustomerWithTicketData } from '../interfaces';
import { IEditCustomerSubscriptionDTO } from '../interfaces/CustomerInterface';
import { AxiosContext } from './AxiosContext';

interface ICustomerContext {
    getCustomerById(id: number): Promise<ICustomerData>;
    getCustomerByPhoneNumber(phoneNumber: string): Promise<ICustomerWithTicketData>;
    addMinutesToCustomer(data: IAddMinutesDTO): Promise<void>;
    chargeCustomerByMinute(data: IChargeMinuteDTO): Promise<void>;
    editCustomerSubscription(data: IEditCustomerSubscriptionDTO): Promise<ICustomerSubscriptionData>;
};

const CustomerContext = createContext({} as ICustomerContext);

interface CustomerContextProps {
    children: React.ReactNode;
}

const CustomerContextProvider: FC<CustomerContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const getCustomerByPhoneNumber = (phoneNumber: string) => {
        return axiosInstance({
            method: 'GET',
            url: `concierges/customer/?phone=${phoneNumber}`
        }).then((response: any) => {
            return response.data;
        });
    }

    const getCustomerById = (id: number) => {
        return axiosInstance({
            method: 'GET',
            url: `concierges/customer/${id}`
        }).then((response: any) => {
            return response.data;
        });
    }

    const addMinutesToCustomer = (data: IAddMinutesDTO) => {
        return axiosInstance({
            method: 'PUT',
            url: 'concierges/customerSubscription/addMinutes',
            data
        }).then((response: any) => {
            console.log(response.data);
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
    }

    const chargeCustomerByMinute = (data: IChargeMinuteDTO) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierge/customer/chargeByMinute',
            data
        }).then((response: any) => {
            console.log(response.data);
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
    }

    const editCustomerSubscription = (data: IEditCustomerSubscriptionDTO) => {
        return axiosInstance({
            method: 'PUT',
            url: 'concierge/subscription/editCustomerSubscription',
            data
        }).then((response: any) => {
            console.log(response.data);
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
    }

    const providerValue = {
        getCustomerByPhoneNumber,
        getCustomerById,
        addMinutesToCustomer,
        chargeCustomerByMinute,
        editCustomerSubscription
    }

    return (
        <CustomerContext.Provider value={providerValue}>
            {props.children}
        </CustomerContext.Provider>
    )
}

export { CustomerContext, CustomerContextProvider }
