import React, { useContext, useEffect, useState } from 'react'
import { Autocomplete } from '../..'
import { ContactContext, SecurityQuestionContext } from '../../../contexts';
import useDebounce from '../../../hooks/useDebounce';
import { IContactData, IContactMessageData, IEmailData, IPhoneData, IToggleContactDeletedDTO } from '../../../interfaces';
import { AutocompleteTypesEnum } from '../../../util/enums'
import Modal from "react-modal";
import ContactModal from '../../Modals/ContactModal/ContactModal';
import { getFullName } from '../../../util/functions';

const ContactListing = () => {
    const [regularContacts, setRegularContacts] = useState<IContactData[]>([]);
    const [priorityContacts, setPriorityContacts] = useState<IContactData[]>([]);
    const [allContacts, setAllContacts] = useState<IContactData[]>([]);
    const [search, setSearch] = useState('');
    const [selectedContact, setSelectedContact] = useState<IContactData | undefined>(undefined);
    const [showContactModal, setShowContactModal] = useState(false);

    const { searchContactsByValue, toggleContactIsDeleted } = useContext(ContactContext);
    const { customerData } = useContext(SecurityQuestionContext);

    const renderRegularContacts = () => {
        return regularContacts.map(contact => {
            return <div
                className="contact_listing__contact_list__contact"
                key={contact.id}
                onClick={() => handleContactClicked(contact)}
            >
                <img src="/assets/user_profile_letters.svg" alt="image" />
                <div className="contact_listing__contact_list__name_container">
                    <p>
                        {/* {
                            contact.myUserAttributes.lastName
                            ? contact.myUserAttributes.firstName + " " + contact.myUserAttributes.lastName
                            : contact.myUserAttributes.firstName
                        } */}
                        {getFullName(contact)}
                    </p>
                    {
                        contact.isDeleted && (
                            <div
                                className="contact_listing__contact_list__restore_container"
                                onClick={() => handleRestoreContact(contact.id)}
                            >
                                <span>Restore</span>
                            </div>
                        )
                    }
                </div>
            </div>
        })
    }

    const renderPriorityContacts = () => {
        return priorityContacts.map(contact => {
            return <div
                className="contact_listing__contact_list__contact"
                key={contact.id}
                onClick={() => handleContactClicked(contact)}
            >
                <img src="/assets/user_profile_letters.svg" alt="image" />
                <div className="contact_listing__contact_list__name_container">
                    <p>
                        {/* {
                            contact.myUserAttributes.lastName
                            ? contact.myUserAttributes.firstName + " " + contact.myUserAttributes.lastName
                            : contact.myUserAttributes.firstName
                        } */}
                        {getFullName(contact)}
                    </p>
                    {
                        contact.isDeleted && (
                            <div
                                className="contact_listing__contact_list__restore_container"
                                onClick={() => handleRestoreContact(contact.id)}
                            >
                                <span>Restore</span>
                            </div>
                        )
                    }
                </div>
            </div>
        })
    }
    
    const emailsContainSearchString = (emails: IEmailData[]) => {
        if (!emails || emails.length === 0) {
            return false;
        }

        const emailValues = emails.map(email => email.value.toLowerCase());
        
        if (emailValues.some(email => email.includes(search))) {
            return true;
        }
        
        return false;
    }
    
    const phonesContainSearchString = (phones: IPhoneData[]) => {
        if (!phones || phones.length === 0) {
            return false;
        }
        
        const phoneNumbers = phones.map(phone => phone.number);
        
        if (phoneNumbers.some(phoneNumber => phoneNumber.includes(search))) {
            return true;
        }
        
        return false;
    }

    const divideContacts = (contacts: IContactData[]) => {
        const regular: IContactData[] = [];
        const priority: IContactData[] = [];

        contacts.forEach(contact => {
            if (contact.priorityListId) {
                priority.push(contact);
            }
            else {
                regular.push(contact);
            }
        });

        setRegularContacts(sortContactsAlphabetically(regular));
        setPriorityContacts(sortContactsAlphabetically(priority));
    }

    const sortContactsAlphabetically = (contactsFromCloud: IContactData[]) => {
        return contactsFromCloud.sort((a, b) => {
            const fullNameA = getFullName(a).toLowerCase();
            const fullNameB = getFullName(b).toLowerCase();
        
            if (fullNameA < fullNameB) {
                return -1;
            }
            if (fullNameA > fullNameB) {
                return 1;
            }
        
            return 0;
        });
    };

    const handleContactClicked = (contact: IContactData) => {
        setSelectedContact(contact);
        setShowContactModal(true);
    }

    const handleRestoreContact = (contactId: number) => {
        if (customerData) {
            const dto = {
                customerId: customerData.customer.id,
                contactId: contactId
            } as IToggleContactDeletedDTO;

            toggleContactIsDeleted(dto)
            .then(() => {
                const foundContactIndex = allContacts.map(contact => contact.id).indexOf(contactId);
    
                if (foundContactIndex !== -1) {
                    const updatedContacts: IContactData[] = JSON.parse(JSON.stringify(allContacts));
    
                    updatedContacts[foundContactIndex].isDeleted = false;
    
                    setAllContacts(updatedContacts);

                    divideContacts(allContacts);
    
                    return;
                }
            });
        }
    }
    
    useEffect(() => {
        if (search) {
            const filteredContacts = allContacts.filter(contact => {
                if (getFullName(contact).toLowerCase().includes(search)
                || emailsContainSearchString(contact.myUserAttributes.emails)
                || phonesContainSearchString(contact.myUserAttributes.phones)) {
                    return contact;
                }
            });

            divideContacts(filteredContacts);

            return;
        }

        divideContacts(allContacts);
    }, [search]);

    useEffect(() => {
        if (customerData) {
            searchContactsByValue(customerData.customer.id)
            .then(response => {
                setAllContacts(response);

                divideContacts(response);
            });
        }
    }, []);

    return (
        <div className="contact_listing__wrapper">
            <div className="contact_listing__search_container">
                <div className="contact_listing__search">
                    <img src="/assets/search.svg" alt="" />
                    <input
                        type="text"
                        placeholder="Search Contact by Name / E-mail / Number"
                        value={search}
                        onChange={e => setSearch(e.target.value.toLowerCase())}
                    />
                </div>
            </div>
            <div className="contact_listing__contact_list_containers">
                <div className="contact_listing__contact_list_container">
                    <h2>Regular Contacts</h2>
                    <div className="contact_listing__contact_list__contact__container">
                        {renderRegularContacts()}
                    </div>
                </div>
                <div className="contact_listing__contact_list_container contact_listing__contact_list_container__priority">
                    <h2>Priority Contacts</h2>
                    <div className="contact_listing__contact_list__contact__container">
                        {renderPriorityContacts()}
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showContactModal}
                className="contact_modal__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <ContactModal contact={selectedContact} setShowModal={setShowContactModal} />
            </Modal>
        </div>
    )
}

export default ContactListing
