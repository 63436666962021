import React from 'react'

const LoggedOutPage = () => {
    return (
        <div className="logged_out__wrapper">
            <div className="logged_out__logo">
                <img src="/assets/big_logo.svg" alt="725contact" />
            </div>
            <div className="logged_out__text">
                <p>You have logged out of your account.</p>
                <p>Please refresh the page so that you can log in!</p>
            </div>
        </div>
    )
}

export default LoggedOutPage
