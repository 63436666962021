import React, { createContext, FC, useContext, useState } from 'react'

interface IGlobalTimerContext {
    totalCallTime: number;
    setTotalCallTime(state: number): void;
    isTotalCallOn: boolean;
    setIsTotalCallOn(state: boolean): void;
    totalCallTimer: ReturnType<typeof setInterval> | undefined;
    setTotalCallTimer (state: ReturnType<typeof setInterval> | undefined): void;
    startTotalCallTimer(): void;
    stopTotalCallTimer(): void;
    resetTotalCallTimer(): void;
};

const GlobalTimerContext = createContext({} as IGlobalTimerContext);

interface GlobalTimerContextProps {
    children: React.ReactNode;
};

const GlobalTimerContextProvider: FC<GlobalTimerContextProps> = (props) => {
    const [totalCallTime, setTotalCallTime] = useState(0);
    const [isTotalCallOn, setIsTotalCallOn] = useState(false);
    const [totalCallTimer, setTotalCallTimer] = useState<ReturnType<typeof setInterval>>();

    const startTotalCallTimer = () => {
        setIsTotalCallOn(true);
        setTotalCallTime(0);

        let seconds = 0;

        setTotalCallTimer(
            setInterval(
                () => {
                    seconds += 1;

                    setTotalCallTime(seconds);
                },
                1000
            )
        )
    }

    const stopTotalCallTimer = () => {
        setIsTotalCallOn(false);

        if (totalCallTimer) {
            clearInterval(totalCallTimer);
        }
    }

    const resetTotalCallTimer = () => {
        setTotalCallTime(0);
        setIsTotalCallOn(false);
        setTotalCallTimer(undefined);
    }

    const providerValue = {
        totalCallTime,
        setTotalCallTime,
        isTotalCallOn,
        setIsTotalCallOn,
        totalCallTimer,
        setTotalCallTimer,
        startTotalCallTimer,
        stopTotalCallTimer,
        resetTotalCallTimer
    }

    return (
        <GlobalTimerContext.Provider value={providerValue}>
            {props.children}
        </GlobalTimerContext.Provider>
    )
}

export { GlobalTimerContext, GlobalTimerContextProvider }
