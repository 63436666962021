import React, { FC, useContext, useState } from 'react'
import { ConciergeContext } from '../../../../contexts';
import { IConciergeData, IUpdateConciergeData } from '../../../../interfaces'

interface EditEmployeeModalProps {
    concierge: IConciergeData;
    refreshHandler(): void;
    cancelHandler(): void;
}

const EditEmployeeModal: FC<EditEmployeeModalProps> = (props) => {
    const { concierge, refreshHandler, cancelHandler } = props;

    const { updateConcierge } = useContext(ConciergeContext);

    const [updateData, setUpdateData] = useState({
        userId: concierge.userId,
        poolId: concierge.poolId,
        email: concierge.email,
        phoneNumber: concierge.phoneNumber,
        role: concierge.role,
        status: concierge.status,
        firstName: concierge.conciergeUser.firstName,
        lastName: concierge.conciergeUser.lastName,
        thumbnailUrl: concierge.conciergeUser.thumbnailUrl ? concierge.conciergeUser.thumbnailUrl : '',
        hasThumbnail: concierge.conciergeUser.hasThumbnail,
    } as IUpdateConciergeData);

    const save = () => {
        updateConcierge(concierge.id, updateData)
        .then((response) => {
            refreshHandler();
            cancelHandler();
        });
    }

    const inputHandler: Function = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateData({
            ...updateData,
            [fieldName]: fieldName === 'role' ? parseInt(event.target.value) : event.target.value,
        });
    };

    return (
        <div className="edit_employee__wrapper">
            <p>Edit profile</p>
            <span>First name</span>
            <input
                type="text"
                name="firstName"
                id="firstName"
                value={updateData.firstName}
                onChange={e => inputHandler('firstName', e)}
            />
            <span>Last name</span>
            <input
                type="text"
                name="lastName"
                id="LastName"
                value={updateData.lastName}
                onChange={e => inputHandler('lastName', e)}
            />
            <span>Email</span>
            <input
                type="text"
                name="email"
                id="email"
                value={updateData.email}
                onChange={e => inputHandler('email', e)}
            />
            <span>Assigned role</span>
            <select
                name="role"
                id="role"
                className="edit_employee__role"
                value={updateData.role}
                onChange={e => inputHandler('role', e)}
            >
                <option value={0}>Concierge</option>
                <option value={1}>Manager</option>
                <option value={2}>Admin</option>
            </select>
            <button className="edit_employee__save" onClick={save}>Save</button>
            <button className="edit_employee__cancel" onClick={cancelHandler}>Cancel</button>
        </div>
    )
}

export default EditEmployeeModal
