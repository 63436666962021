import React, { FC, useContext, useState } from 'react'
import { ConciergeBackendRoleEnum, ConciergeBackendStatusEnum, ConciergeRoleEnum, ConciergeStatusEnum } from '../../../../util/enums';
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import Modal from 'react-modal';
import EditEmployeeModal from "../EditEmployee/EditEmployeeModal";
import DeleteEmployeeModal from "../DeleteEmployee/DeleteEmployeeModal";
import { IConciergeData } from '../../../../interfaces';
import { ConciergeContext } from '../../../../contexts';

interface EmployeeCardProps {
    concierge: IConciergeData;
    refreshHandler(): void;
}

const EmployeeCard: FC<EmployeeCardProps> = (props) => {
    const { concierge, refreshHandler } = props;

    const { conciergeData } = useContext(ConciergeContext);

    const [showMenu, setShowMenu] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const getConciergeRoleFromBackendRole = (backendRole: ConciergeBackendRoleEnum) => {
        if (backendRole === ConciergeBackendRoleEnum.Concierge) {
            return ConciergeRoleEnum.CONCIERGE;
        }

        if (backendRole === ConciergeBackendRoleEnum.Manager) {
            return ConciergeRoleEnum.MANAGER;
        }

        return ConciergeRoleEnum.ADMIN;
    }

    const getConciergeStatusFromBackendStatus = (backendStatus: ConciergeBackendStatusEnum) => {
        if (backendStatus === ConciergeBackendStatusEnum.Routable) {
            return ConciergeStatusEnum.AVAILABLE;
        }

        if (backendStatus === ConciergeBackendStatusEnum.NotRoutable) {
            return ConciergeStatusEnum.OCCUPIED;
        }

        return ConciergeStatusEnum.OFFLINE;
    }

    const toggleShow = () => {
        setShowMenu(!showMenu);
    }

    const hide = (e: any) => {
        setShowMenu(false);
    }

    return (
        <div className={`user_card__container__${getConciergeStatusFromBackendStatus(concierge.status)}`}>
            <div className="user_card__picture">
                {
                    concierge.conciergeUser.thumbnailUrl !== "" && concierge.conciergeUser.thumbnailUrl !== null
                    ? (<img src={concierge.conciergeUser.thumbnailUrl} alt="image" />)
                    : (<img src="/assets/user_profile_letters.svg" alt="" />)
                }
            </div>
            <div className="user_card__details">
                <p className="user_card__name">{concierge.conciergeUser.firstName + " " + concierge.conciergeUser.lastName}</p>
                <p className="user_card__email">{concierge.email}</p>
            </div>
            <div className={`user_card__role ${getConciergeRoleFromBackendRole(concierge.role)}_role`}>
                <p>{getConciergeRoleFromBackendRole(concierge.role)}</p>
            </div>
            {
                conciergeData?.role !== ConciergeBackendRoleEnum.Concierge
                ? <div
                    className="user_card__menu"
                    tabIndex={0}
                    onClick={toggleShow}
                    onBlur={hide}
                >
                    <img src="/assets/dots_menu.svg" alt="" />
                    {
                        showMenu && ( 
                            <DropdownMenu
                                showEditModalHandler={setShowEditModal} 
                                showDeleteModalHandler={setShowDeleteModal}
                            /> 
                        )
                    }
                </div>
                : null
            }
            <Modal
                isOpen={showEditModal}
                onRequestClose={() => setShowEditModal(false)}
                className="edit_employee__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <EditEmployeeModal
                    concierge={concierge}
                    cancelHandler={() => setShowEditModal(false)}
                    refreshHandler={refreshHandler}
                />
            </Modal>
            <Modal
                isOpen={showDeleteModal}
                onRequestClose={() => setShowDeleteModal(false)}
                className="delete_employee__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <DeleteEmployeeModal
                    concierge={concierge}
                    cancelHandler={() => setShowDeleteModal(false)}
                    refreshHandler={refreshHandler}
                />
            </Modal>
        </div>
    )
}

export default EmployeeCard
