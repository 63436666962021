import React, { createContext, FC, useContext, useState } from 'react'
import { IAuthenticated, IAuthenticateSecurityQuestion, ICustomerWithTicketData, ISecurityQuestionData } from '../interfaces';
import { AuthenticationContext } from './AuthenticationContext';
import { AxiosContext } from './AxiosContext';

interface ISecurityQuestionContext {
    securityQuestionData: ISecurityQuestionData | undefined;
    setSecurityQuestionData(data: ISecurityQuestionData | undefined): void;
    customerData: ICustomerWithTicketData | undefined;
    setCustomerData(data: ICustomerWithTicketData): void;
    isCustomerEnrolled: boolean | undefined;
    setIsCustomerEnrolled(data: boolean | undefined): void;
    getSecurityQuestion(email: string): Promise<void>;
    authenticateCustomer(data: IAuthenticateSecurityQuestion): Promise<ICustomerWithTicketData>;
};

const SecurityQuestionContext = createContext({} as ISecurityQuestionContext);

interface SecurityQuestionContextProps {
    children: React.ReactNode;
}

const SecurityQuestionContextProvider: FC<SecurityQuestionContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const [securityQuestionData, setSecurityQuestionData] = useState<ISecurityQuestionData | undefined>(undefined);
    const [customerData, setCustomerData] = useState<ICustomerWithTicketData | undefined>(undefined);
    const [isCustomerEnrolled, setIsCustomerEnrolled] = useState<boolean | undefined>(false);

    const getSecurityQuestion = (email: string) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierges/security-question',
            data: {
                email: email
            }
        }).then((response: any) => {
            if (response.data.customerId) {
                setSecurityQuestionData(response.data);
                
                return response.data;
            }

            throw new Error(response.data);
        }).catch(error => {
            alert(error.message);
        });
    }

    const authenticateCustomer = (data: IAuthenticateSecurityQuestion) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierges/security-question/auth',
            data
        }).then((response: any) => {
            if (response.data.customer.id) {
                setCustomerData(response.data);
                setSecurityQuestionData(undefined);
                
                return response.data;
            }

            throw new Error('Invalid passphrase.');
        }).catch(error => {
            alert(error.message);
        });
    }

    const providerValue = {
        securityQuestionData,
        setSecurityQuestionData,
        customerData,
        setCustomerData,
        isCustomerEnrolled,
        setIsCustomerEnrolled,
        getSecurityQuestion,
        authenticateCustomer,
    }

    return (
        <SecurityQuestionContext.Provider value={providerValue}>
            {props.children}
        </SecurityQuestionContext.Provider>
    )
}

export { SecurityQuestionContext, SecurityQuestionContextProvider }
