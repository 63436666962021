import React, { FC } from 'react'
import { IConciergeData, IContactMessageData } from '../../interfaces'
import { MessageTypesEnum } from '../../util/enums';
import { doesCorrespondingMessageTypeAndDataExist, getFullNameMessageContact } from '../../util/functions';

interface SelectedContactProps {
    data: IContactMessageData | IConciergeData;
    messageType?: MessageTypesEnum;
    handleRemove(value: IContactMessageData | IConciergeData): void;
    isConcierge?: boolean;
}

const SelectedContact: FC<SelectedContactProps> = (props) => {
    const { data, handleRemove, messageType, isConcierge } = props;

    let backgroundClass = 'selected_contact__gray';

    if (!isConcierge && !doesCorrespondingMessageTypeAndDataExist(messageType, data as IContactMessageData)) {
        backgroundClass = 'selected_contact__red';
    }

    const renderName = () => {
        if (isConcierge) {
            const concierge = data as IConciergeData;

            return concierge.conciergeUser.firstName + ' ' + concierge.conciergeUser.lastName;
        }

        const contact = data as IContactMessageData;

        // return contact.firstName + ' ' + contact.lastName;
        return getFullNameMessageContact(contact);
    }

    return (
        <div className={`selected_contact__wrapper ${backgroundClass}`}>
            <img className="selected_contact__picture" src="/assets/user_profile_letters.svg" alt="img" />
            {/* <img className="selected_contact__picture" src={contact.thumbnailUrl} alt="img" /> */}
            <p>{renderName()}</p>
            <img className="selected_contact__close" src="/assets/x.svg" alt="X" onClick={() => handleRemove(data)} />
        </div>
    )
}

export default SelectedContact
