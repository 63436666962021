import React, { FC } from 'react'
import { AddressInfoLabel, InfoLabel } from '../..';
import { IContactData } from '../../../interfaces'
import { getFullName } from '../../../util/functions';

interface ContactModalProps {
    contact: IContactData | undefined;
    setShowModal(state: boolean): void;
}

const ContactModal: FC<ContactModalProps> = (props) => {
    const { contact, setShowModal } = props;

    const contactAddress = contact?.myUserAttributes.myAddresses
        ? contact.myUserAttributes.myAddresses[0]
        : { street: null, state: null, city: null, zip: null, country: null };

    const street = contactAddress?.street ? contactAddress.street : null;
    const state = contactAddress?.state ? contactAddress.state : null;
    const city = contactAddress?.city ? contactAddress.city : null;
    const zip = contactAddress?.zip ? contactAddress.zip : null;
    const country = contactAddress?.country ? contactAddress.country : null;
    const address = { street, city, state, zip, country };

    const renderEmails = () => {
        return contact?.myUserAttributes.emails.map((email, index) => (
            <InfoLabel
                index={index}
                key={index}
                value={email.value}
                label="Email"
            />
        ))
    }

    const renderPhones = () => {
        return contact?.myUserAttributes.phones.map((phone, index) => (
            <InfoLabel
                index={index}
                key={index}
                value={phone.number}
                label="Phone"
            />
        ))
    }

    // const getFullName = () => {
    //     let fullName = '';

    //     if (contact?.myUserAttributes.firstName) {
    //         fullName += contact.myUserAttributes.firstName;
    //     }

    //     if (contact?.myUserAttributes.lastName) {
    //         fullName += ' ' + contact.myUserAttributes.lastName;
    //     }

    //     if (contact?.prefix) {
    //         fullName += ' ' + contact.prefix;
    //     }

    //     if (contact?.phoneticMiddleName) {
    //         fullName += ' ' + contact.phoneticMiddleName;
    //     }

    //     if (contact?.sufix) {
    //         fullName += ' ' + contact.sufix;
    //     }

    //     return fullName;
    // }

    return (
        <div className="contact_modal__wrapper">
            <div className="card_modal__header">
                <div className="card_modal__header_divider"></div>
                <div className="card_modal__profile__picture">
                    {/* <p className="card_modal__profile__picture__initials">{initials}</p> */}
                    {
                        contact?.myUserAttributes.thumbnailUrl !== "" && contact?.myUserAttributes.thumbnailUrl !== null
                        ? <img src={contact?.myUserAttributes.thumbnailUrl} alt="image" />
                        : <img src="/assets/user_profile_letters.svg" alt="" />
                    }
                </div>
                <div className="contact_modal__close">
                    <img
                        src="/assets/x.svg"
                        alt="x"
                        onClick={() => setShowModal(false)}
                    />
                </div>
            </div>
            <p className="contact_modal__fullname_text">{getFullName(contact)}</p>
            <div className="contact_modal__innner_wrapper">
                {renderPhones()}
                <div className="contact_modal__divider"></div>
                {renderEmails()}
                <AddressInfoLabel value={address} />
            </div>
        </div>
    )
}

export default ContactModal
