import { ConciergeBackendStatusEnum } from "../enums";

const getAgentStateFromConciergeStatus = (status: ConciergeBackendStatusEnum, agent: connect.Agent | undefined) => {
    if (agent) {
        const agentStates = agent.getAgentStates();

        switch (status) {
            case ConciergeBackendStatusEnum.NotRoutable:
                return agentStates.find(state => state.name === 'Occupied')
            case ConciergeBackendStatusEnum.Routable:
                return agentStates.find(state => state.name === 'Available')
            default:
                return agentStates.find(state => state.name === 'Offline')
        }
    }
}

export { getAgentStateFromConciergeStatus }