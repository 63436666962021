import React, { FC, useContext, useState } from 'react'
import { InCallActionsEnum, ModalTypesEnum } from '../../util/enums'
import {
    BoostPlan,
    Call,
    ContactListing,
    Message,
    VoiceMessage,
} from "../../components/index";
import { AmazonConnectContext, GlobalStateContext, GlobalTimerContext, SecurityQuestionContext, SupportCallTimerContext } from '../../contexts';
import { AddMinutes, ReassignTicket } from '../../components/Actions';
import Timer from '../../components/Timer/Timer';
import Modal from 'react-modal';
import { NoteModal } from '../../components/Modals';

interface InCallModalProps {
    setIsConciergeNote(state: boolean): void;
    setIsTotalTimeOn(state: boolean): void;
    setIsSupportCallTimeOn(state: boolean): void;
    setIsChargedMinutesTimeOn(state: boolean): void;
    setIsContactCallTimeOn(state: boolean): void;
    totalCallTime: number;
    supportCallTime: number;
    chargedMinutesTime: number;
    contactCallTime: number;
    isSupportCallTimeOn: boolean;
}

const InCallModal: FC<InCallModalProps> = (props) => {
    const { 
        setIsConciergeNote,
        setIsSupportCallTimeOn,
        setIsChargedMinutesTimeOn,
        setIsTotalTimeOn,
        setIsContactCallTimeOn,
        supportCallTime,
        totalCallTime,
        isSupportCallTimeOn,
        chargedMinutesTime,
        contactCallTime
    } = props;

    const [showBoostPlanMenu, setShowBoostPlanMenu] = useState(false);
    const [showReassignTicket, setShowReassignTicket] = useState(false);

    const { agent, connection, contact } = useContext(AmazonConnectContext);
    const { activeAction, setActiveAction, setActiveModal } = useContext(GlobalStateContext);
    const { customerData } = useContext(SecurityQuestionContext);

    const handleActiveAction = (action: InCallActionsEnum) => {
        if (activeAction === action) {
            setActiveAction(undefined);

            return;
        }

        setActiveAction(action);
    }

    const renderActionButtons = () => {
        return Object.keys(InCallActionsEnum).map((key) => {
            const actionValue = InCallActionsEnum[key as keyof typeof InCallActionsEnum];

            let fileName = actionValue.toString();
    
            if (activeAction === actionValue) {
                fileName += '_active';
            }

            if ((actionValue === InCallActionsEnum.BOOST_PLAN &&
                activeAction !== InCallActionsEnum.ADD_MINUTES) ||
                (activeAction === InCallActionsEnum.ADD_MINUTES &&
                actionValue === InCallActionsEnum.ADD_MINUTES)) 
            {
                return <div
                    className="in_call__boost_plan__dropdown__container"
                    key={key}
                    tabIndex={0}
                    onClick={() => setShowBoostPlanMenu(!showBoostPlanMenu)}
                    onBlur={() => setShowBoostPlanMenu(false)}
                >
                    {
                        showBoostPlanMenu
                        ? <div className="in_call__boost_plan__dropdown">
                            <div className="in_call__boost_plan__dropdown_item">
                                <img
                                    src={`/assets/incall_buttons/boost_plan_active.svg`}
                                    onClick={() => handleActiveAction(InCallActionsEnum.BOOST_PLAN)}
                                    alt={fileName}
                                />
                            </div>
                            <div className="in_call__boost_plan__dropdown_divider"></div>
                            <div className="in_call__boost_plan__dropdown_item">
                                <img
                                    src='/assets/incall_buttons/add_minutes_active.svg'
                                    onClick={() => handleActiveAction(InCallActionsEnum.ADD_MINUTES)}
                                    alt='add_minutes.svg'
                                />
                            </div>
                        </div>
                        : <img
                            src={`/assets/incall_buttons/${fileName}.svg`}
                            alt={fileName}
                        />
                    }
                </div>
            }
            
            if (actionValue !== InCallActionsEnum.ADD_MINUTES &&
                actionValue !== InCallActionsEnum.BOOST_PLAN) {
                return (
                    <img
                        key={key}
                        src={`/assets/incall_buttons/${fileName}.svg`}
                        onClick={() => handleActiveAction(actionValue)}
                        alt={fileName}
                    />
                )
            }
        });
    }

    const actionToDisplay = () => {
        switch (activeAction) {
            case InCallActionsEnum.BOOST_PLAN:
                return <BoostPlan setActiveAction={setActiveAction}/>
            case InCallActionsEnum.CALL:
                return <Call setIsContactCallTimeOn={setIsContactCallTimeOn} contactCallTime={contactCallTime} />
            case InCallActionsEnum.CONTACT_LISTING:
                return <ContactListing />
            case InCallActionsEnum.MESSAGE:
                return <Message />
            case InCallActionsEnum.VOICE_MESSAGE:
                return <VoiceMessage />
            case InCallActionsEnum.ADD_MINUTES:
                return <AddMinutes />
        }
    }

    const handleEndCall = () => {
        if (connection && agent && contact) {
            connection.destroy({
                success: () => {
                    contact.clear({
                        success: () => {
                            setActiveModal(ModalTypesEnum.TICKET_SUMMARY);
                        },
                    });
                },
            });

            return;
        }

        setActiveModal(ModalTypesEnum.TICKET_SUMMARY);
    }

    const handleShowConciergeNote = () => {
        setIsConciergeNote(true);
        setActiveModal(ModalTypesEnum.NOTE);
    }

    const handleShowCustomerNote = () => {
        setIsConciergeNote(false);
        setActiveModal(ModalTypesEnum.NOTE);
    }

    const renderInCall = () => {
        return <div className="in_call__wrapper">
            <div className="in_call__top">
                <img src="/assets/orange_route_call.svg" alt="" onClick={() => setShowReassignTicket(true)} />
                <div className="in_call__note_buttons">
                    <p onClick={handleShowConciergeNote}>My note</p>
                    <p onClick={handleShowCustomerNote}>Add Customer's note</p>
                </div>
                <div className="in_call__customer_info">
                    <p>{customerData?.customer.userAttributes.firstName + ' ' + customerData?.customer.userAttributes.lastName}</p>
                    {/* <p>Michael Garcia</p> */}
                    <span>in call</span>
                    <Timer time={totalCallTime} />
                    {
                        isSupportCallTimeOn
                        ? <Timer time={supportCallTime} />
                        : <span>Minutes: {chargedMinutesTime}</span>
                    }
                    {/* <Timer time={supportCallTime} /> */}
                    {/* <span>Minutes: {supportCallTime}</span> */}
                </div>
                <div className="in_call__divider">
                    <span>Customer's remaining support calls: {customerData?.customer.subscription?.remainingNumOfSupportCalls}</span>
                </div>
                <img src="/assets/red_hangup_call.svg" alt="" onClick={handleEndCall} />
            </div>
            <div className="in_call__actions">
                {renderActionButtons()}
            </div>
            <div className="in_call__action_content">
                {actionToDisplay()}
            </div>
            {/* <Modal
                isOpen={isNoteModalVisible}
                className="note_modal__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <NoteModal
                    closeHandler={() => setIsNoteModalVisible(false)}
                    isConciergeNote={isConciergeNote}
                />
            </Modal> */}
        </div>
    }

    return (
        showReassignTicket === true
        ? <ReassignTicket
            setShowReassignTicket={setShowReassignTicket}
            totalCallTime={totalCallTime}
        />
        : renderInCall()
    )
}

export default InCallModal
