import { IAnalyticsData } from "../../interfaces";

const getDataForAnalytic = (analytic: IAnalyticsData, title: string) => {
    const properties = getPropertyFromAnalyticTitle(title);

    if (Array.isArray(properties)) {
        let dataArray = [];

        for (let index = 0; index < properties.length; index++) {
            const property = properties[index];
            
            let data = analytic[property] as number;

            switch (title) {
                case 'Average Ticket Handle Time':
                    data = data / 60;

                    dataArray.push(Math.round((data + Number.EPSILON) * 100) / 100);

                    break;

                case 'Average After-call Work Time':
                    data = data / 60;

                    dataArray.push(Math.round((data + Number.EPSILON) * 100) / 100);

                    break;

                case 'Average Age of Query':
                    data = data / (60 * 60);

                    dataArray.push(Math.round((data + Number.EPSILON) * 100) / 100);

                    break;

                default:
                    dataArray.push(data);

                    break;
            }
        }

        return dataArray;
    }

    let data = analytic[getPropertyFromAnalyticTitle(title) as string] as number;

    switch (title) {
        case 'Average Ticket Handle Time':
            data = data / 60;

            return Math.round((data + Number.EPSILON) * 100) / 100;
        case 'Average After-call Work Time':
            data = data / 60;

            return Math.round((data + Number.EPSILON) * 100) / 100;
        case 'Average Age of Query':
            data = data / (60 * 60);

            return Math.round((data + Number.EPSILON) * 100) / 100;
        default:
            return data;
    }
}

const getPropertyFromAnalyticTitle = (title: string) => {
    switch (title) {
        case 'First Response Time':
            return 'firstResponseTime';
        case 'Agent Utilisation Rate':
            return 'agentUtilizationRate';
        case 'Occupancy Rate':
            return 'occupancy';
        case 'Average Speed of Answer':
            return 'averageSpeedOfAnswer';
        case 'Average Ticket Handle Time':
            return 'averageTicketHandleTime';
        case 'Agent Productivity':
            return 'agentProductivity';
        case 'Average After-call Work Time':
            return 'averageAfterCallWorkTime';
        case 'Call Volume':
            return ['totalCallsHandledByAConcierge', 'totalCallsHandledByAutomatedSystem'];
        case 'Call Abandonment Rate':
            return 'callAbandonmentRate';
        case 'Average Waiting Time':
            return 'averageWaitingTime';
        case 'Call Arrival Rate':
            return 'callArrivalRate';
        case 'Percentage of Calls Blocked':
            return 'percentageOfCallsBlocked';
        case 'Active Waiting Calls':
            return 'activeWaitingCalls';
        case 'Average Age of Query':
            return 'averageAgeOfQuery';
        case 'Longest Hold Time Rate':
            return 'longestHoldTimeRate';
        case 'Subscriber Utilization Rate':
            return 'subscriberUtilizationRate';
        case 'A la Carte Minutes Sold':
            return 'aLaCarteMinutesSold';
        case 'Support Calls with features sold':
            return 'percentageOfSupportCallsWithIncrementalFeaturesSold';
        case 'Utilization of Entitlement':
            return 'utilizationOfEntitlement';
        default:
            return '';
    }
}

const getUnitFromAnalyticTitle = (title: string): string => {
    switch (title) {
        case 'First Response Time':
            return 'sec';
        case 'Agent Utilisation Rate':
            return '%';
        case 'Occupancy Rate':
            return '%';
        case 'Average Speed of Answer':
            return 'sec';
        case 'Average Ticket Handle Time':
            return 'min';
        case 'Agent Productivity':
            return '%';
        case 'Average After-call Work Time':
            return 'min';
        case 'Call Volume':
            return '';
        case 'Call Abandonment Rate':
            return '%';
        case 'Average Waiting Time':
            return 'sec';
        case 'Call Arrival Rate':
            return '';
        case 'Percentage of Calls Blocked':
            return '%';
        case 'Active Waiting Calls':
            return '';
        case 'Average Age of Query':
            return 'h';
        case 'Longest Hold Time Rate':
            return 'sec';
        case 'Subscriber Utilization Rate':
            return '%';
        case 'A la Carte Minutes Sold':
            return '';
        case 'Support Calls with features sold':
            return '%';
        case 'Utilization of Entitlement':
            return '%';
        default:
            return '';
    }
}

export { getPropertyFromAnalyticTitle, getUnitFromAnalyticTitle, getDataForAnalytic }