import React, { createContext, FC, useContext } from 'react'
import { IFilterTicketDTO, IReassignTicketDTO, ITicketData, IUpdateTicketDTO } from '../interfaces';
import { AxiosContext } from './AxiosContext';

interface ITicketContext {
    reassignTicket(data: IReassignTicketDTO): Promise<void>;
    updateTicket(id: number, data: IUpdateTicketDTO): Promise<void>;
    getAllTickets(): Promise<ITicketData[]>;
    filterTickets(data: IFilterTicketDTO): Promise<ITicketData[]>;
    getTicketById(id: number): Promise<ITicketData>;
};

const TicketContext = createContext({} as ITicketContext);

interface TicketContextProps {
    children: React.ReactNode;
}

const TicketContextProvider: FC<TicketContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const reassignTicket = (data: IReassignTicketDTO) => {
        return axiosInstance({
            method: 'PUT',
            url: 'tickets/reassign',
            data
        }).then((response: any) => {
            console.log(response.data);
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
    }

    const updateTicket = (id: number, data: IUpdateTicketDTO) => {
        return axiosInstance({
            method: 'PUT',
            url: `tickets/${id}`,
            data
        }).then((response: any) => {
            console.log(response.data);
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
    }

    const getAllTickets = () => {
        return axiosInstance({
            method: 'GET',
            url: 'tickets',
        }).then((response: any) => {
            return response.data;
        });
    }

    const filterTickets = (data: IFilterTicketDTO) => {
        let queryUrl = 'tickets/filter?';

        Object.keys(data).forEach((key) => {
            queryUrl += key + '=' + data[key] + '&';
        });

        return axiosInstance({
            method: 'GET',
            url: queryUrl,
        }).then((response: any) => {
            return response.data;
        });
    }

    const getTicketById = (id: number) => {
        return axiosInstance({
            method: 'GET',
            url: `tickets/${id}`,
        }).then((response: any) => {
            return response.data;
        });
    }

    const providerValue = {
        reassignTicket,
        updateTicket,
        getAllTickets,
        filterTickets,
        getTicketById
    }

    return (
        <TicketContext.Provider value={providerValue}>
            {props.children}
        </TicketContext.Provider>
    )
}

export { TicketContext, TicketContextProvider }
