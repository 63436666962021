import React, { FC } from 'react'
import { IConciergeData } from '../../../../interfaces'
import { ConciergeBackendRoleEnum, ConciergeRoleEnum } from '../../../../util/enums';

interface ConciergeCardProps {
    concierge: IConciergeData;
}

const ConciergeCard: FC<ConciergeCardProps> = (props) => {
    const { concierge } = props;

    const getConciergeRoleFromBackendRole = (backendRole: ConciergeBackendRoleEnum) => {
        if (backendRole === ConciergeBackendRoleEnum.Concierge) {
            return ConciergeRoleEnum.CONCIERGE;
        }

        if (backendRole === ConciergeBackendRoleEnum.Manager) {
            return ConciergeRoleEnum.MANAGER;
        }

        return ConciergeRoleEnum.ADMIN;
    }

    return (
        <div className="concierge_card__wrapper">
            <div className="concierge_card__picture">
                {/* <img src="/assets/profile_picture.svg" alt="profile" /> */}
                {
                    concierge.conciergeUser.thumbnailUrl !== "" && concierge.conciergeUser.thumbnailUrl !== null
                    ? (<img src={concierge.conciergeUser.thumbnailUrl} alt="image" />)
                    : (<img src="/assets/user_profile_letters.svg" alt="" />)
                }
            </div>
            <div className="concierge_card__content">
                <div className="concierge_card__name">
                    {/* <p>Strahinja Markovic</p>
                    <div className={`concierge_card__role Manager_role`}>
                        <span>Manager</span>
                    </div> */}
                    <p>{concierge.conciergeUser.firstName + ' ' + concierge.conciergeUser.lastName}</p>
                    <div className={`concierge_card__role ${getConciergeRoleFromBackendRole(concierge.role)}_role`}>
                        <span>{getConciergeRoleFromBackendRole(concierge.role)}</span>
                    </div>
                </div>
                <div className="concierge_card__data">
                    {/* <span>Email: <b>adamdriver@725contact.com</b></span> */}
                    <span>Email: <b>{concierge.email}</b></span>
                </div>
                <div className="concierge_card__data">
                    {/* <span>Phone number: <b>260-579-1955</b></span> */}
                    <span>Phone number: <b>{concierge.phoneNumber}</b></span>
                </div>
                <div className="concierge_card__data">
                    <span>Pool: <b>725Contact - East Coast</b></span>
                    {/* <span>Pool: <b>{concierge.poolId}</b></span> */}
                </div>
            </div>
        </div>
    )
}

export default ConciergeCard
