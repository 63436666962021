import { DateTime } from 'luxon';
import React, { FC, useContext, useState } from 'react'
import { TicketContext } from '../../../../contexts';
import { ITicketData, IUpdateTicketDTO } from '../../../../interfaces'
import { TicketStatusEnum } from '../../../../util/enums';

interface TicketInformationModalProps {
    ticket: ITicketData | undefined;
    closeModal(): void;
    updateTicketList(id: number, status: TicketStatusEnum): void;
}

const TicketInformationModal: FC<TicketInformationModalProps> = (props) => {
    const { ticket, closeModal, updateTicketList } = props;

    const [selectedStatus, setSelectedStatus] = useState<TicketStatusEnum | undefined>(ticket?.status);
    const [showStatuses, setShowStatuses] = useState(false);

    const { updateTicket } = useContext(TicketContext);

    const handleSetStatus = (status: TicketStatusEnum) => {
        setSelectedStatus(status);

        if (ticket) {
            const dto = {
                conciergeId: ticket?.conciergeId,
                conciergeNote: ticket?.conciergeNote ? ticket?.conciergeNote : '',
                customerId: ticket?.customerId,
                customerNote: ticket?.customerNote ? ticket?.customerNote : '',
                status: status
            } as IUpdateTicketDTO;
    
            updateTicket(ticket.id, dto)
            .then(response => {
                updateTicketList(ticket.id, status)
            })
            .catch(error => {
                alert(error);
            })
        }
    }

    const renderStatuses = () => {
        const statuses = Object.keys(TicketStatusEnum).map((key) => {
            if (key > '6') {
                const value = TicketStatusEnum[key as keyof typeof TicketStatusEnum];
    
                if (value === selectedStatus) {
                    return;
                }
    
                return (
                    <div
                        key={key}
                        className="ticket_information__dropdown_item"
                        onClick={() => handleSetStatus(value)}
                    >
                        <span>{renderStatusName(value)}</span>
                        <div className="ticket_information__dropdown_divider"></div>
                    </div>
                );
            }
        });

        return <div className="ticket_information__dropdown_container">
            <div
                className={`ticket_information__selected ticket_information__selected__${selectedStatus}`}
                tabIndex={0}
                onClick={e => setShowStatuses(!showStatuses)}
                // onBlur={e => setShowStatuses(false)}
            >
                <div className="ticket_information__divider"></div>
                <div className="ticket_information__selected_container">
                    {
                        <span>{renderStatusName(selectedStatus)}</span>
                    }
                </div>
                <div className="ticket_information__arrow_container">
                    <img src="/assets/arrow.svg" alt="v" />
                </div>
            </div>
            {
                showStatuses && (<div
                    className="ticket_information__dropdown"
                    tabIndex={9}
                    onClick={() => setShowStatuses(!showStatuses)}
                    onBlur={() => setShowStatuses(false)}
                >
                    <div className="ticket_information__dropdown__selected_type">
                        <div className="ticket_information__divider"></div>
                        <div className="ticket_information__selected_container">
                            {
                                <span>{renderStatusName(selectedStatus)}</span>
                            }
                        </div>
                        <div className="ticket_information__arrow_container">
                            <img src="/assets/arrow.svg" alt="v" />
                        </div>
                    </div>
                    {statuses}
                </div>)
            }
        </div>
    }

    const renderStatusName = (value: TicketStatusEnum | undefined) => {
        switch (value) {
            case TicketStatusEnum.OPEN:
                return 'Open';
            case TicketStatusEnum.CLOSED_COMPLETE:
                return 'Closed Complete';
            case TicketStatusEnum.CLOSE_PENDING:
                return 'Closed Pending';
            case TicketStatusEnum.PENDING_COMPLETION:
                return 'Pending Completion';
            case TicketStatusEnum.CLOSED_INCOMPLETE:
                return 'Closed Incomplete';
            case TicketStatusEnum.REVIEW:
                return 'Review';
            case TicketStatusEnum.AUDIT:
                return 'Audit';
            default:
                return 'Ticket Status';
        }
    }

    return (
        <div className="ticket_information__wrapper">
            <div className="ticket_information__header">
                <div className="ticket_information__divider"></div>
                <div className="ticket_information__title">
                    <h2>Ticket Information</h2>
                </div>
                <div className="ticket_information__close">
                    <img src="/assets/x.svg" alt="x" onClick={closeModal} />
                </div>
            </div>
            <div className="ticket_information__content">
                <span>Ticket ID:</span>
                <p>{ticket?.id}</p>
            </div>
            <div className="ticket_information__content">
                <span>Status:</span>
                {renderStatuses()}
            </div>
            <div className="ticket_information__content">
                <span>Action:</span>
                <p className="ticket_information__content_action">Concierge communication pending</p>
            </div>
            <div className="ticket_information__content">
                <span>Date opened:</span>
                {
                    ticket && (
                        <p>{DateTime.fromISO(ticket.createdAt.toString()).toFormat('MM/dd/yyyy')}</p>
                    )
                }
            </div>
            <div className="ticket_information__content ticket_information__content_note">
                <span>Customer's note (public):</span>
                <p className="ticket_information__content_spacing">{ticket?.customerNote}</p>
            </div>
            <div className="ticket_information__content">
                <span>Concierge's note (private):</span>
                <p className="ticket_information__content_spacing">{ticket?.conciergeNote}</p>
            </div>
        </div>
    )
}

export default TicketInformationModal
