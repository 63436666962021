import React, { FC } from 'react'
import { AuthenticationContextProvider } from "./AuthenticationContext";
import { AxiosContextProvider } from "./AxiosContext";
import { ConciergeContextProvider } from "./ConciergeContext";
import { GlobalStateContextProvider } from "./GlobalStateContext";
import { SecurityQuestionContextProvider } from "./SecurityQuestionContext";
import { ContactContextProvider } from "./ContactContext";
import { SendMessageContextProvider } from "./SendMessageContext";
import { ResetPasswordContextProvider } from "./ResetPasswordContext";
import { AmazonConnectContextProvider } from "./AmazonConnectContext";
import { CustomerContextProvider } from "./CustomerContext";
import { SubscriptionPlanContextProvider } from "./SubscriptionPlanContext";
import { CreditCardContextProvider } from "./CreditCardContext";
import { TicketContextProvider } from "./TicketContext";
import { GlobalTimerContextProvider } from './GlobalTimerContext';
import { SupportCallTimerContextProvider } from './SupportCallTimerContext';
import { ContactCallTimerContextProvider } from './ContactCallTimerContext';
import { AnalyticsContextProvider } from './AnalyticsContext';

interface GlobalContextProps {
    children: React.ReactNode;
}

const GlobalContextProvider: FC<GlobalContextProps> = (props) => {
    return (
        <AuthenticationContextProvider>
            <GlobalStateContextProvider>
                <AxiosContextProvider>
                    <ConciergeContextProvider>
                        <AmazonConnectContextProvider>
                            <SecurityQuestionContextProvider>
                                <CustomerContextProvider>
                                    <ContactContextProvider>
                                        <SendMessageContextProvider>
                                            <ResetPasswordContextProvider>
                                                <SubscriptionPlanContextProvider>
                                                    <CreditCardContextProvider>
                                                        <TicketContextProvider>
                                                            <GlobalTimerContextProvider>
                                                                <SupportCallTimerContextProvider>
                                                                    <ContactCallTimerContextProvider>
                                                                        <AnalyticsContextProvider>
                                                                            {props.children}
                                                                        </AnalyticsContextProvider>
                                                                    </ContactCallTimerContextProvider>
                                                                </SupportCallTimerContextProvider>
                                                            </GlobalTimerContextProvider>
                                                        </TicketContextProvider>
                                                    </CreditCardContextProvider>
                                                </SubscriptionPlanContextProvider>
                                            </ResetPasswordContextProvider>
                                        </SendMessageContextProvider>
                                    </ContactContextProvider>
                                </CustomerContextProvider>
                            </SecurityQuestionContextProvider>
                        </AmazonConnectContextProvider>
                    </ConciergeContextProvider>
                </AxiosContextProvider>
            </GlobalStateContextProvider>
        </AuthenticationContextProvider>
    )
}

export default GlobalContextProvider
