import React, { FC, useContext, useEffect, useState } from 'react'
import Modal from 'react-modal';
import { CustomerAuthModal, IncomingCallModal, NoteModal, TicketSummaryModal } from '..';
import { CustomerContext, GlobalStateContext, SecurityQuestionContext } from '../../../contexts';
import { IChargeMinuteDTO } from '../../../interfaces';
import { InCallModal } from '../../../templates';
import { ModalTypesEnum } from '../../../util/enums';

const MainModal: FC = () => {
    const { showModal, activeModal, setActiveModal } = useContext(GlobalStateContext);
    const { customerData } = useContext(SecurityQuestionContext);
    const { chargeCustomerByMinute } = useContext(CustomerContext);

    const [isConciergeNote, setIsConciergeNote] = useState(false);

    const [totalCallTime, setTotalCallTime] = useState(0);
    const [isTotalTimeOn, setIsTotalTimeOn] = useState(false);
    const [supportCallTime, setSupportCallTime] = useState(0);
    const [isSupportCallTimeOn, setIsSupportCallTimeOn] = useState(false);
    const [contactCallTime, setContactCallTime] = useState(0);
    const [isContactCallTimeOn, setIsContactCallTimeOn] = useState(false);
    const [chargedMinutesTime, setChargedMinutesTime] = useState(0);
    const [isChargedMinutesTimeOn, setIsChargedMinutesTimeOn] = useState(false);

    const renderModalContent = () => {
        switch (activeModal) {
            case ModalTypesEnum.INCOMING_CALL:
                return <IncomingCallModal
                    setIsTotalTimeOn={setIsTotalTimeOn}
                    setIsSupportCallTimeOn={setIsSupportCallTimeOn}
                    setIsChargedMinutesTimeOn={setIsChargedMinutesTimeOn}
                />
            case ModalTypesEnum.CUSTOMER_AUTH:
                return <CustomerAuthModal
                    totalCallTime={totalCallTime}
                    setIsTotalTimeOn={setIsTotalTimeOn}
                    setIsSupportCallTimeOn={setIsSupportCallTimeOn}
                    setIsChargedMinutesTimeOn={setIsChargedMinutesTimeOn}
                />
            case ModalTypesEnum.IN_CALL:
                return <InCallModal
                    totalCallTime={totalCallTime}
                    supportCallTime={supportCallTime}
                    chargedMinutesTime={chargedMinutesTime}
                    contactCallTime={contactCallTime}
                    isSupportCallTimeOn={isSupportCallTimeOn}
                    setIsConciergeNote={setIsConciergeNote}
                    setIsTotalTimeOn={setIsTotalTimeOn}
                    setIsSupportCallTimeOn={setIsSupportCallTimeOn}
                    setIsChargedMinutesTimeOn={setIsChargedMinutesTimeOn}
                    setIsContactCallTimeOn={setIsContactCallTimeOn}
                />
            case ModalTypesEnum.NOTE:
                return <NoteModal
                    totalCallTime={totalCallTime}
                    isConciergeNote={isConciergeNote}
                />
            case ModalTypesEnum.TICKET_SUMMARY:
                return <div className="modal__scroll_component">
                    <TicketSummaryModal totalCallTime={totalCallTime} />
                </div>
            default:
                return;
        }
    }

    const resetTimers = () => {
        setIsTotalTimeOn(false);
        setIsContactCallTimeOn(false);
        setIsSupportCallTimeOn(false);
        setIsChargedMinutesTimeOn(false);
        setTotalCallTime(0);
        setSupportCallTime(0);
        setContactCallTime(0);
        setChargedMinutesTime(0);
    }

    useEffect(() => {
        if (isTotalTimeOn) {
            let seconds = 0;
            setTotalCallTime(seconds);
            
            const timerInterval = setInterval(
                () => {
                    seconds += 1;
                    setTotalCallTime(seconds);
                },
                1000
            )
    
            return () => {
                clearInterval(timerInterval);
                resetTimers();
            }
        }
    }, [isTotalTimeOn])

    useEffect(() => {
        if (isSupportCallTimeOn && customerData?.customer.subscription) {
            let seconds = customerData.customer.subscription.remainingNumOfMinsPerSupportCall * 60;
            setSupportCallTime(seconds);
            
            const timerInterval = setInterval(
                () => {
                    if (seconds > 0) {
                        seconds -= 1;
                    }

                    setSupportCallTime(seconds);
                },
                1000
            )
    
            return () => {
                clearInterval(timerInterval);
                resetTimers();
            }
        }
    }, [isSupportCallTimeOn])

    useEffect(() => {
        if (isContactCallTimeOn && customerData?.customer.subscription) {
            let seconds = customerData.customer.subscription.remainingNumOfMinsPerCallToContact * 60;
            setContactCallTime(seconds);
            
            const timerInterval = setInterval(
                () => {
                    if (seconds > 0) {
                        seconds -= 1;
                    }

                    setContactCallTime(seconds);
                },
                1000
            )
    
            return () => {
                clearInterval(timerInterval);
                resetTimers();
            }
        }
    }, [isContactCallTimeOn])

    useEffect(() => {
        if (isChargedMinutesTimeOn && customerData) {
            let minutes = 1;
            setChargedMinutesTime(minutes);

            const dto = {
                customerId: customerData.customer.id
            } as IChargeMinuteDTO;

            chargeCustomerByMinute(dto)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
                
                alert('There was an error while billing the customer for the minute.');
            });
            
            const timerInterval = setInterval(
                () => {
                    minutes += 1;

                    setChargedMinutesTime(minutes);

                    chargeCustomerByMinute(dto)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                        
                        alert('There was an error while billing the customer for the minute.');
                    });
                },
                60000
            )
    
            return () => {
                clearInterval(timerInterval);
                resetTimers();
            }
        }
    }, [isChargedMinutesTimeOn])

    return (
        <Modal
            isOpen={showModal}
            className={activeModal}
            ariaHideApp={false}
            overlayClassName="modal__overlay"
        >
            {renderModalContent()}
        </Modal>
    )
}

export default MainModal
