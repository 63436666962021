import { IAnalyticComponentInfoData } from "../../interfaces";

const ANALYTIC_COMPONENTS_INFO = [
    {
        title: "First Response Time",
        description: "FRT refers to the time it takes customers to reach a qualified agent after making a call. This quantity is closely related to Service Level — an important KPI we will track."
    },
    {
        title: "Agent Utilisation Rate",
        description: "Agent Utilization Rate is the average amount of time an agent spends handling calls in a given period relative to the total number of work hours. For example, an agent who works an average of 5 hours in an 8-hour workday has a utilization rate of 62.5%. This metric is considered a good indicator of agent performance."
    },
    {
        title: "Occupancy Rate",
        description: "Occupancy Rate measures the time a concierge spends engaging customers on live calls and doing admin tasks related to those calls. Low Occupancy Rates may be due to: duties not call-related, longer breaks, frequency of meetings/events/training sessions, poor agent work habits."
    },
    {
        title: "Average Speed of Answer",
        description: "ASA calculates the average time to answer customer calls. We equate this metric with First Response Time (FRT). These two time-based quantities both set an ideal threshold to respond to customer calls."
    },
    {
        title: "Average Ticket Handle Time",
        description: "This is the average amount of time to handle a call from start to finish. It includes hold times and after-call tasks. We will use this metric to set team benchmarks and to discern which concierges need further training to handle customer calls."
    },
    {
        title: "Agent Productivity",
        description: "This computes the number of closed tickets divided by total concierge hours (at individual and group level, over definable period)."
    },
    {
        title: "Average After-call Work Time",
        description: "This metric refers to the time it takes concierges to finish tasks related to the calls they handle.  High values for this post-call metric suggests inefficiency on the part of the concierge, workflow, or tools."
    },
    {
        title: "Call Volume",
        description: "This is all incoming and outgoing calls handled by the virtual call center over a given period. We will categorize call volume into: Total Calls Handled By a Concierge, Total Calls Handled By an Automated System (e.g., IVR)"
    },
    {
        title: "Call Abandonment Rate",
        description: "Perhaps more than anyone else, customers want their issues resolved quickly. Not surprisingly, many of them hang up when kept on hold for too long. Abandoned calls signal customer frustration and potential loss of trust in our brand."
    },
    {
        title: "Average Waiting Time",
        description: "This metric relates to instances where concierges need to place customer calls on hold. While there are legitimate reasons for holding calls, concierges should always minimize Average Waiting Time."
    },
    {
        title: "Call Arrival Rate",
        description: "This is the total number of calls a contact center receives within a specific period. The time frame should be filterable or expressed by year, month, day, hour, minute and second."
    },
    {
        title: "Percentage of Calls Blocked",
        description: "Ideally, calling up a contact center should rarely lead to a busy signal. Auto-transfer, IVR, and other call volume features are helpful here. This metric refers to the proportion of incoming calls that receive a busy signal. We need to balance customer satisfaction, budget, and cost efficiency to keep this metric at or near zero."
    },
    {
        title: "Active Waiting Calls",
        description: "This measures how well teams cope with call volume in real-time. It gives concierge managers an idea about the number of calls the concierges handle vs. those on hold. Too many calls that are being held lead to poor customer experience. There is no formula for this metric, this is a feed from Amazon Connect."
    },
    {
        title: "Average Age of Query",
        description: "This is the average amount of time a customer query that has not been resolved on the first call remains open. Related to FCR, this metric provides a glimpse into which issues, channels, or engagement approaches lead to longer resolution periods."
    },
    {
        title: "Longest Hold Time Rate",
        description: "This is the longest time a customer call was on hold without the customer abandoning the call. Often, this involves a single incident unless multiple incidents registered the same call waiting periods. We will strive to keep this metric at a minimum."
    },
    {
        title: "Subscriber Utilization Rate",
        description: "Number of calls received and handled, divided by total active subscribers, in a given defined period."
    },
    {
        title: "A la Carte Minutes Sold",
        description: "Number of support or call-to-contact minutes sold in a defined period: This will require feed from the Database to track the number of support calls added for a given period."
    },
    {
        title: "Support Calls with features sold",
        description: "Percentage of support calls with incremental or upgrade features sold, in a defined period: This will require a feed from the Database to track the additive Upgrades from Basic to Plus, Basic to Premium and Plus to Premium."
    },
    {
        title: "Utilization of Entitlement",
        description: "Percentage of subscribers using full entitlement of support incidents, in a defined period."
    }
] as IAnalyticComponentInfoData[];

export default ANALYTIC_COMPONENTS_INFO;