import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { createContext, FC, useContext } from 'react'
import { AuthenticationContext } from './AuthenticationContext';
import { GlobalStateContext } from './GlobalStateContext';

const devUrl = 'http://localhost:3000/api/';
const deployUrl = 'https://1eocnxysy2.execute-api.us-west-2.amazonaws.com/api/';
const test = 'https://925115064a17.ngrok.io/api/';

interface IAxiosContext {
    axiosInstance: AxiosInstance;
}

const AxiosContext = createContext({} as IAxiosContext);

interface AxiosContextProps {
    children: React.ReactNode;
}

const AxiosContextProvider: FC<AxiosContextProps> = (props) => {
    const { token, saveToken, removeToken, email, saveEmail, removeEmail } = useContext(AuthenticationContext);
    const { setShowLoader, isCCPInitialized } = useContext(GlobalStateContext);

    const axiosInstance = axios.create({
        baseURL: deployUrl,
        headers: {
            Authorization: "Bearer ".concat(token),
            'Content-Type': 'application/json',
        },
        validateStatus: () => true,
    });

    const handleStartLoader = (config: AxiosRequestConfig) => {
        if (isCCPInitialized) {
            setShowLoader(true);
        }

        return config;
    }

    const handleEndLoader = (response: AxiosResponse) => {
        console.log('handleEndLoader response', response);

        if (response.data === 'Connection terminated unexpectedly') {
            // axiosInstance(response.config);
            axios.request(response.config);
            
            if (isCCPInitialized) {
                setShowLoader(false);
            }

            return Promise.reject(response);
        }

        if (isCCPInitialized) {
            setShowLoader(false);
        }

        return response;
    }

    const handleErrors = (error: any) => {
        console.log('error', error);
        console.log('response', error.response);
        console.log('message', error.message);

        if (error.response) {
            console.log('response message', error.response.message);
        }

        if (error.message === 'Network Error') {
            // axiosInstance(error.config);
            axios.request(error.config);

            return;
        }

        if (error.response.message === 'Connection terminated unexpectedly' || error.response.status === 500) {
            // axiosInstance(error.config);
            axios.request(error.config);

            return;
        }

        if (error.response.status === 401) {
            if (token && email) {
                axiosInstance({
                    method: 'POST',
                    url: 'auth/concierges/refresh-token',
                    data: {
                        email: email,
                        token: token
                    }
                }).then((response: any) => {
                    saveToken(response.data.token);
                    saveEmail(response.data.email);

                    return axios.request(error.config);
                }).catch((error) => {
                    removeToken();
                    removeEmail();

                    return Promise.reject(error);
                });
            }
        }
        
        return Promise.reject(error);
    }

    axiosInstance.interceptors.request.use(
        config => handleStartLoader(config),
        error => error
    );

    axiosInstance.interceptors.response.use(
        response => handleEndLoader(response),
        error => handleErrors(error)
    );

    const providerValue = {
        axiosInstance
    };
    
    return (
        <AxiosContext.Provider value={providerValue}>
            {props.children}
        </AxiosContext.Provider>
    )
}

export { AxiosContext, AxiosContextProvider }
