import React, { FC, useContext, useState } from 'react'
import { AmazonConnectContext, ConciergeContext, CustomerContext, GlobalStateContext, GlobalTimerContext, SupportCallTimerContext } from '../../../contexts';
import { SecurityQuestionContext } from '../../../contexts/SecurityQuestionContext';
import { IAuthenticateSecurityQuestion, ICustomerWithTicketData, IEditCustomerSubscriptionDTO } from '../../../interfaces';
import { ConciergeBackendStatusEnum, ModalTypesEnum } from '../../../util/enums';
import { getAgentStateFromConciergeStatus } from '../../../util/functions';
import { ReassignTicket } from '../../Actions';
import Timer from '../../Timer/Timer';

import useSound from 'use-sound';

interface CustomerAuthModalProps {
    setIsTotalTimeOn(state: boolean): void;
    setIsSupportCallTimeOn(state: boolean): void;
    setIsChargedMinutesTimeOn(state: boolean): void;
    totalCallTime: number;
}

const CustomerAuthModal: FC<CustomerAuthModalProps> = (props) => {
    const { setIsChargedMinutesTimeOn, setIsTotalTimeOn, setIsSupportCallTimeOn, totalCallTime } = props;

    const { securityQuestionData, getSecurityQuestion, authenticateCustomer, setCustomerData, isCustomerEnrolled, setIsCustomerEnrolled } = useContext(SecurityQuestionContext);
    const { updateConciergeStatus, getCurrentConcierge, conciergeData } = useContext(ConciergeContext);
    const { setActiveModal, setShowModal } = useContext(GlobalStateContext);
    const { agent, connection, contact } = useContext(AmazonConnectContext);
    const { editCustomerSubscription } = useContext(CustomerContext);

    const [play] = useSound(process.env.PUBLIC_URL + '/assets/sounds/ping.wav');

    const [email, setEmail] = useState('');
    const [passphrase, setPassphrase] = useState('');
    const [showHint, setShowHint] = useState(false);
    const [showReassignTicket, setShowReassignTicket] = useState(false);
    const [isEnrolling, setIsEnrolling] = useState(false);

    const getSecurityQuestionData = () => {
        getSecurityQuestion(email)
            .then((response) => console.log(response));
    }

    const authenticate = () => {
        const data = {
            conciergeId: conciergeData?.id,
            customerId: securityQuestionData?.customerId,
            securityQuestionId: securityQuestionData?.securityQuestion.id,
            passPhrase: passphrase.trim().toLowerCase(),
        } as IAuthenticateSecurityQuestion;

        authenticateCustomer(data)
        .then((response) => {
            if (response && response.customer.subscription) {
                if (response.customer.subscription.remainingNumOfSupportCalls > 0) {
                    const dto = {
                        customerId: response.customer.id,
                        subscription: {
                            remainingNumOfMinsPerCallToContact: response.customer.subscription.remainingNumOfMinsPerCallToContact,
                            remainingNumOfPriorityContactGroups: response.customer.subscription.remainingNumOfPriorityContactGroups,
                            remainingNumOfSupportCalls: response.customer.subscription.remainingNumOfSupportCalls - 1,
                            remainingNumOfMinsPerSupportCall: response.customer.subscription.remainingNumOfMinsPerSupportCall,
                        }
                    } as IEditCustomerSubscriptionDTO;

                    editCustomerSubscription(dto)
                    .then(editResponse => {
                        const customerData: ICustomerWithTicketData = JSON.parse(JSON.stringify(response));

                        customerData.customer.subscription = editResponse;

                        setCustomerData(customerData);
                        setActiveModal(ModalTypesEnum.IN_CALL);
                        // setShowCustomerAuthModal(false);
                        // setShowInCallModal(true);

                        if (response.customer.subscription) {
                            setIsSupportCallTimeOn(true);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        alert(error);
                    })

                    return;
                }

                setIsChargedMinutesTimeOn(true);
                setActiveModal(ModalTypesEnum.IN_CALL);
                // setShowCustomerAuthModal(false);
                // setShowInCallModal(true);
            }
        });
    }

    const toggleHint = () => {
        setShowHint(!showHint);
    }

    const enrollCustomer = () => {
        if (connection && agent) {
            // agent.mute();

            play();

            setIsEnrolling(true);

            connection.enrollSpeakerInVoiceId()
            .then(response => {
                console.log(response);

                play();

                setIsEnrolling(false);
                setIsCustomerEnrolled(true);

                alert('Customer enrolled successfully.');

                // agent.unmute();
            })
            .catch(error => {
                console.log(error);

                play();

                setIsEnrolling(false);

                alert('There was an error while enrolling the customer.');

                // agent.unmute();
            })
        }
        else {
            alert("Agent and connection have not been established.");
        }
    }

    const handleEndCall = () => {
        if (connection && agent && contact) {
            connection.destroy({
                success: () => {
                    contact.clear({
                        success: () => {
                            handleUpdateConciergeStatusToAvailable();
                            
                            // setShowCustomerAuthModal(false);
                            setShowModal(false);
                            setActiveModal(undefined);
                        }
                    });
                },
            });

            return;
        }
    }

    const handleUpdateConciergeStatusToAvailable = () => {
        if (conciergeData) {
            updateConciergeStatus(conciergeData.id, ConciergeBackendStatusEnum.Routable)
            .then(response => {
                const agentState = getAgentStateFromConciergeStatus(ConciergeBackendStatusEnum.Routable, agent);
                
                if (agentState) {
                    agent?.setState(agentState);
                }
                
                getCurrentConcierge();
            })
        }
    }

    const renderEnrollmentOrAuthentication = () => {
        if (!isCustomerEnrolled) {
            return <div className="customer_auth__email_input">
                <button
                    className="customer_auth__enroll"
                    onClick={() => enrollCustomer()}
                    disabled={isEnrolling}
                >
                    Enroll customer
                </button>
            </div>
        }

        if (!securityQuestionData) {
            return <div className="customer_auth__email_input">
                <input
                    className="customer_auth__input"
                    type="text"
                    name="email"
                    id="email"
                    placeholder="e-mail address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <button
                    className="customer_auth__next_button"
                    onClick={getSecurityQuestionData}
                    disabled={email === ''}
                >
                    Next
                </button>
            </div>
        }

        return <div className="customer_auth__passphrase_input">
            { showHint
                ? <span>{securityQuestionData.securityQuestion.hint}</span>
                : null
            }
            <div className="customer_auth__hint_container">
                <input
                    className="customer_auth__input"
                    type="text"
                    name="passphrase"
                    id="passphrase"
                    placeholder="Passphrase"
                    value={passphrase}
                    onChange={e => setPassphrase(e.target.value)}
                />
                <button
                    className="customer_auth__hint_button"
                    onClick={toggleHint}
                >
                    Hint
                </button>
            </div>
            <button
                className="customer_auth__next_button"
                onClick={authenticate}
                disabled={passphrase === ''}
            >
                Next
            </button>
        </div>
    }

    return (
        showReassignTicket === true
        ? <ReassignTicket
            setShowReassignTicket={setShowReassignTicket}
            totalCallTime={totalCallTime}
        />
        : <div className="customer_auth__wrapper">
            <div className="customer_auth__top">
                <img src="/assets/orange_route_call.svg" alt="" onClick={() => setShowReassignTicket(true)} />
                <div className="customer_auth__info">
                    <p>Not authenticated</p>
                    <span>in call</span>
                    {/* <span>00:09</span> */}
                    <Timer time={totalCallTime} />
                </div>
                <img src="/assets/red_hangup_call.svg" alt="" onClick={handleEndCall} />
            </div>
            {/* { !securityQuestionData
                ? (
                    <div className="customer_auth__email_input">
                        <button
                            className="customer_auth__enroll"
                            onClick={() => enrollCustomer()}
                            disabled={isEnrolling}
                        >
                            Enroll customer
                        </button>
                        <input
                            className="customer_auth__input"
                            type="text"
                            name="email"
                            id="email"
                            placeholder="e-mail address"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <button
                            className="customer_auth__next_button"
                            onClick={getSecurityQuestionData}
                            disabled={email === ''}
                        >
                            Next
                        </button>
                    </div>
                )
                : (
                    <div className="customer_auth__passphrase_input">
                        { showHint
                            ? <span>{securityQuestionData.securityQuestion.hint}</span>
                            : null
                        }
                        <div className="customer_auth__hint_container">
                            <input
                                className="customer_auth__input"
                                type="text"
                                name="passphrase"
                                id="passphrase"
                                placeholder="Passphrase"
                                value={passphrase}
                                onChange={e => setPassphrase(e.target.value)}
                            />
                            <button
                                className="customer_auth__hint_button"
                                onClick={toggleHint}
                            >
                                Hint
                            </button>
                        </div>
                        <button
                            className="customer_auth__next_button"
                            onClick={authenticate}
                            disabled={passphrase === ''}
                        >
                            Next
                        </button>
                    </div>
                )
            } */}
            {renderEnrollmentOrAuthentication()}
        </div>
    )
}

export default CustomerAuthModal
