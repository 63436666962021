import React, { FC, useContext, useEffect, useState } from 'react'
import { GlobalStateContext, SecurityQuestionContext } from '../../../contexts'
import { ICustomerWithTicketData } from '../../../interfaces';
import { ModalTypesEnum } from '../../../util/enums';
import Timer from '../../Timer/Timer';

interface NoteModalProps {
    isConciergeNote?: boolean;
    totalCallTime: number;
}

const NoteModal: FC<NoteModalProps> = (props) => {
    const { isConciergeNote, totalCallTime } = props;

    const { customerData, setCustomerData } = useContext(SecurityQuestionContext);
    const { setActiveModal } = useContext(GlobalStateContext);

    const [text, setText] = useState('');

    const handleSaveNote = () => {
        const updatedCustomerData: ICustomerWithTicketData = JSON.parse(JSON.stringify(customerData));

        if (isConciergeNote) {
            updatedCustomerData.ticket.conciergeNote = text;
        }
        else {
            updatedCustomerData.ticket.customerNote = text;
        }

        setCustomerData(updatedCustomerData);

        setActiveModal(ModalTypesEnum.IN_CALL);
    }

    useEffect(() => {
        if (customerData) {
            if (isConciergeNote) {
                setText(customerData.ticket.conciergeNote);
            }
            else {
                setText(customerData.ticket.customerNote);
            }
        }
    }, [])

    return (
        <div className="note_modal__wrapper">
            <div className="note_modal__caller">
                <p>{customerData?.customer.userAttributes.firstName + ' ' + customerData?.customer.userAttributes.lastName}</p>
                {/* <span>Call duration: 02:10</span> */}
                <span>Call duration: <Timer time={totalCallTime} /></span>
            </div>
            <div className="note_modal__input">
                {
                    isConciergeNote
                    ? <p>Add my note (private)</p>
                    : <p>Add Customer's note (public)</p>
                }
                <textarea
                    value={text}
                    onChange={e => setText(e.target.value)}
                />
                <div className="note_modal__button_container">
                    <button
                        onClick={() => setActiveModal(ModalTypesEnum.IN_CALL)}
                    >
                        Cancel
                    </button>
                    <button
                        className="note_modal__save"
                        onClick={handleSaveNote}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="note_modal__close">
                <img
                    src="/assets/x.svg"
                    alt="x"
                    onClick={() => setActiveModal(ModalTypesEnum.IN_CALL)}
                />
            </div>
        </div>
    )
}

export default NoteModal
