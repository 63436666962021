import React, { useContext, useEffect, useState } from 'react'
import { IContactData, IContactMessageData, ISendMessageDTO } from '../../../interfaces';
import { AutocompleteTypesEnum, MessageTypesEnum } from '../../../util/enums';
import Autocomplete from "../../Autocomplete/Autocomplete";
import { SelectedContact } from '../..';
import { SecurityQuestionContext, SendMessageContext } from '../../../contexts';
import { doesCorrespondingMessageTypeAndDataExist } from '../../../util/functions';

const Message = () => {
    const [suggestions, setSuggestions] = useState<IContactData[]>([]);
    const [selected, setSelected] = useState<IContactMessageData[]>([]);
    const [messageType, setMessageType] = useState<MessageTypesEnum | undefined>(undefined);
    const [messageContent, setMessageContent] = useState('');

    const { customerData } = useContext(SecurityQuestionContext);
    const { sendEmailMessage, sendSMSMessage } = useContext(SendMessageContext);

    const handleRemove = (value: IContactMessageData) => {
        const selectedIndex = selected.map(item => item.id).indexOf(value.id);

        if (selectedIndex !== -1) {
            const selectedList: IContactMessageData[] = JSON.parse(JSON.stringify(selected));

            selectedList.splice(selectedIndex, 1);

            setSelected(selectedList);

            return;
        }
    }

    const generateDTO = (item: IContactMessageData) => {
        if (messageType === MessageTypesEnum.SMS) {
            const sendSMSDTO = {
                body: messageContent,
                customer: {
                    id: customerData?.customer.id
                },
                contact: {
                    id: item.id,
                    phoneId: item.phoneNumberId
                }
            } as ISendMessageDTO;

            return sendSMSDTO;
        }
        else {
            const sendEmailDTO = {
                body: messageContent,
                subject: "Subject",
                customer: {
                    id: customerData?.customer.id
                },
                contact: {
                    id: item.id,
                    emailId: item.emailId
                }
            } as ISendMessageDTO;

            return sendEmailDTO;
        }
    }

    const sendMessage = () => {
        const dto: ISendMessageDTO[] = [];

        for (let i = 0; i < selected.length; i++) {
            const item = selected[i];
            
            if (!doesCorrespondingMessageTypeAndDataExist(messageType, item)) {
                alert(`Contact ${item.firstName + ' ' + item.lastName} does not have an phone number/email associated with them.`);

                return;
            }

            dto.push(generateDTO(item));
        }

        if (messageType === MessageTypesEnum.SMS) {
            sendSMSMessage(dto)
                .then(response => {
                    console.log(response);
                    alert("SMS message was sent");
                })
                .catch(error => {
                    alert("There was an error sending the SMS.");
                });
            
            return;
        }
        
        sendEmailMessage(dto)
            .then(response => {
                console.log(response);
                alert("E-mail message was sent");
            })
            .catch(error => {
                alert("There was an error sending the e-mail.");
            });
    }

    const handleSetSelected = (value: IContactMessageData[]) => {
        setSelected(value);
    }

    const handleSetSuggestions = (value: IContactData[]) => {
        setSuggestions(value);
    }

    useEffect(() => {
        return () => {
            setMessageType(undefined);
        }
    }, []);

    return (
        <div className="message__wrapper">
            {
                messageType === undefined
                ? (
                    <div>
                        <button
                            onClick={() => setMessageType(MessageTypesEnum.SMS)}
                        >
                            <img src="/assets/message_bubble.svg" alt="" />
                            Send SMS
                        </button>
                        <button
                            onClick={() => setMessageType(MessageTypesEnum.EMAIL)}
                        >
                            <img src="/assets/message_bubble.svg" alt="" />
                            Send Email
                        </button>
                    </div>
                )
                : (
                    <div>
                        <div className="message__input_container" style={selected.length >= 2 ? {paddingTop: "10px"} : {}}>
                            {
                                selected.map(item => {
                                    return <SelectedContact key={item.id} data={item} handleRemove={handleRemove} messageType={messageType} />
                                })
                            }
                            <Autocomplete
                                selected={selected}
                                setSelected={handleSetSelected}
                                suggestions={suggestions}
                                setSuggestions={handleSetSuggestions}
                                messageType={messageType}
                                searchingType={AutocompleteTypesEnum.CONTACT}
                            />
                        </div>
                        <textarea
                            name="messageContent"
                            id="messageContent"
                            placeholder="Type a message. A maximum of 200 characters."
                            value={messageContent}
                            onChange={e => setMessageContent(e.target.value)}
                            style={selected.length === 0 ? { display: 'none' } : {}}
                        />
                        <button
                            disabled={selected.length === 0 || messageContent.length === 0}
                            onClick={sendMessage}
                        >
                            <img src="/assets/message_bubble.svg" alt="" />
                            Send Message
                        </button>
                        {/* {
                            showError
                            ? <span>{errorMessage}</span>
                            : null
                        } */}
                    </div>
                )
            }
        </div>
    )
}

export default Message
