import React, { useContext, useState } from 'react'
import { Autocomplete, SelectedContact } from '../..'
import { IContactData, IContactMessageData, ISendVoiceMessageDTO } from '../../../interfaces';
import { AutocompleteTypesEnum, MessageTypesEnum } from '../../../util/enums';
import RecordRTCPromisesHandler, { invokeSaveAsDialog } from 'recordrtc';
import { doesCorrespondingMessageTypeAndDataExist } from '../../../util/functions';
import { SecurityQuestionContext, SendMessageContext } from '../../../contexts';

const VoiceMessage = () => {
    const [suggestions, setSuggestions] = useState<IContactData[]>([]);
    const [selected, setSelected] = useState<IContactMessageData[]>([]);
    const [recording, setRecording] = useState<Blob | undefined>(undefined);
    const [isRecording, setIsRecording] = useState(false);
    const [base64String, setBase64String] = useState<string | ArrayBuffer | null>(null);

    const { customerData } = useContext(SecurityQuestionContext);
    const { sendVoiceMessage } = useContext(SendMessageContext);

    const handleSetSelected = (value: IContactMessageData[]) => {
        setSelected(value);
    }

    const handleSetSuggestions = (value: IContactData[]) => {
        setSuggestions(value);
    }

    const handleRemove = (value: IContactMessageData) => {
        const selectedIndex = selected.map(item => item.id).indexOf(value.id);

        if (selectedIndex !== -1) {
            const selectedList: IContactMessageData[] = JSON.parse(JSON.stringify(selected));

            selectedList.splice(selectedIndex, 1);

            setSelected(selectedList);

            return;
        }
    }

    const handleStartRecording = () => {
        navigator.mediaDevices.getDisplayMedia({
            audio: true,
            video: true
        })
        .then((stream) => {
            const recorder = new RecordRTCPromisesHandler(stream, {
                type: 'audio',
                mimeType: 'audio/webm',
            });

            stream.getAudioTracks()[0].addEventListener('ended', () => {
                recorder.stopRecording(() => {
                    const blob = recorder.getBlob();

                    setIsRecording(false);

                    setRecording(blob);

                    blobToBase64(blob);
                });
            });
            
            recorder.startRecording();

            setIsRecording(true);
        })
    }

    const handleDeleteRecording = () => {
        if (!isRecording) {
            setRecording(undefined);
        }
    }

    const handleSendVoiceMessage = () => {
        const dto = generateDTO();

        if (dto) {
            sendVoiceMessage(dto)
            .then(response => {
                alert('Successfully sent the voice message');
            });
        }

    }

    const generateDTO = () => {
        const dto: ISendVoiceMessageDTO[] = [];

        for (let i = 0; i < selected.length; i++) {
            const item = selected[i];
            
            if (!doesCorrespondingMessageTypeAndDataExist(MessageTypesEnum.EMAIL, item)) {
                alert(`Contact ${item.firstName + ' ' + item.lastName} does not have an phone number/email associated with them.`);

                return;
            }

            const sendVoiceMessageDTO = {
                customer: {
                    id: customerData?.customer.id
                },
                contact: {
                    id: item.id,
                    emailId: item.emailId
                },
                voice: base64String
            } as ISendVoiceMessageDTO;

            dto.push(sendVoiceMessageDTO);
        }

        return dto;
    }

    const blobToBase64 = (blob: Blob) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            let base64data = reader.result as string;

            console.log(base64data);

            base64data = base64data.replace("data:audio/webm;codecs=opus;", "data:audio/wav;");

            setBase64String(base64data);
        }

        reader.readAsDataURL(blob);
    }

    return (
        <div className="voice_message__wrapper">
            <div className="voice_message__search_container">
                {
                    selected.map(item => {
                        return <SelectedContact key={item.id} data={item} handleRemove={handleRemove} messageType={MessageTypesEnum.EMAIL} />
                    })
                }
                <Autocomplete
                    selected={selected}
                    setSelected={handleSetSelected}
                    suggestions={suggestions}
                    setSuggestions={handleSetSuggestions}
                    searchingType={AutocompleteTypesEnum.CONTACT}
                    messageType={MessageTypesEnum.EMAIL}
                />
            </div>
            <div className="voice_message__audio_container">
                {
                    !isRecording
                    ? <img className="voice_message__play" src="/assets/play.svg" alt="" onClick={handleStartRecording} />
                    : <img className="voice_message__stop" src="/assets/stop.svg" alt="" />
                }
                <img src="/assets/audio.svg" alt="" />
                <img className="voice_message__close" src="/assets/x.svg" alt="" onClick={handleDeleteRecording} />
            </div>
            <button
                disabled={isRecording || !recording}
                onClick={handleSendVoiceMessage}
            >
                <img src="/assets/microphone.svg" alt="" />
                Send to Contacts
            </button>
        </div>
    )
}

export default VoiceMessage
