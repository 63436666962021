import React, { createContext, FC, useContext, useState } from 'react'
import { IContactData, IToggleContactDeletedDTO } from '../interfaces';
import { AxiosContext } from './AxiosContext';

interface IContactContext {
    searchContactsByValue(customerId: number, value?: string): Promise<IContactData[]>;
    toggleContactIsDeleted(data: IToggleContactDeletedDTO): Promise<void>;
};

const ContactContext = createContext({} as IContactContext);

interface ContactContextProps {
    children: React.ReactNode;
}

const ContactContextProvider: FC<ContactContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const searchContactsByValue = (customerId: number, value?: string) => {
        let url = 'concierges/contacts/';

        if (value) {
            url += `?value=${value}`;
        }

        return axiosInstance({
            method: 'GET',
            url: url,
            headers: {
                'customerId': customerId
            }
        }).then((response: any) => {
            return response.data;
        });
    }

    const toggleContactIsDeleted = (data: IToggleContactDeletedDTO) => {
        return axiosInstance({
            method: 'PUT',
            url: 'contact/toogleIsDeletedConcierge',
            data
        }).then((response: any) => {
            console.log(response.data);
        });
    }

    const providerValue = {
        searchContactsByValue,
        toggleContactIsDeleted
    }

    return (
        <ContactContext.Provider value={providerValue}>
            {props.children}
        </ContactContext.Provider>
    )
}

export { ContactContext, ContactContextProvider }
