import React, { useContext, useEffect, useState } from 'react'
import { CustomerContext, GlobalStateContext, SecurityQuestionContext } from '../../../contexts';
import { IAddMinutesDTO, ICustomerWithTicketData } from '../../../interfaces';
import { AddMinutesTypeEnum, CreditCardFormActionEnum } from '../../../util/enums';
import Modal from "react-modal";
import { CreditCardNeededModal } from '../../Modals';
import CreditCardForm from '../../CreditCardForm/CreditCardForm';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import config from '../../../config/config.json';
import { Elements } from '@stripe/react-stripe-js';

const AddMinutes = () => {
    const [showTypeDropdown, setShowTypeDropdown] = useState(false);
    const [showMinutesDropdown, setShowMinutesDropdown] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [showCardForm, setShowCardForm] = useState(false);

    const [selectedType, setSelectedType] = useState<AddMinutesTypeEnum | undefined>(undefined);
    const [selectedMinutes, setSelectedMinutes] = useState(0);
    const [selectedCard, setSelectedCard] = useState<string | undefined>(undefined);

    const [total, setTotal] = useState(0);
    const [timesAdded, setTimesAdded] = useState(0);

    const [stripe, setStripe] = useState<Stripe | null>(null);

    const { customerData, setCustomerData } = useContext(SecurityQuestionContext);
    const { showCreditCardModal, setShowCreditCardModal } = useContext(GlobalStateContext);
    const { addMinutesToCustomer, getCustomerById } = useContext(CustomerContext);

    const updateCustomerData = () => {
        const updatedCustomerData: ICustomerWithTicketData = JSON.parse(JSON.stringify(customerData));

        if (customerData) {
            getCustomerById(customerData?.customer.id)
            .then(response => {
                updatedCustomerData.customer = response;

                setCustomerData(updatedCustomerData);
            })
        }
    }

    const resetData = () => {
        setShowCardForm(false);
        setShowPayment(false);
        setSelectedCard(undefined);
        setSelectedType(undefined);
        setSelectedMinutes(0);
        setTotal(0);
    }

    const handleSelectType = (type: AddMinutesTypeEnum) => {
        setSelectedType(type);
        setShowTypeDropdown(false);

        if (type && selectedMinutes) {
            const subscriptionField = type === AddMinutesTypeEnum.CALL_TO_CONTACT
                ? 'priceOfAdditionalCallToCont'
                : 'priceOfAdditionalConciergeS'
            
            if (customerData?.customer.subscription) {
                setTotal(customerData?.customer.subscription?.subscriptionAttributes[subscriptionField] * selectedMinutes);
            }
        }
    }

    const handleSelectMinutes = (minutes: number) => {
        setSelectedMinutes(minutes);
        setShowMinutesDropdown(false);

        if (minutes && selectedType) {
            const subscriptionField = selectedType === AddMinutesTypeEnum.CALL_TO_CONTACT
                ? 'priceOfAdditionalCallToCont'
                : 'priceOfAdditionalConciergeS'
            
            if (customerData?.customer.subscription) {
                setTotal(customerData?.customer.subscription?.subscriptionAttributes[subscriptionField] * minutes);
            }
        }
    }

    const handleShowType = (state: boolean) => {
        setShowMinutesDropdown(false);
        setShowTypeDropdown(state);
    }

    const handleShowMinutes = (state: boolean) => {
        setShowMinutesDropdown(state);
        setShowTypeDropdown(false);
    }

    const handleSubmitNext = () => {
        setShowPayment(true);
    }

    const handleSubmitPay = () => {
        const dto = {
            customerId: customerData?.customer.id,
        } as IAddMinutesDTO;

        if (selectedType === AddMinutesTypeEnum.CALL_TO_CONTACT) {
            dto.numOfMinsPerCallToContact = selectedMinutes;
        }
        else {
            dto.numOfMinsPerSupportCall = selectedMinutes;
        }

        addMinutesToCustomer(dto)
        .then(response => {
            updateCustomerData();

            alert('Minutes successfully added to the customer.');

            setTimesAdded(timesAdded + 1);
            
            resetData();
        })
        .catch(error => {
            alert('There was an error adding minutes to the customer.');
        })
    }

    const handleSelectCard = (cardId: string) => {
        setSelectedCard(cardId);
    }

    const handleAddCard = () => {
        setShowCreditCardModal(true);
    }

    const renderTimesAdded = () => {
        switch (timesAdded) {
            case 0:
                return null;
            case 1:
                return <span>Minutes added: once</span>
            case 2:
                return <span>Minutes added: twice</span>
            default:
                return <span>Minutes added: {timesAdded} times</span>
        }
    }

    const renderAddMinutesSelection = () => {
        return <div className="add_minutes__wrapper">
            <div className="add_minutes__minutes_added">
                {renderTimesAdded()}
            </div>
            <div className="add_minutes__content">
                <div
                    className="add_minutes__selected"
                    tabIndex={0}
                    onClick={() => handleShowType(!showTypeDropdown)}
                >
                    {
                        selectedType
                        ? <span>{selectedType}</span>
                        : <span>Select type</span>
                    }
                    <img src="/assets/analytic_arrow.svg" alt="v" />
                </div>
                {
                    showTypeDropdown && (<div
                        className="add_minutes__dropdown"
                        tabIndex={9}
                        onClick={() => handleShowType(!showTypeDropdown)}
                        onBlur={() => setShowTypeDropdown(false)}
                    >
                        <div className="add_minutes__dropdown__selected_type">
                            {
                                selectedType
                                ? <span>{selectedType}</span>
                                : <span>Select type</span>
                            }
                            <img src="/assets/analytic_arrow.svg" alt="v" />
                        </div>
                        <div className="add_minutes__dropdown_divider"></div>
                        <div
                            className="add_minutes__dropdown_item"
                            onClick={() => handleSelectType(AddMinutesTypeEnum.CALL_TO_CONTACT)}
                        >
                            <span>{AddMinutesTypeEnum.CALL_TO_CONTACT}</span>
                        </div>
                        <div
                            className="add_minutes__dropdown_item"
                            onClick={() => handleSelectType(AddMinutesTypeEnum.SUPPORT_CALL)}
                        >
                            <span>{AddMinutesTypeEnum.SUPPORT_CALL}</span>
                        </div>
                    </div>)
                }
                <div
                    className="add_minutes__selected"
                    tabIndex={0}
                    onClick={() => handleShowMinutes(!showMinutesDropdown)}
                >
                    {
                        selectedMinutes > 0
                        ? <span>{selectedMinutes} {selectedMinutes === 1 ? 'minute' : 'minutes'}</span>
                        : <span>Select minutes</span>
                    }
                    <img src="/assets/analytic_arrow.svg" alt="v" />
                </div>
                {
                    showMinutesDropdown && (<div
                        className="add_minutes__dropdown__minutes"
                        tabIndex={9}
                        onClick={() => handleShowMinutes(!showMinutesDropdown)}
                        onBlur={() => setShowMinutesDropdown(false)}
                    >
                        <div className="add_minutes__dropdown__selected_minutes">
                            {
                                selectedMinutes > 0
                                ? <span>{selectedMinutes} {selectedMinutes === 1 ? 'minute' : 'minutes'}</span>
                                : <span>Select minutes</span>
                            }
                            <img src="/assets/analytic_arrow.svg" alt="v" />
                        </div>
                        <div className="add_minutes__dropdown_divider"></div>
                        <div
                            className="add_minutes__dropdown_item"
                            onClick={() => handleSelectMinutes(1)}
                        >
                            <span>1 minute</span>
                        </div>
                        <div
                            className="add_minutes__dropdown_item"
                            onClick={() => handleSelectMinutes(5)}
                        >
                            <span>5 minutes</span>
                        </div>
                        <div
                            className="add_minutes__dropdown_item"
                            onClick={() => handleSelectMinutes(10)}
                        >
                            <span>10 minutes</span>
                        </div>
                        <div
                            className="add_minutes__dropdown_item"
                            onClick={() => handleSelectMinutes(15)}
                        >
                            <span>15 minutes</span>
                        </div>
                    </div>)
                }
                <div className="add_minutes__total_container">
                    <p>Total: <b>${total}</b></p>
                </div>
                <button
                    disabled={!selectedType || !selectedMinutes}
                    onClick={handleSubmitNext}
                >
                    Add minutes
                </button>
            </div>
            <div className="add_minutes__divider"></div>
        </div>
    }

    const renderCards = () => {
        return customerData?.customer.creditCards.map(card => {
            return <div
                className={
                    card.id === selectedCard
                    ? "add_minutes__payment__card add_minutes__payment__card_selected"
                    : "add_minutes__payment__card"
                }
                key={card.id}
                onClick={() => handleSelectCard(card.id)}
            >
                <div className="add_minutes__payment__card_info">
                    <img
                        src={
                            card.brand === 'visa'
                            ? "/assets/visa.svg"
                            : "/assets/mastercard.svg"
                        }
                        alt=""
                    />
                    <p>**** **** **** {card.last4}</p>
                    <div className="add_minutes__payment__holder_info__container">
                        <div className="add_minutes__payment__holder_info">
                            <span>Card Holder</span>
                            <span>{customerData.customer.userAttributes.firstName + ' ' + customerData.customer.userAttributes.lastName}</span>
                        </div>
                        <div className="add_minutes__payment__holder_info">
                            <span>Expires</span>
                            <span>{card.exp_month}/{card.exp_year}</span>
                        </div>
                    </div>
                </div>
                <button>
                    Edit
                </button>
            </div>
        });
    }

    const renderPayment = () => {
        return <div className="add_minutes__payment_wrapper">
            <div className="add_minutes__payment_info">
                <div className="add_minutes__payment_info__back">
                    <img
                        src="/assets/back_arrow.svg"
                        alt="<"
                        onClick={() => setShowPayment(false)}
                    />
                </div>
                <div className="add_minutes__payment_info__data">
                    <h2>Select Payment type</h2>
                    <span>Type: {selectedType}</span>
                    <span>Add: {selectedMinutes} {selectedMinutes === 1 ? 'minute' : 'minutes'}</span>
                    <p>Total: <b>${total}</b></p>
                </div>
                <div className="add_minutes__payment_info__divider"></div>
            </div>
            <div className="add_minutes__payment_cards">
                {renderCards()}
                <div
                    className="add_minutes__payment__add_card"
                    onClick={handleAddCard}
                >
                    <img src="/assets/plus.svg" alt="+" />
                    <span>Add Card</span>
                </div>
            </div>
            <button
                onClick={handleSubmitPay}
            >
                Pay
            </button>
        </div>
    }

    const renderCardForm = () => {
        return <Elements stripe={stripe}>
            <CreditCardForm
                creditCardAction={CreditCardFormActionEnum.ADD_CARD}
                setShowCreditCard={setShowCardForm}
            />
        </Elements>
    }

    const renderContent = () => {
        if (showCardForm) {
            return renderCardForm();
        }

        if (showPayment) {
            return renderPayment();
        }

        return renderAddMinutesSelection();
    }

    useEffect(() => {
        loadStripe(config.stripeKey)
        .then((value) => {
            setStripe(value);
        });
    }, []);

    return (
        <div className="add_minute__container">
            {renderContent()}
            <Modal
                isOpen={showCreditCardModal}
                onRequestClose={() => setShowCreditCardModal(false)}
                className="creditcard_needed__modal"
                ariaHideApp={false}
                overlayClassName="modal__overlay"
            >
                <CreditCardNeededModal
                    setShowCreditCard={setShowCardForm}
                />
            </Modal>
        </div>
    )
}

export default AddMinutes
