export enum DashboardTextEnum {
    ANALYTICS = "Analytics",
    TICKET_MANAGEMENT = "Ticket Management",
    SUBSCRIPTION_PLAN = "Subscription Plan",
    EMPLOYEES = "Employees",
}

export enum DashboardLinkEnum {
    ANALYTICS = "/analytics",
    TICKET_MANAGEMENT = "/ticket-management",
    SUBSCRIPTION_PLAN = "/subscription-plan",
    EMPLOYEES = "/employees",
    CREATE_EMPLOYEES = "/create-employees",
    EDIT_PROFILE = "/edit-profile"
}