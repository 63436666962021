import React, { FC, useContext } from 'react'
import { ConciergeContext } from '../../../../contexts';
import { ConciergeBackendRoleEnum } from '../../../../util/enums';

interface DropdownMenuProps {
    showEditModalHandler(state: boolean): void;
    showDeleteModalHandler(state: boolean): void;
}

const DropdownMenu: FC<DropdownMenuProps> = (props) => {
    const { showEditModalHandler, showDeleteModalHandler } = props;

    const { conciergeData } = useContext(ConciergeContext);

    return (
        <div className="user_card__menu__dropdown">
            <div
                className={
                    conciergeData?.role === ConciergeBackendRoleEnum.Administrator
                    ? "user_card__menu__dropdown_item user_card__menu__dropdown_item_border"
                    : "user_card__menu__dropdown_item"
                }
                onClick={() => showEditModalHandler(true)}
            >
                <img src="/assets/edit.svg" alt="" />
                <p>Edit</p>
            </div>
            {
                conciergeData?.role === ConciergeBackendRoleEnum.Administrator
                ? <div className="user_card__menu__dropdown_item" onClick={() => showDeleteModalHandler(true)}>
                    <img src="/assets/delete.svg" alt="" />
                    <p>Delete</p>
                </div>
                : null
            }
        </div>
    )
}

export default DropdownMenu
