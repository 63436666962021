import React, { useContext, useEffect, useState } from 'react'
import { AnalyticsContext, AuthenticationContext } from '../../contexts';
import { IAnalyticsData } from '../../interfaces';
import { ANALYTIC_COMPONENTS_INFO } from '../../util/constants';
import { AnalyticsTimeframesEnum } from '../../util/enums'
import { getPropertyFromAnalyticTitle } from '../../util/functions';
import AnalyticComponent from './components/AnalyticComponent/AnalyticComponent'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from 'luxon';
import { getDataForAnalytic } from '../../util/functions/AnalyticsPropertyHelper';

const AnalyticsPage = () => {
    const [selectedTimeframe, setSelectedTimeframe] = useState(AnalyticsTimeframesEnum.TODAY);
    const [startDate, setStartDate] = useState<DateTime | null>(null);
    const [endDate, setEndDate] = useState<DateTime | null>(null);
    const [analyticsData, setAnalyticsData] = useState<IAnalyticsData[]>([]);

    const { getAnalytics } = useContext(AnalyticsContext);
    const { token } = useContext(AuthenticationContext);

    const handleSelectTimeframe = (value: string) => {
        const timeframe = parseInt(value) as AnalyticsTimeframesEnum;

        setSelectedTimeframe(timeframe);

        setStartDate(null);
        setEndDate(null);
    }

    const handleSetStartDate = (value: Date | null) => {
        if (value) {
            setStartDate(DateTime.fromJSDate(value));
        }

        if (endDate) {
            setSelectedTimeframe(AnalyticsTimeframesEnum.FIXED_TIMEFRAME);
        }
    }

    const handleSetEndDate = (value: Date | null) => {
        if (value) {
            setEndDate(DateTime.fromJSDate(value));
        }

        if (startDate) {
            setSelectedTimeframe(AnalyticsTimeframesEnum.FIXED_TIMEFRAME);
        }
    }

    const timeframeToDisplay = (timeframe: AnalyticsTimeframesEnum) => {
        switch (timeframe) {
            case AnalyticsTimeframesEnum.TODAY:
                return 'Today';
            case AnalyticsTimeframesEnum.LAST_WEEK:
                return 'Last week';
            case AnalyticsTimeframesEnum.LAST_MONTH:
                return 'Last month';
            case AnalyticsTimeframesEnum.LAST_YEAR:
                return 'Last year';
            default:
                return 'Custom';
        }
    }

    const renderTimeframes = () => {
        return Object.keys(AnalyticsTimeframesEnum).map((key) => {
            if (key > '4' && key !== "FIXED_TIMEFRAME") {
                const value = AnalyticsTimeframesEnum[key as keyof typeof AnalyticsTimeframesEnum];

                return <div className="analytics__timeframe__container" key={key}>
                    <label
                        className="analytics__timeframe"
                        htmlFor={key}
                    >
                        <p>{timeframeToDisplay(value)}</p>
                        <input
                            type="radio"
                            id={key}
                            value={value}
                            onChange={e => handleSelectTimeframe(e.target.value)}
                            checked={value === selectedTimeframe}
                        />
                        <span className="analytics__timeframe_checkmark"></span>
                    </label>
                </div>
            }

            return null;
        });
    }

    const renderAnalyticComponents = () => {
        return ANALYTIC_COMPONENTS_INFO.map(info => {
            if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_MONTH) {
                const lastMonth = DateTime.now().minus({month: 1});
    
                return <AnalyticComponent
                    key={info.title}
                    timeframe={selectedTimeframe}
                    info={info}
                    data={analyticsData}
                    startDate={lastMonth.startOf('month')}
                    endDate={lastMonth.endOf('month')}
                />
            }

            return <AnalyticComponent
                key={info.title}
                timeframe={selectedTimeframe}
                info={info}
                data={analyticsData}
                startDate={startDate}
                endDate={endDate}
            />
        })
    }

    const handleGetAnalytics = (startDate: DateTime, endDate: DateTime) => {
        if (token) {
            getAnalytics(startDate.toMillis(), endDate.toMillis())
            .then(response => {
                setAnalyticsData(response);
            })
        }
    }

    useEffect(() => {
        const today = DateTime.now();

        if (selectedTimeframe === AnalyticsTimeframesEnum.TODAY) {
            handleGetAnalytics(today, today);
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_WEEK) {
            const lastWeek = today.minus({weeks: 1});

            handleGetAnalytics(lastWeek.startOf('week'), lastWeek.endOf('week'));
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_MONTH) {
            const lastMonth = today.minus({month: 1});

            handleGetAnalytics(lastMonth.startOf('month'), lastMonth.endOf('month'));
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.LAST_YEAR) {
            const lastYear = today.minus({year: 1});

            handleGetAnalytics(lastYear.startOf('year'), lastYear.endOf('year'));
        }

        if (selectedTimeframe === AnalyticsTimeframesEnum.FIXED_TIMEFRAME
            && startDate
            && endDate) {
            handleGetAnalytics(startDate, endDate);
        }
    }, [selectedTimeframe]);

    useEffect(() => {
        const today = DateTime.now();

        handleGetAnalytics(today, today);
    }, []);

    return (
        <div className="analytics__wrapper">
            <div className="analytics__search__container">
                <img src="/assets/search.svg" alt="" />
                <input
                    type="text"
                    placeholder="Search Employee"
                />
                <select>
                    <option value="0">Select Pool</option>
                    <option value="1">First pool</option>
                    <option value="2">Second pool</option>
                    <option value="3">Third pool</option>
                </select>
            </div>
            <div className="analytics__timeframes__container">
                {renderTimeframes()}
                <div className="analytic__custom_timeframe__container">
                    {
                        startDate
                        ? <p><b>{startDate.toFormat('dd. LLL yyyy.')}</b></p>
                        // ? <p><b>{formatDate(startDate)}</b></p>
                        : <p>Start Date</p>
                    }
                    <img src="/assets/gray_arrow.svg" alt="v" />
                    <DatePicker
                        selected={startDate?.toJSDate()}
                        onChange={handleSetStartDate}
                    />
                </div>
                <div className="analytic__custom_timeframe__container">
                    {
                        endDate
                        ? <p><b>{endDate.toFormat('dd. LLL yyyy.')}</b></p>
                        // ? <p><b>{formatDate(endDate)}</b></p>
                        : <p>End Date</p>
                    }
                    <img src="/assets/gray_arrow.svg" alt="v" />
                    <DatePicker
                        selected={endDate?.toJSDate()}
                        onChange={handleSetEndDate}
                    />
                </div>
            </div>
            <hr />
            <div className="analytics__container">
                {renderAnalyticComponents()}
            </div>
        </div>
    )
}

export default AnalyticsPage
