import React, { FC } from 'react'

interface TimerProps {
    time: number;
}

const Timer: FC<TimerProps> = (props) => {
    const { time } = props;

    const seconds = time % 60;
    const minutes = Math.floor(time / 60);

    const renderNumber = (value: number) => {
        if (value > 9) {
            return value;
        }

        return '0' + value;
    }

    return (
        <span>
            {renderNumber(minutes) + ':' + renderNumber(seconds)}
        </span>
    )
}

export default Timer
