import React, { FC, useContext, useState } from 'react'
import { useHistory } from 'react-router';
import { AmazonConnectContext, ConciergeContext, GlobalStateContext } from '../../contexts';
import { ConciergeBackendStatusEnum, DashboardLinkEnum, PagesEnum } from '../../util/enums';
import { getAgentStateFromConciergeStatus } from '../../util/functions';

const MainHeader: FC = () => {
    const [showMenu, setShowMenu] = useState(false);

    const { conciergeData, updateConciergeStatus, getCurrentConcierge } = useContext(ConciergeContext);
    const { agent } = useContext(AmazonConnectContext);
    const { logout } = useContext(AmazonConnectContext);
    const {
        activePage,
        setActivePage,
        setPreviousActivePage,
        previousPageLink,
        setPreviousPageLink,
        pageLink,
        setPageLink
    } = useContext(GlobalStateContext);

    const history = useHistory();

    const renderConciergeName = () => {
        if (conciergeData) {
            return conciergeData.conciergeUser.firstName + ' ' + conciergeData.conciergeUser.lastName[0] + '.'
        }

        return '';
    }

    const toggleShow = () => {
        setShowMenu(!showMenu);
    }

    const hide = (e: any) => {
        setShowMenu(false);
    }

    const handleLogout = () => {
        if (conciergeData) {
            updateConciergeStatus(conciergeData.id, ConciergeBackendStatusEnum.Offline)
            .then(response => {
                // const agentState = getAgentStateFromConciergeStatus(ConciergeBackendStatusEnum.Offline);

                // if (agentState) {
                //     agent?.setState(agentState);
                // }
                
                logout();
            })
        }
    }

    const handleEditProfile = () => {
        setPreviousActivePage(activePage);
        setPreviousPageLink(pageLink);
        
        setActivePage(PagesEnum.EDIT_PROFILE);
        setPageLink(DashboardLinkEnum.EDIT_PROFILE);

        history.push(DashboardLinkEnum.EDIT_PROFILE);
    }

    const handleStatusChange = (value: string) => {
        const status = parseInt(value);

        if (conciergeData) {
            updateConciergeStatus(conciergeData.id, status)
            .then(response => {
                const agentState = getAgentStateFromConciergeStatus(status, agent);
                
                if (agentState) {
                    agent?.setState(agentState);
                }

                getCurrentConcierge();
            })
        }
    }

    // const getAgentStateFromConciergeStatus = (status: ConciergeBackendStatusEnum) => {
    //     if (agent) {
    //         const agentStates = agent.getAgentStates();

    //         switch (status) {
    //             case ConciergeBackendStatusEnum.NotRoutable:
    //                 return agentStates.find(state => state.name === 'Occupied')
    //             case ConciergeBackendStatusEnum.Routable:
    //                 return agentStates.find(state => state.name === 'Available')
    //             default:
    //                 return agentStates.find(state => state.name === 'Offline')
    //         }
    //     }
    // }

    return (
        <div className="header">
            <div className="header__left">
                <div className="header_title">
                    <p>{activePage}</p>
                </div>
            </div>
            <div className="header__right">
                <div className="header_status">
                    {/* <p>Online</p>
                    <img src="/assets/arrow.svg" alt="" /> */}
                    <select
                        value={conciergeData?.status}
                        onChange={e => handleStatusChange(e.target.value)}
                        className={`select_status__${conciergeData?.status}`}
                    >
                        <option value="1">Online</option>
                        <option value="2">Occupied</option>
                        <option value="3">Offline</option>
                    </select>
                </div>
                <div className="header_divider"></div>
                <div
                    className="header_user"
                    tabIndex={0}
                    onClick={toggleShow}
                    onBlur={hide}
                >
                    {/* <p>Jonathan S.</p> */}
                    <p>{renderConciergeName()}</p>
                    <img src="/assets/gray_arrow.svg" alt="" />
                    {
                        showMenu && ( 
                            <div className="header__dropdown_menu">
                                <div
                                    className="header__dropdown_menu__item"
                                    onClick={handleEditProfile}
                                >
                                    <p>Edit profile</p>
                                </div>
                                <hr />
                                <div
                                    className="header__dropdown_menu__item"
                                    onClick={handleLogout}
                                >
                                    <p>Log out</p>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="header_profile_image">
                    <img src="/assets/profile_letters.svg" alt="JS" />
                </div>
            </div>
        </div>
    )
}

export default MainHeader
