import React, { FC, useContext, useEffect, useState } from 'react'
import { AmazonConnectContext, ContactCallTimerContext, SecurityQuestionContext } from '../../../contexts';
import { IContactData, IContactMessageData } from '../../../interfaces';
import { AutocompleteTypesEnum } from '../../../util/enums';
import Autocomplete from '../../Autocomplete/Autocomplete';
import SelectedContact from '../../SelectedContact/SelectedContact';
import Timer from '../../Timer/Timer';

interface CallProps {
    contactCallTime: number;
    setIsContactCallTimeOn(state: boolean): void;
}

const Call: FC<CallProps> = (props) => {
    const { contactCallTime, setIsContactCallTimeOn } = props;

    const [selected, setSelected] = useState<IContactMessageData[]>([]);
    const [suggestions, setSuggestions] = useState<IContactData[]>([]);
    const [isCalled, setIsCalled] = useState(false);
    const [isInCall, setIsInCall] = useState(false);
    const [isJoined, setIsJoined] = useState(false);

    const { agent, connection, contact } = useContext(AmazonConnectContext);
    const { customerData } = useContext(SecurityQuestionContext);
    // const { contactCallTime, startContactCallTimer, resetContactCallTimer } = useContext(ContactCallTimerContext);

    const handleRemove = (value: IContactMessageData) => {
        const selectedIndex = selected.map(item => item.id).indexOf(value.id);

        if (selectedIndex === -1) return;

        const selectedList: IContactMessageData[] = JSON.parse(JSON.stringify(selected));

        selectedList.splice(selectedIndex, 1);

        setSelected(selectedList);
    }

    const handleCall = () => {
        if (contact && selected[0].phoneNumber) {
            const endpoint = connect.Endpoint.byPhoneNumber(selected[0].phoneNumber);

            contact.addConnection(endpoint, {
                success: () => {
                    setIsCalled(true);

                    alert('Contact called successfully.');
                },
                failure: error => {
                    console.log(error);
                }
            })
        }
    }

    const handleConference = () => {
        const calledThirdPartyConnection = contact?.getSingleActiveThirdPartyConnection();

        if (calledThirdPartyConnection?.getState().type === connect.ConnectionStateType.CONNECTED) {
            contact?.conferenceConnections({
                success: () => {
                    setIsInCall(true);
                    setIsJoined(true);
    
                    if (customerData?.customer.subscription) {
                        setIsContactCallTimeOn(true);
                    }
                },
                failure: (error) => {
                    console.log(error);
                }
            });
        }
    }

    const handleResumeCall = () => {
        const calledThirdPartyConnection = contact?.getSingleActiveThirdPartyConnection();

        if (!calledThirdPartyConnection) {
            const thirdPartyConnection = contact?.getThirdPartyConnections()[0];

            thirdPartyConnection?.destroy({
                success: () => {
                    contact?.getAgentConnection().resume();
                }
            });
        }
    }

    const handleLeaveCall = () => {
        agent?.mute();

        setIsJoined(false);
    }

    const handleJoinCall = () => {
        agent?.unmute();

        setIsJoined(true);
    }

    const handleEndCall = () => {
        const thirdPartyConnection = contact?.getSingleActiveThirdPartyConnection();

        thirdPartyConnection?.destroy({
            success: () => {
                setIsContactCallTimeOn(false);

                setIsCalled(false);
            },
            failure: error => {
                console.log(error);
            }
        });
    }

    const renderJoinLeaveButton = () => {
        if (isJoined) {
            return (
                <button
                    className="call__leave_call_button"
                    onClick={handleLeaveCall}
                >
                    Leave Call
                </button>
            );
        }

        return (
            <button
                className="call__join_call_button"
                onClick={handleJoinCall}
            >
                Join Call
            </button>
        );
    }

    const handleSetSelected = (value: IContactMessageData[]) => {
        setSelected(value);
    }

    const handleSetSuggestions = (value: IContactData[]) => {
        setSuggestions(value);
    }

    useEffect(() => {
        return () => {
            setIsCalled(false);
        }
    }, []);

    return (
        <div className="call__wrapper">
            <div className="call__contact_container">
                <div className="call__divider"></div>
                <div className="call__input_container">
                    {
                        selected.length === 1
                        ? <SelectedContact
                            key={selected[0].id}
                            data={selected[0]}
                            handleRemove={handleRemove}
                        />
                        : <Autocomplete
                            selected={selected}
                            setSelected={handleSetSelected}
                            suggestions={suggestions}
                            setSuggestions={handleSetSuggestions}
                            searchingType={AutocompleteTypesEnum.CONTACT}
                        />
                    }
                </div>
                {
                    isInCall
                    ? <div className="call__timer">
                        <span>In call - <Timer time={contactCallTime} /></span>
                    </div>
                    : <div className="call__divider"></div>
                }
            </div>
            {
                !isInCall
                ? <div>
                    <button
                        className="call__call_button"
                        disabled={selected.length === 0 || isCalled}
                        onClick={handleCall}
                    >
                        <img src="/assets/call.svg" alt="" />
                        Call
                    </button>
                    <div className="call__in_call_button_container">
                        <button
                            className="call__call_button"
                            onClick={handleConference}
                        >
                            Conference
                        </button>
                        <button
                            className="call__call_button"
                            onClick={handleResumeCall}
                        >
                            Resume call
                        </button>
                    </div>
                </div>
                : <div className="call__in_call_button_container">
                    {renderJoinLeaveButton()}
                    <button
                        className="call__end_call_button"
                        onClick={handleEndCall}
                    >
                        End Call
                    </button>
                </div>
            }
        </div>
    )
}

export default Call
