import React, { createContext, FC, useContext } from 'react'
import { IAddCardDTO } from '../interfaces';
import { AxiosContext } from './AxiosContext';

interface ICreditCardContext {
    addDefaultCard(data: IAddCardDTO): Promise<void>;
};

const CreditCardContext = createContext({} as ICreditCardContext);

interface CreditCardContextProps {
    children: React.ReactNode;
}

const CreditCardContextProvider: FC<CreditCardContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const addDefaultCard = (data: IAddCardDTO) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierges/customers/changeDefaultCard',
            data
        }).then((response: any) => {
            console.log(response.data);
            return response.data;
        }).catch((error) => {
            console.log(error);
            throw error;
        });
    }

    const providerValue = {
        addDefaultCard
    }

    return (
        <CreditCardContext.Provider value={providerValue}>
            {props.children}
        </CreditCardContext.Provider>
    )
}

export { CreditCardContext, CreditCardContextProvider }
