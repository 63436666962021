import React, { createContext, FC, useContext } from 'react'
import { ISendMessageDTO, ISendVoiceMessageDTO } from '../interfaces'
import { AxiosContext } from './AxiosContext';

interface ISendMessageContext {
    sendSMSMessage(data: ISendMessageDTO[]): Promise<void>;
    sendEmailMessage(data: ISendMessageDTO[]): Promise<void>;
    sendVoiceMessage(data: ISendVoiceMessageDTO[]): Promise<void>;
};

const SendMessageContext = createContext({} as ISendMessageContext);

interface SendMessageContextProps {
    children: React.ReactNode;
}

const SendMessageContextProvider: FC<SendMessageContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const sendSMSMessage = (data: ISendMessageDTO[]) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierges/sendSMSsToContacts',
            data: data
        }).then((response: any) => {
            return response.data;
        });
    }

    const sendEmailMessage = (data: ISendMessageDTO[]) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierges/sendEmailsToContacts',
            data: data
        }).then((response: any) => {
            return response.data;
        });
    }

    const sendVoiceMessage = (data: ISendVoiceMessageDTO[]) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierges/sendVoicesToContacts',
            data: data
        }).then((response: any) => {
            return response.data;
        });
    }

    const providerValue = {
        sendSMSMessage,
        sendEmailMessage,
        sendVoiceMessage
    }

    return (
        <SendMessageContext.Provider value={providerValue}>
            {props.children}
        </SendMessageContext.Provider>
    )
}

export { SendMessageContext, SendMessageContextProvider }
