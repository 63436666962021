import React, { createContext, FC, useContext, useState } from 'react'
import { ICancelSubscriptionDTO, ISubscriptionData, IToggleSubscriptionActiveDTO, IUpgradeSubscriptionDTO } from '../interfaces';
import { AxiosContext } from './AxiosContext';

interface ISubscriptionPlanContext {
    subscriptionPlans: ISubscriptionData[] | undefined;
    setSubscriptionPlans(plans: ISubscriptionData[] | undefined): void;
    getSubscriptionPlans(): Promise<ISubscriptionData[]>;
    getActiveSubscriptionPlans(): Promise<ISubscriptionData[]>;
    addSubscriptionPlan(data: ISubscriptionData): Promise<void>;
    editSubscriptionPlan(data: ISubscriptionData): Promise<void>;
    toggleSubscriptionPlanActiveStatus(id: number, data: IToggleSubscriptionActiveDTO): Promise<void>;
    cancelSubscriptionPlan(data: ICancelSubscriptionDTO): Promise<void>;
    upgradeSubscriptionPlan(data: IUpgradeSubscriptionDTO): Promise<void>;
};

const SubscriptionPlanContext = createContext({} as ISubscriptionPlanContext);

interface SubscriptionPlanContextProps {
    children: React.ReactNode;
}

const SubscriptionPlanContextProvider: FC<SubscriptionPlanContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const [subscriptionPlans, setSubscriptionPlans] = useState<ISubscriptionData[] | undefined>(undefined);

    const getSubscriptionPlans = () => {
        return axiosInstance({
            method: 'GET',
            url: 'concierges/subscription/getAll'
        }).then((response: any) => {
            setSubscriptionPlans(response.data);
            return response.data;
        });
    }

    const getActiveSubscriptionPlans = () => {
        return axiosInstance({
            method: 'GET',
            url: 'subscription/getAll'
        }).then((response: any) => {
            setSubscriptionPlans(response.data);
            return response.data;
        });
    }

    const cancelSubscriptionPlan = (data: ICancelSubscriptionDTO) => {
        return axiosInstance({
            method: 'POST',
            url: 'subscription/cancel',
            data
        }).then((response: any) => {
            return response.data;
        });
    }

    const upgradeSubscriptionPlan = (data: IUpgradeSubscriptionDTO) => {
        return axiosInstance({
            method: 'PUT',
            url: 'concierge/subscription/upgradeCustomerSubscription',
            data
        }).then((response: any) => {
            if ((response.data as string).includes('Unable')) {
                throw new Error(response.data);
            }
            return response.data;
        }).catch((error) => {
            alert(error.message);
            throw error;
        });
    }

    const addSubscriptionPlan = (data: ISubscriptionData) => {
        return axiosInstance({
            method: 'POST',
            url: 'subscription/create',
            data
        }).then((response: any) => {
            console.log(response.data);
        }).catch((error) => {
            throw error;
        });
    }

    const editSubscriptionPlan = (data: ISubscriptionData) => {
        return axiosInstance({
            method: 'PUT',
            url: 'subscription/edit',
            data
        }).then((response: any) => {
            console.log(response.data);
        }).catch((error) => {
            throw error;
        });
    }

    const toggleSubscriptionPlanActiveStatus = (id: number, data: IToggleSubscriptionActiveDTO) => {
        return axiosInstance({
            method: 'PUT',
            url: `subscription/makeActiveInactive/${id}`,
            data
        }).then((response: any) => {
            console.log(response.data);
        }).catch((error) => {
            throw error;
        });
    }

    const providerValue = {
        subscriptionPlans,
        setSubscriptionPlans,
        getSubscriptionPlans,
        getActiveSubscriptionPlans,
        cancelSubscriptionPlan,
        upgradeSubscriptionPlan,
        addSubscriptionPlan,
        editSubscriptionPlan,
        toggleSubscriptionPlanActiveStatus
    }

    return (
        <SubscriptionPlanContext.Provider value={providerValue}>
            {props.children}
        </SubscriptionPlanContext.Provider>
    )
}

export { SubscriptionPlanContext, SubscriptionPlanContextProvider }
