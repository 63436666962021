import React, { useContext, useEffect, useState } from 'react'
import { AuthenticationContext, ConciergeContext } from '../../contexts';
import useDebounce from '../../hooks/useDebounce';
import { IConciergeData } from '../../interfaces';
import { ConciergeBackendStatusEnum } from '../../util/enums'
import { EmployeeCard } from './components'

const EmployeesPage = () => {
    const [concierges, setConcierges] = useState<IConciergeData[] | undefined>(undefined);
    const [filterRole, setFilterRole] = useState("-1");
    const [search, setSearch] = useState({
        query: "",
    });

    const { token } = useContext(AuthenticationContext);
    const { getConcierges, getConciergesByRole, getConciergesByName } = useContext(ConciergeContext);
    const searchWithDebounce = useDebounce(search.query, 400);

    const renderAvailableConcierges = () => {
        const availableConcierges = concierges?.filter(concierge => concierge.status === ConciergeBackendStatusEnum.Routable);

        return availableConcierges?.map(concierge => {
            return (
                <EmployeeCard
                    key={concierge.id}
                    concierge={concierge}
                    refreshHandler={resetConcierges}
                />
            );
        });
    }

    const renderOccupiedConcierges = () => {
        const occupiedConcierges = concierges?.filter(concierge => concierge.status === ConciergeBackendStatusEnum.NotRoutable);

        return occupiedConcierges?.map(concierge => {
            return (
                <EmployeeCard
                    key={concierge.id}
                    concierge={concierge}
                    refreshHandler={resetConcierges}
                />
            );
        });
    }

    const renderOfflineConcierges = () => {
        const offlineConcierges = concierges?.filter(concierge => concierge.status === ConciergeBackendStatusEnum.Offline || concierge.status === ConciergeBackendStatusEnum.Init);

        return offlineConcierges?.map(concierge => {
            return (
                <EmployeeCard
                    key={concierge.id}
                    concierge={concierge}
                    refreshHandler={resetConcierges}
                />
            );
        });
    }

    const resetConcierges = () => {
        getConcierges().then((concierges: IConciergeData[]) => {
            setConcierges(concierges);
        });
    }

    const filterByRole = (value: string) => {
        setFilterRole(value);

        const role = parseInt(value);

        if (role === 3) {
            resetConcierges();

            return;
        }

        getConciergesByRole(role)
            .then((concierges: IConciergeData[]) => {
                setConcierges(concierges);
            });
    }

    const handleOnInputChange = (value: string) => {
        setSearch({
            query: value
        });

        if (!value) {
            resetConcierges();
        }
    }

    useEffect(() => {
        if (token) {
            resetConcierges();
        }
    }, [token]);

    useEffect(() => {
        let mounted = true;

        if (searchWithDebounce) {
            getConciergesByName(searchWithDebounce)
                .then((concierges: IConciergeData[]) => {
                    if (mounted) {
                        setConcierges(concierges);
                    }
                });

            return function cleanup() {
                mounted = false;
            };
        }
    }, [searchWithDebounce])

    return (
        <div className="employees__wrapper">
            <div className="search_container">
                <div className="search_container__searchbar">
                    <img src="/assets/search.svg" alt="" />
                    <input
                        type="text"
                        placeholder="Search"
                        name="search"
                        id="search"
                        value={search.query}
                        onChange={e => handleOnInputChange(e.target.value)}
                    />
                </div>
                <div className="search_container__filter">
                    <select 
                        name="filter"
                        id="filter"
                        value={filterRole}
                        onChange={e => filterByRole(e.target.value)}
                    >
                        <option disabled value="-1">Filter by Role</option>
                        <option value="0">Concierge</option>
                        <option value="1">Manager</option>
                        <option value="2">Admin</option>
                        <option value="3">All</option>
                    </select>
                    <img src="/assets/gray_arrow.svg" alt="" />
                </div>
            </div>
            <div className="user_container__wrapper">
                <div className="user_container__header_container">
                    <div className="user_container__header">
                        <p>Available</p>
                    </div>
                    <div className="user_container__header">
                        <p>Occupied</p>
                    </div>
                    <div className="user_container__header">
                        <p>Offline</p>
                    </div>
                </div>
                <div className="user_container__container">
                    <div className="user_container">
                        {renderAvailableConcierges()}
                    </div>
                    <div className="user_container__divider"></div>
                    <div className="user_container">
                        {renderOccupiedConcierges()}
                    </div>
                    <div className="user_container__divider"></div>
                    <div className="user_container">
                        {renderOfflineConcierges()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeesPage
