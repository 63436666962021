import "amazon-connect-streams";
import React, { FC, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router";
import { AmazonConnectContext, AuthenticationContext, ConciergeContext, GlobalStateContext, GlobalTimerContext } from "../../contexts";
import { ConciergeBackendStatusEnum, ModalTypesEnum } from "../../util/enums";
import { encryptEmail } from "../../util/functions";

const contact_control_panel = document.getElementById("ccp") as HTMLElement;

interface CCPPortalProps {
    children: React.ReactNode;
}

const CCPPortal: FC<CCPPortalProps> = ({ children }) => {
    const ccp_portal: HTMLElement = document.createElement("div");
    const instance_url = "https://725contact.my.connect.aws/ccp-v2/";
    // const instance_url_core = "https://725contact.my.connect.aws/ccp-v2";

    const location = useLocation();
    const history = useHistory();
    
    const {
        showIncomingCallModal,
        setShowIncomingCallModal,
        setShowLoader,
        isCCPInitialized,
        setIsCCPInitialized,
        setIsLoggedOut,
        setShowModal,
        setActiveModal,
        activeModal,
        isLoggedOut
    } = useContext(GlobalStateContext);
    const { startTotalCallTimer } = useContext(GlobalTimerContext);
    const { setAgent, setConnection, setContact, setEventBus } = useContext(AmazonConnectContext);
    const { saveEmail, saveToken, removeEmail, removeToken } = useContext(AuthenticationContext);
    const { login, conciergeData, getCurrentConcierge, updateConciergeStatus } = useContext(ConciergeContext);

    const stop_CCP = () => {
        connect.agentApp.AppRegistry.stop();
    }

    const initialize_CCP = () => {
        connect.agentApp.initApp("ccp", "ccp", instance_url, {
            ccpParams: {
                loginPopup: true,
                loginPopupAutoClose: true,
                loginOptions: {
                    autoClose: true,
                    height: 600,
                    width: 400,
                },
                softphone: {
                    allowFramedSoftphone: true
                }
            }
        })
        
        // connect.core.initCCP(contact_control_panel, {
        //     ccpUrl: instance_url_core,
        //     loginPopup: true,
        //     loginPopupAutoClose: true,
        //     softphone: {
        //         allowFramedSoftphone: true
        //     }
        // })

        connect.agent((agent) => {
            const states = agent.getAgentStates();
            const onlineState = states.filter(state => state.name === 'Available')[0];
            
            agent.setState(onlineState);

            setAgent(agent);
            
            const email = agent.getConfiguration().username;

            login(encryptEmail(email))
            .then(response => {
                saveEmail(response.email);
                saveToken(response.token);

                setIsCCPInitialized(true);
                setShowLoader(false);
            });
            
            // agent.onStateChange((state) => {
            //     const status = getConciergeStatusFromAgentState(state.newState);

            //     console.log(status);
            //     console.log(conciergeData);
            //     if (conciergeData) {
            //         console.log(conciergeData);
            //         updateConciergeStatus(conciergeData.id, status)
            //         .then(response => {
            //             getCurrentConcierge();
            //         })
            //     }
            // })
        });

        const eventBus = (connect.core as any).getEventBus();

        eventBus.subscribe((connect as any).EventType.TERMINATED, () => {
            removeEmail();
            removeToken();

            history.push('/');

            setIsLoggedOut(true);
        })

        eventBus.subscribe((connect as any).EventType.TERMINATE, () => {
            removeEmail();
            removeToken();

            history.push('/');
            
            setIsLoggedOut(true);
        })

        setEventBus(eventBus);

        // connect.core.onInitialized(function() {
            
        // });

        connect.contact((contact) => {
            setContact(contact);

            const conn = contact.getAgentConnection() as connect.VoiceConnection;
            setConnection(conn);

            contact.onIncoming(function(contact) {
                setShowModal(true);
                setActiveModal(ModalTypesEnum.INCOMING_CALL);
                console.log("INCOMING");
            });

            contact.onAccepted(function(contact) {
                console.log("ACCEPTED");
            });

            // OVO JE KAD SE PREKINE POZIV (SA BILO CIJE STRANE)
            contact.onEnded(function(contact) {
                // console.log('ON ENDeeeeeeeeeeeeeeeeeeeeeED:', activeModal)
                if (showIncomingCallModal) {
                    setShowModal(false);
                    setActiveModal(undefined);
                    setShowIncomingCallModal(false);
                }
                console.log("ENDED");
            });

            // OVO JE KAD ZOVU CUSTOMERI PA DA SE PRIKAZE AGENTU
            contact.onConnecting(function(contact) {
                console.log('ON CONNECTIIIIIIIIIIIIIIIIIIIING', activeModal);
                setShowModal(true);
                setActiveModal(ModalTypesEnum.INCOMING_CALL);
                setShowIncomingCallModal(true);
                console.log("CONNECTING");
            });

            // OVO JE KAD SE POVEZE SA TOBOM
            contact.onConnected(function(contact) {
                // if (activeModal === ModalTypesEnum.INCOMING_CALL) {
                //     setShowModal(false);
                //     setActiveModal(undefined);
                // }
                console.log("CONNECTED");
            });

            // OVO JE KAD SE NE JAVIS ILI ODBIJES
            contact.onMissed(function(contact) {
                if (showIncomingCallModal) {
                    setShowModal(false);
                    setActiveModal(undefined);
                    setShowIncomingCallModal(false);
                }
                console.log("MISSED");
            });
        })
    }

    const getConciergeStatusFromAgentState = (agentState: string) => {
        switch (agentState) {
            case 'Occupied':
                return ConciergeBackendStatusEnum.NotRoutable;
            case 'Available':
                return ConciergeBackendStatusEnum.Routable;
            default:
                return ConciergeBackendStatusEnum.Offline;
        }
    }

    useEffect(() => {
        if (!isCCPInitialized && !location.pathname.includes('password')) {
            initialize_CCP();
        }

        if (isCCPInitialized && isLoggedOut) {
            stop_CCP();
        }
    }, [isCCPInitialized, location]);

    useEffect(() => {
        contact_control_panel.appendChild(ccp_portal);
        
        return () => {
            contact_control_panel.removeChild(ccp_portal);
        }
    }, []);

    const child_with_connect = React.Children.map(children, (child) => {
        if (React.isValidElement(child))
            return React.cloneElement(child, {connect: connect});

        return child;
    })

    return ReactDOM.createPortal(child_with_connect, ccp_portal);
}
  
  export default CCPPortal;