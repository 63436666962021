import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { ConciergeContext } from '../../contexts';
import { ICreateConciergeData } from '../../interfaces'

const CreateEmployeePage = () => {
    const { createConcierge } = useContext(ConciergeContext);

    const history = useHistory();

    const [conciergeData, setConciergeData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: -1,
        poolId: 1,
        photo: "",
    } as ICreateConciergeData);

    const create = () => {
        createConcierge(conciergeData).then((response) => {
            history.push("/employees");
        });
    }

    const inputHandler: Function = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        setConciergeData({
            ...conciergeData,
            [fieldName]: fieldName === 'role' ? parseInt(event.target.value) : event.target.value,
        });
    };

    const isEmail = (search:string):boolean =>
    {
        var serchfind:boolean;
        const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        serchfind = emailRegex.test(search);

        return serchfind;
    }

    const addDisabled = () => {
        if (conciergeData.firstName.length > 0 &&
          conciergeData.lastName.length > 0 &&
          isEmail(conciergeData.email) &&
          conciergeData.role > -1) {
            return false;
        }
        return true;
    }

    return (
        <div className="create_employees__wrapper">
            <div className="create_employees__names">
                <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First name"
                    value={conciergeData.firstName}
                    onChange={e => inputHandler('firstName', e)}
                />
                <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Last name"
                    value={conciergeData.lastName}
                    onChange={e => inputHandler('lastName', e)}
                />
            </div>
            <input
                type="text"
                name="email"
                id="email"
                placeholder="E-mail"
                className="create_employees__email"
                value={conciergeData.email}
                onChange={e => inputHandler('email', e)}
            />
            <select
                name="role"
                id="role"
                className="create_employees__role"
                value={conciergeData.role}
                onChange={e => inputHandler('role', e)}
            >
                <option value="-1" disabled>Assign Role</option>
                <option value={0}>Concierge</option>
                <option value={1}>Manager</option>
                <option value={2}>Admin</option>
            </select>
            <button className="create_employees__button" onClick={create} disabled={addDisabled()}>Add</button>
        </div>
    )
}

export default CreateEmployeePage
