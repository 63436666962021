import jwt_decode from "jwt-decode";
import { ITokenData } from "../../interfaces";

const getConciergeIdFromToken = (token: string): number | undefined => {
    const decoded = jwt_decode<ITokenData>(token);

    if (decoded) {
        return decoded.id;
    }

    return undefined;
}

export { getConciergeIdFromToken }