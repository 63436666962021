import React, { FC, useContext, useState } from 'react'
import { ISubscriptionData } from '../../../../interfaces'
import NumericInput from 'react-numeric-input'
import { SubscriptionPlanContext } from '../../../../contexts';
import { useHistory } from 'react-router-dom';

interface SubscriptionPlanFormProps {
    plan: ISubscriptionData | undefined;
}

const SubscriptionPlanForm: FC<SubscriptionPlanFormProps> = (props) => {
    const { plan } = props;

    const history = useHistory();

    const { addSubscriptionPlan, editSubscriptionPlan, getSubscriptionPlans } = useContext(SubscriptionPlanContext);

    const [subscriptionPlanData, setSubscriptionPlanData] = useState(
        plan
        ? plan
        : {
            // monthlyPrice: 0,
            // yearlyPrice: 0,
            // numOfMinsPerCallToContact: 0,
            // numOfMinsPerSupportCall: 0,
            // numOfPriorityContactGroups: 0,
            // numOfSupportCalls: 0,
            // priceOfAdditionalCallToCont: 0,
            // priceOfAdditionalCallToContactPerMin: 0,
            // priceOfAdditionalConciergeS: 0,
            // priceOfAdditionalConciergeSupportPerMin: 0,
            // name: ''
        } as ISubscriptionData
    );

    const inputHandler: Function = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        let value;

        switch (fieldName) {
            case 'name':
                value = event.target.value;
                break;
            case 'numOfSupportCalls':
                value = parseInt(event.target.value);
                break;
            default:
                value = parseFloat(event.target.value);
                break;
        }

        setSubscriptionPlanData({
            ...subscriptionPlanData,
            [fieldName]: value,
        });
    };

    const handleSaveSubscription = () => {
        if (plan) {
            editSubscriptionPlan(subscriptionPlanData)
            .then(response => {
                getSubscriptionPlans()
                .then(response => {
                    history.push("/subscription-plan");
                })
            })
            .catch(error => {
                console.log(error);
            })

            return;
        }

        addSubscriptionPlan(subscriptionPlanData)
        .then(response => {
            getSubscriptionPlans()
            .then(response => {
                history.push("/subscription-plan");
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <div className="subscription_plan_form__container">
            <h2>
                {
                    plan
                    ? 'Edit plan'
                    : 'Add new plan'
                }
            </h2>
            <input
                type="text"
                className="subscription_plan_form__name_input"
                value={subscriptionPlanData.name}
                onChange={e => inputHandler('name', e)}
                placeholder="Plan name"
            />
            <input
                type="number"
                value={subscriptionPlanData.numOfSupportCalls}
                onChange={e => inputHandler('numOfSupportCalls', e)}
                placeholder="Number of Concierge support calls per year"
                min={0}
            />
            <input
                type="number"
                value={subscriptionPlanData.numOfPriorityContactGroups}
                onChange={e => inputHandler('numOfPriorityContactGroups', e)}
                placeholder="Number of contacts in a Priority Group"
                min={0}
            />
            <input
                type="number"
                value={subscriptionPlanData.numOfMinsPerSupportCall}
                onChange={e => inputHandler('numOfMinsPerSupportCall', e)}
                placeholder="Number of minutes per support call"
                min={0}
            />
            <input
                type="number"
                value={subscriptionPlanData.numOfMinsPerCallToContact}
                onChange={e => inputHandler('numOfMinsPerCallToContact', e)}
                placeholder="Number of minutes per assisted call-to-contact"
                min={0}
            />
            <input
                type="number"
                value={subscriptionPlanData.priceOfAdditionalConciergeSupportPerMin}
                onChange={e => inputHandler('priceOfAdditionalConciergeSupportPerMin', e)}
                placeholder="Price of additional per-minute concierge support"
                min={0}
                step={0.1}
            />
            <input
                type="number"
                value={subscriptionPlanData.priceOfAdditionalCallToContactPerMin}
                onChange={e => inputHandler('priceOfAdditionalCallToContactPerMin', e)}
                placeholder="Price of additional per-minute 3-way call"
                min={0}
                step={0.1}
            />
            <div className="subscription_plan_form__prices_container">
                <div className="subscription_plan_form__price_container">
                    <span className="subscription_plan_form__dollar">$</span>
                    <input
                        type="number"
                        value={subscriptionPlanData.yearlyPrice}
                        onChange={e => inputHandler('yearlyPrice', e)}
                        placeholder="Plan price"
                        min={0}
                        step={0.1}
                    />
                    <span className="subscription_plan_form__type">/ year</span>
                </div>
                <div className="subscription_plan_form__price_container">
                    <span className="subscription_plan_form__dollar">$</span>
                    <input
                        type="number"
                        value={subscriptionPlanData.monthlyPrice}
                        onChange={e => inputHandler('monthlyPrice', e)}
                        placeholder="Plan price"
                        min={0}
                        step={0.1}
                    />
                    <span className="subscription_plan_form__type">/ monthly</span>
                </div>
            </div>
            <button
                onClick={handleSaveSubscription}
            >
                Save Subscription Plan
            </button>
        </div>
    )
}

export default SubscriptionPlanForm
