import React, { createContext, FC, useContext } from 'react'
import { IResetPasswordDTO } from '../interfaces';
import { AxiosContext } from './AxiosContext';

interface IResetPasswordContext {
    resetPassword(data: IResetPasswordDTO): Promise<void>;
};

const ResetPasswordContext = createContext({} as IResetPasswordContext);

interface ResetPasswordContextProps {
    children: React.ReactNode;
}

const ResetPasswordContextProvider: FC<ResetPasswordContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);

    const resetPassword = (data: IResetPasswordDTO) => {
        return axiosInstance({
            method: 'POST',
            url: 'auth/concierges/reset-password',
            data
        }).then((response: any) => {
            return response.data;
        });
    }

    const providerValue = {
        resetPassword
    }

    return (
        <ResetPasswordContext.Provider value={providerValue}>
            {props.children}
        </ResetPasswordContext.Provider>
    )
}

export { ResetPasswordContext, ResetPasswordContextProvider }
