import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { IConciergeData, ICreateConciergeData, ILoginData, ILoginDTO, IUpdateConciergeData } from '../interfaces'
import { ConciergeBackendStatusEnum } from '../util/enums';
import { getConciergeIdFromToken } from '../util/functions';
import { AuthenticationContext } from './AuthenticationContext';
import { AxiosContext } from './AxiosContext';

interface IConciergeContext {
    conciergeData: IConciergeData | undefined;
    setConciergeData(data: IConciergeData | undefined): void;
    getCurrentConcierge(): Promise<void> | undefined;
    createConcierge(data: ICreateConciergeData): Promise<void>;
    updateConcierge(id: number, data: IUpdateConciergeData): Promise<void>;
    updateConciergeStatus(id: number, status: ConciergeBackendStatusEnum): Promise<void>;
    deleteConcierge(id: number): Promise<void>;
    getConcierges(): Promise<IConciergeData[]>;
    getConciergesByRole(role: number): Promise<IConciergeData[]>;
    getConciergesByName(name: string): Promise<IConciergeData[]>;
    login(data: ILoginDTO): Promise<ILoginData>;
};

const ConciergeContext = createContext({} as IConciergeContext);

interface ConciergeContextProps {
    children: React.ReactNode;
}

const ConciergeContextProvider: FC<ConciergeContextProps> = (props) => {
    const { axiosInstance } = useContext(AxiosContext);
    const { token } = useContext(AuthenticationContext);

    const [conciergeData, setConciergeData] = useState<IConciergeData | undefined>(undefined);

    const getCurrentConcierge = () => {
        const id = getConciergeIdFromToken(token);

        if (!id) {
            // return;
            return Promise.reject();
        }

        return axiosInstance({
            method: 'GET',
            url: `concierges/${id}`
        }).then((response: any) => {
            setConciergeData(response.data);
            return response.data;
        });
    }

    const getConcierges = () => {
        return axiosInstance({
            method: 'GET',
            url: 'concierges',
        }).then((response: any) => {
            return response.data;
        });
    }

    const getConciergesByRole = (role: number) => {
        return axiosInstance({
            method: 'GET',
            url: 'concierges/role',
            headers: {
                'role': role
            }
        }).then((response: any) => {
            return response.data;
        });
    }

    const getConciergesByName = (name: string) => {
        return axiosInstance({
            method: 'GET',
            // url: 'concierges/name',
            url: 'concierges/active',
            headers: {
                'name': name
            }
        }).then((response: any) => {
            return response.data;
        });
    }

    const createConcierge = (data: ICreateConciergeData) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierges',
            data
        }).then((response: any) => {
            console.log(response.data);
        });
    }

    const updateConcierge = (id: number, data: IUpdateConciergeData) => {
        return axiosInstance({
            method: 'PUT',
            url: `concierges/${id}`,
            data
        }).then((response: any) => {
            alert(JSON.stringify(data));
            console.log(response.data);
        });
    }

    const updateConciergeStatus = (id: number, status: ConciergeBackendStatusEnum) => {
        return axiosInstance({
            method: 'PUT',
            url: `concierges/status/${id}`,
            data: {
                status
            }
        }).then((response: any) => {
            console.log(response.data);
        });
    }

    const deleteConcierge = (id: number) => {
        return axiosInstance({
            method: 'DELETE',
            url: `concierges/${id}`
        }).then((response: any) => {
            alert('Concierge has been deleted.');
        }).catch(error => {
            alert(error.message);
        });
    }

    const login = (data: ILoginDTO) => {
        return axiosInstance({
            method: 'POST',
            url: 'auth/concierges/token',
            data
        }).then((response: any) => {
            console.log(response.data);
            return response.data;
        });
    }

    // const getCurrentConciergeCallback = useCallback(
    //     () => {
    //         const id = getConciergeIdFromToken(token);

    //         if (!id) {
    //             return;
    //         }

    //         return axiosInstance({
    //             method: 'GET',
    //             url: `concierges/${id}`
    //         }).then((response: any) => {
    //             setConciergeData(response.data);
    //             return response.data;
    //         });
    //     },
    //     [token, axiosInstance],
    // )
    
    useEffect(() => {
        if (!conciergeData && token) {
            getCurrentConcierge()
            .then(response => {
                updateConciergeStatus(response.id, ConciergeBackendStatusEnum.Routable)
                .then(() => {
                    getCurrentConcierge();
                })
            });
        }
    }, [token, conciergeData]);
    
    const providerValue = {
        conciergeData,
        setConciergeData,
        getCurrentConcierge,
        createConcierge,
        updateConcierge,
        updateConciergeStatus,
        deleteConcierge,
        getConcierges,
        getConciergesByRole,
        getConciergesByName,
        login,
    }

    return (
        <ConciergeContext.Provider value={providerValue}>
            {props.children}
        </ConciergeContext.Provider>
    )
}

export { ConciergeContext, ConciergeContextProvider }
