import React, { createContext, FC, useContext, useState } from 'react'
import { IAmazonContactAttributesDTO } from '../interfaces';
import { AxiosContext } from './AxiosContext';
import "amazon-connect-streams";

interface IAmazonConnectContext {
    agent: connect.Agent | undefined;
    setAgent(agent: connect.Agent | undefined): void;
    connection: connect.VoiceConnection | undefined;
    setConnection(connection: connect.VoiceConnection | undefined): void;
    contact: connect.Contact | undefined;
    setContact(contact: connect.Contact | undefined): void;
    eventBus: any | undefined;
    setEventBus(eventBus: any | undefined): void;
    updateContactAttributes(data: IAmazonContactAttributesDTO): Promise<void>;
    logout(): void;
};

const AmazonConnectContext = createContext({} as IAmazonConnectContext);

interface AmazonConnectContextProps {
    children: React.ReactNode;
};

const AmazonConnectContextProvider: FC<AmazonConnectContextProps> = (props) => {
    const [agent, setAgent] = useState<connect.Agent | undefined>(undefined);
    const [connection, setConnection] = useState<connect.VoiceConnection | undefined>(undefined);
    const [contact, setContact] = useState<connect.Contact | undefined>(undefined);
    const [eventBus, setEventBus] = useState<any | undefined>(undefined);

    const { axiosInstance } = useContext(AxiosContext);

    const updateContactAttributes = (data: IAmazonContactAttributesDTO) => {
        return axiosInstance({
            method: 'POST',
            url: 'concierge/customer/updateAmazonContactAttributes',
            data
        })
        .then((response: any) => {
            console.log(response.data);
        })
        .catch((error: any) => {
            console.log(error);
            throw error;
        });
    }

    const logout = () => {
        fetch("https://725contact.my.connect.aws/logout", { credentials: 'include', mode: 'no-cors'})
        .then(() => {
            eventBus.trigger((connect as any).EventType.TERMINATE);
            eventBus.trigger((connect as any).EventType.TERMINATED);

            document.cookie.split(';').forEach(cookie => {
                document.cookie = cookie
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
        });
    }

    const providerValue = {
        agent: agent,
        setAgent,
        connection: connection,
        setConnection,
        contact: contact,
        setContact,
        eventBus,
        setEventBus,
        updateContactAttributes,
        logout,
    }

    return (
        <AmazonConnectContext.Provider value={providerValue}>
            {props.children}
        </AmazonConnectContext.Provider>
    )
}

export { AmazonConnectContext, AmazonConnectContextProvider }
